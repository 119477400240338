import { Progress } from 'antd';
import React, { FC } from 'react';
import InfoMessage from './Common/InfoMessage';
import styles from './RepairList.module.scss';

type Props = {
  totalPercentage?: number;
  onClick?: () => void;
};

const ActiveUploads: FC<Props> = ({ totalPercentage, onClick }) => {
  return (
    <div className={styles.ActiveUploadsContainer} onClick={onClick}>
      <span>Active uploads</span>
      {/* <Progress percent={totalPercentage} size='small' /> */}
      <InfoMessage message='Do not close this tab while upload is in progress' />
    </div>
  );
};

export default ActiveUploads;
