import React, { FC, CSSProperties } from 'react';
import { CurrencyEnum } from '../../../shared/enums';

interface Props {
  className?: string;
  style?: CSSProperties | undefined;
  value: number;
  currency?: CurrencyEnum;
  hideZero: boolean;
}

const SpanNumberFormat: FC<Props> = ({ value, className, style, hideZero, currency }) => {
  var formatter = new Intl.NumberFormat('da-DK', {
    style: 'decimal',
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return (
    <span className={className} style={style}>
      {hideZero && value === 0 ? '' : `${formatter.format(value)} ${currency ? currency : ''}`}
    </span>
  );
};

export default SpanNumberFormat;
