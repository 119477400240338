import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import axios from '../../axios';
import { BidderStatusEnum, CurrencyEnum } from '../../shared/enums';
import { QuoteDataFinding } from '../QuoteData/quoteData.slice';

export const TAKE = 10;

export type BudgetDetail = {
  id: string;
  quoteId: string;
  bidderId: string;
  companyName: string;
  bidderStatus: BidderStatusEnum;
  bidderInvitedDate: Date | '';
  bidPlacedDate: Date | '';
  rewardedDate: Date | '';
  totalManPower: number;
  estimatedHours: number;
  vesselType: string;
  currency: CurrencyEnum;
  expectedProjectDuration: number;
  mobDemobCost: number;
  numberOfTeamAssigned: number;
  teamSize: number;
  weatherRiskCost: number;
  trainingCost: number;
  vesselCost: number;
  additionalCost: number;
  totalCases: number;
  totalNumberOfWTG: number;
  totalNumberOfBlades: number;
  canEdit: boolean;
  bidData: BidData[];
};

export type BidData = {
  id: string;
  quoteDataId: string;
  bidId: string;
  tooling: number;
  additionalCosts: number;
  totalManPower: number;
  estimatedHours: number;
  comment: number;
  accessType: string;
  cases: number;
  findingType: string;
  numberOfBlades: number;
  numberOfWTG: number;
  scopeOfWorkReference: string;
  findings: Array<QuoteDataFinding>;
};

type State = {
  budgetDetail: BudgetDetail;
  isLoading: boolean;
  recalculateTotalRepairCost: boolean;
  bidData: BidData[];
};

const initialBudgetDetail: BudgetDetail = {
  id: '',
  quoteId: '',
  bidderId: '',
  companyName: '',
  bidderStatus: BidderStatusEnum.InviteNotSent,
  bidderInvitedDate: '',
  bidPlacedDate: '',
  rewardedDate: '',
  additionalCost: 0,
  totalManPower: 0,
  estimatedHours: 0,
  vesselType: '',
  currency: CurrencyEnum.Euro,
  expectedProjectDuration: 0,
  mobDemobCost: 0,
  numberOfTeamAssigned: 0,
  teamSize: 0,
  weatherRiskCost: 0,
  trainingCost: 0,
  vesselCost: 0,
  totalCases: 0,
  totalNumberOfWTG: 0,
  totalNumberOfBlades: 0,
  canEdit: true,
  bidData: []
};

const initialState: State = {
  budgetDetail: initialBudgetDetail,
  isLoading: true,
  recalculateTotalRepairCost: false,
  bidData: []
};

const bidSlice = createSlice({
  name: 'bid',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setBudgetDetail(state, action: PayloadAction<BudgetDetail>) {
      state.budgetDetail = action.payload;
      state.bidData = action.payload.bidData;
    },
    bidDataChanged(state, action: PayloadAction<BidData>) {
      const bidData = action.payload;
      state.bidData = state.bidData.map(x => {
        if (x.id === bidData.id) {
          return {
            ...bidData,
            key: bidData.quoteDataId
          };
        }
        return x;
      });
    },
    setBidDataFindings(state, action: PayloadAction<{ quoteDataId: string; data: Array<QuoteDataFinding> }>) {
      const index = state.bidData.findIndex(x => x.quoteDataId === action.payload.quoteDataId);
      if (index >= 0) {
        state.bidData[index].findings = action.payload.data;
      }
    }
  }
});

export const { setLoading, setBudgetDetail, bidDataChanged, setBidDataFindings } = bidSlice.actions;

export default bidSlice.reducer;

export const getBudgetDetail = (bidderId: string): AppThunk => async dispatch => {
  dispatch(setLoading(true));
  const response = await axios.get(`budget/${bidderId}`);
  dispatch(setBudgetDetail(response.data));
  dispatch(setLoading(false));
};

export const getQuoteDataFindings = (quoteId: string, quoteDataId: string): AppThunk => async dispatch => {
  const response = await axios.get(`quote/${quoteId}/data/${quoteDataId}`);
  dispatch(setBidDataFindings({ quoteDataId, data: response.data }));
};
