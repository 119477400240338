import { Form, Select, Space } from 'antd';
import React, { FC } from 'react';

const { Option } = Select;

type Props = {
  label: string;
  value: string[] | undefined;
  options: string[];
  maxWidth?: string;
  loading: boolean;
  disabled: boolean;
  onChange: (value: string[] | undefined) => void;
};

export const SearchItem: FC<Props> = React.memo(({ disabled, loading, label, value, options, maxWidth, onChange }) => {
  return (
    <Form.Item label={label} style={{ width: '180px', marginRight: '1rem' }} colon={false}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Select
          loading={loading}
          value={value}
          mode='multiple'
          maxTagCount='responsive'
          showSearch
          disabled={disabled || loading}
          style={{ minWidth: '150px', maxWidth: maxWidth ? maxWidth : '200px' }}
          allowClear={true}
          placeholder='Please select'
          optionFilterProp='children'
          onChange={onChange}
          onClear={() => onChange(undefined)}
        >
          {options &&
            options.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
        </Select>
      </Space>
    </Form.Item>
  );
});

export default SearchItem;
