import React, { FC } from 'react';
import { Modal } from 'antd';
import styles from './ConfirmDialog.module.scss';

const ConfirmDialog = (onSubmit: (arg?: any) => void, description: string, optionalParam?: any) => {
  const modalConfirm: any = Modal.confirm;

  return modalConfirm({
    title: 'Confirm',
    content: description,
    okText: 'Yes',
    cancelText: 'No',
    closable: true,
    icon: false,
    className: 'confirm-dialog',
    maskClosable: true,
    onOk: () => {
      onSubmit(optionalParam);
    },
    onCancel: () => {
      return;
    }
  });
};

export default ConfirmDialog;
