import { UserManager, Log, User } from 'oidc-client';
import * as Utils from 'hermes-tiles-grid-popup';

type AuthService = {
  userManager: UserManager;
  user: User | null;
  getUser: () => Promise<User | null>;
  login: (state?: any) => Promise<any>;
  logout: () => Promise<any>;
  signinRedirectCallback: () => Promise<User>;
  redirectToAuthority: () => void;
  hasPermission: (permissions: string[]) => Promise<boolean>;
};

const clientRoot = Utils.replaceTenantNamePlaceHolder(process.env.REACT_APP_CLIENT_ROOT!);
const allowedScopes = process.env.REACT_APP_ALLOWED_SCOPES;
const authority = Utils.replaceTenantNamePlaceHolder(process.env.REACT_APP_IDENTITY_SERVER!);
const clientId = process.env.REACT_APP_CLIENT_ID || 'stitching_tool';

const settings = {
  authority: authority,
  client_id: clientId,
  redirect_uri: `${clientRoot}/callback`,
  post_logout_redirect_uri: '/Account/Login',
  response_type: 'id_token token',
  scope: allowedScopes,
  loadUserInfo: true
};

if (process.env.NODE_ENV !== 'production') {
  Log.logger = console;
  Log.level = Log.DEBUG;
}

export const AuthService: AuthService = {
  userManager: new UserManager(settings),
  user: null,
  getUser() {
    return this.userManager.getUser();
  },
  login(state: any) {
    return this.userManager.signinRedirect(state);
  },
  logout() {
    return this.userManager.signoutRedirect();
  },
  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback();
  },
  redirectToAuthority() {
    this.userManager.removeUser().then(() => {
      if (settings.authority) window.location.href = settings.authority;
    });
  },
  async hasPermission(permissions: string[]) {
    const user = await AuthService.getUser();
    const hasPermission = user && user.profile && permissions.some(x => user.profile.permission.includes(x));

    if (hasPermission) {
      return true;
    }

    return false;
  }
};
