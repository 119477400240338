import { createContext } from 'react';
import { AttachmentForUpload, FileWithLabel } from '../repairList.slice';

export type FilesContextValue = {
  scheduledFiles: AttachmentForUpload[];
  toScheduleFiles: FileWithLabel[];
  onFileRemove: (name: string) => void;
  setToScheduleFiles: (files: FileWithLabel[]) => void;
};

export const FilesContext = createContext<FilesContextValue>({} as FilesContextValue);
