import React, { useState, FC } from 'react';
import { connect } from 'react-redux';
import Button from '../../Shared/Button/Button';
import styles from './SearchForm.module.scss';
import { searchQuotes } from '../quoteList.slice';
import { RootState } from '../../../rootReducer';
import { Form, Select, Input } from 'antd';
import { QuoteStatusEnum } from '../../../shared/enums';
import { AppDispatch } from '../../../store';
import { QuoteStatusesForSelect } from '../../../shared/enums';

const { Option } = Select;

type Props = {
  initialStatus: QuoteStatusEnum;
  initialSite: string;
  initialDescription: string;
  searchQuotes: (site: string, description: string, status: QuoteStatusEnum) => void;
};

export const statuses = Object.keys(QuoteStatusesForSelect).map(key => (
  <Option key={key} value={key}>
    {QuoteStatusesForSelect[key].name}
  </Option>
));

export const SearchForm: FC<Props> = ({ initialStatus, initialSite, initialDescription, searchQuotes }) => {
  const [site, setSite] = useState<string | undefined>(initialSite.length ? initialSite : undefined);
  const [description, setDescription] = useState<string | undefined>(initialDescription.length ? initialDescription : undefined);
  const [status, setStatus] = useState<QuoteStatusEnum | undefined>(initialStatus ? undefined : undefined);

  // useEffect(() => {
  //   onGetSites();
  // }, [onGetSites]);

  const statusSelected = (value: QuoteStatusEnum) => {
    setStatus(value);
  };

  const descriptionEntered = (value: any) => {
    setDescription(value.target.value);
  };

  const siteEntered = (value: any) => {
    setSite(value.target.value);
  };

  const onSubmit = (e: any) => {
    searchQuotes(site || '', description || '', status || 0);
  };

  const onClear = (e: any) => {
    setSite(undefined);
    setDescription(undefined);
    setStatus(undefined);
    searchQuotes('', '', 0);
  };

  return (
    <div>
      <Form className={styles.formContainer}>
        <Form.Item label='Site name' colon={false}>
          {<Input value={site} onChange={siteEntered} style={{ width: '150px' }} placeholder='Site name'></Input>}
        </Form.Item>
        <Form.Item label='Description' colon={false}>
          {<Input value={description} onChange={descriptionEntered} style={{ width: '150px' }} placeholder='Description'></Input>}
        </Form.Item>
        <Form.Item label='Status' colon={false}>
          <Select
            value={status}
            showSearch
            style={{ width: '150px' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={statusSelected}
          >
            {statuses}
          </Select>
        </Form.Item>
        <Button
          style={{ marginLeft: '30px', borderRadius: '0.25rem' }}
          onClick={onSubmit}
          type='submit'
          disabled={false} //{isLoading}
          title='Search'
        />
        <Button
          style={{ marginLeft: '20px', borderRadius: '0.25rem' }}
          onClick={onClear}
          type='submit'
          disabled={false} //{isLoading}
          title='Clear'
        />
      </Form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialSite: state.quoteList.siteSearchTerm,
  initialDescription: state.quoteList.descriptionSearchTerm,
  initialStatus: state.quoteList.statusSearchTerm
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  searchQuotes: (site: string, description: string, status: QuoteStatusEnum) => dispatch(searchQuotes(site, description, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
