import React from 'react';
import RepairTableAction from './RepairTableAction';
import Label from './label';
import { AttachmentTable, RepairDetail } from './repairList.slice';
import moment from 'moment';
import Show from './show';
import { RepairType } from '../../shared/enums';

type ColumnProps = {
  title: string;
  dataIndex?: any;
  key: string;
  render?: (annotation: any) => JSX.Element;
  width?: string;
  editable?: boolean;
  sorter?: boolean;
};

const DEFAULT_COLUMN_WIDTH = '150px';

const ColumnWidths = new Map<string, string>([
  ['status', '200px'],
  ['siteName', '150px'],
  ['companyName', '250px'],
  ['description', '250px'],
  ['bidderInvitedDate', '250px'],
  ['bidPlacedDate', '250px'],
  ['action', '80px'],
  ['contactPerson', '80px']
]);

const getColumnWidth = (column: string) => {
  if (ColumnWidths.has(column)) {
    return ColumnWidths.get(column)!;
  }

  return DEFAULT_COLUMN_WIDTH;
};

const checkSequenceName = (sequence: any) => {
  switch (sequence) {
    case 'PressureSide':
      return 'PS';

    case 'LeadingEdge':
      return 'LE';

    case 'TrailingEdge':
      return 'TE';

    case 'SuctionSide':
      return 'SS';

    default:
      return '';
  }
};

const getCommonColumnProperties = (
  key: string,
  title: string,
  render?: ((record: string) => JSX.Element) | undefined,
  addDataIndex = true,
  width?: string,
  sorter?: boolean,
  showSorterTooltip?: boolean
) => ({
  title,
  dataIndex: addDataIndex ? key : undefined,
  key: key,
  width: width && width?.length > 0 ? width : getColumnWidth(key),
  render,
  sorter,
  showSorterTooltip
});

type Column = {
  key: string;
  title: string;
  addDataIndex: boolean;
  render?: ((record: any) => JSX.Element) | undefined;
  editable?: boolean;
  width?: string;
  sorter?: boolean;
  showSorterTooltip?: boolean;
};

export const repairInfoAttachmentColumns: Column[] = [
  {
    key: 'fileName',
    title: 'File Name',
    addDataIndex: true
  },
  {
    key: 'label',
    title: 'Label',
    addDataIndex: true,
    render: ((_, record: AttachmentTable) => <Label attachment={record} />) as any
  },
  {
    key: 'show',
    title: 'Show',
    addDataIndex: true,
    render: ((_, record: AttachmentTable) => <Show attachment={record} />) as any
  }
];

export const getSortProperty = (name: string) => {
  switch (name) {
    case 'siteName':
      return 'site';
    case 'inspection':
      return 'inspectionOrderNumber';
    case 'bladeSide':
      return 'surface';
    case 'findingType':
      return 'category';
    case 'defectMaintenanceTriggerCode':
      return 'maintenanceTriggerCode';
    default:
      return name;
  }
};

export const prepareSortDirectionAndProperty = (sorter: { order: 'descend' | 'ascend'; field: string }) => {
  let sort: number | undefined = undefined;
  let sortName: string | undefined = undefined;

  if (sorter.order === 'descend') {
    sort = 1;
    sortName = getSortProperty(sorter.field);
  } else if (sorter.order === 'ascend') {
    sort = 0;
    sortName = getSortProperty(sorter.field);
  } else {
    sort = undefined;
    sortName = undefined;
  }

  return {
    sortDirection: sort,
    sortProperty: sortName
  };
};

const repairListColumns: Column[] = [
  {
    key: 'repairType',
    title: 'Status',
    addDataIndex: true,
    render: (record: any) => {
      switch (record) {
        case 'Repaired':
          return <span>Repaired</span>;
        case 'Replaced':
          return <span>Replaced</span>;
        case 'ToBeRepaired':
          return <span>To be repaired</span>;
        default:
          return <span>Not Fixed</span>;
      }
    },
    width: '120px',
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'distanceToRoot',
    title: 'Distance to Root',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'distanceToLeadingEdge',
    title: 'Distance to LE',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'countryName',
    title: 'Country',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'siteName',
    title: 'Site',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'inspection',
    title: 'Inspection',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'turbineName',
    title: 'Turbine',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'bladePosition',
    title: 'Blade',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'bladeSide',
    title: 'Blade side',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'findingType',
    title: 'Finding type',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'severity',
    title: 'Severity',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'defectMaintenanceTriggerCode',
    title: 'Response level',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'findingDateCreated',
    title: 'Finding date',
    addDataIndex: true,
    render: (record: string) => <>{record ? moment(record).format('YYYY-MM-DD') : ''}</>,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'repairDate',
    title: 'Repair date',
    addDataIndex: true,
    render: (record: string) => <>{record ? moment(record).format('YYYY-MM-DD') : ''}</>,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'workOrderNumber',
    title: 'Work order number',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false,
    render: (record: number) => <>{record ? record : ''}</>
  },
  {
    key: 'comment',
    title: 'Comment',
    addDataIndex: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    key: 'repairCompany',
    title: 'Repair company',
    addDataIndex: true,
    sorter: false,
    showSorterTooltip: false
  },
  {
    key: 'findingId',
    title: 'Finding ID',
    addDataIndex: true,
    sorter: false,
    showSorterTooltip: false
  }
];

export const repairInfoExtendedColumns: Column[] = [
  {
    key: 'action',
    title: 'Action',
    addDataIndex: false,
    render: (record: any): JSX.Element => <RepairTableAction record={record} />,
    width: '250px'
  },
  {
    key: 'fileName',
    title: 'File Name',
    addDataIndex: true
  },
  {
    key: 'uploadDate',
    title: 'Upload date',
    addDataIndex: true
  },
  {
    key: 'label',
    title: 'Label',
    render: record => (
      <span className='label-cell' data-tooltip={record}>
        {record}
      </span>
    ),
    addDataIndex: true
  },
  {
    key: 'show',
    title: 'Show',
    addDataIndex: true,
    render: (show?: boolean) => <span>{show ? 'Yes' : 'No'}</span>
  }
];

export const getRepairListColumns = (): ColumnProps[] =>
  repairListColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex, x.width, x.sorter, x.showSorterTooltip));

export const getRepairListInfoColumns = (): ColumnProps[] =>
  repairInfoExtendedColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex, x.width));

export const getRepairListInfoAttachmentsColumns = (): ColumnProps[] =>
  repairInfoAttachmentColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex));
