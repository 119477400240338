import moment from 'moment';

const dateFormat = 'YYYY-MM-DD HH:mm';

const shortDateFormat = 'YYYY-MM-DD';

export const getFormattedDate = (date: Date | '') => {
    if(!date){
        return ''
    }

    return moment(date).local().format(dateFormat);
}

export const getShortFormattedDate = (date: Date | '') => {
    if(!date){
        return ''
    }

    return moment(date).local().format(shortDateFormat);
}
