import React, { FC, useState, useEffect, useCallback } from 'react';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { connect } from 'react-redux';
import { Form, Card, Table, Input, DatePicker, Layout, PageHeader, Divider, Select, Spin } from 'antd';
import { QuoteStatusEnum, QuoteStatusesForSelect, PermissionEnum, BidderStatusEnum } from '../../shared/enums';
import styles from './Quote.module.scss';
import { saveQuote, Quote, getQuote, sendMail, getSites, IdText } from './quote.slice';
import moment from 'moment';
import Button from '../Shared/Button/Button';
import { deleteBidder, setBidder, Bidder, openBidderForm } from '../Bidder/bidder.slice';
import BidderForm from '../Bidder/BidderForm';
import { RouteComponentProps, withRouter } from 'react-router';
import { BidderStatusesToDescription } from '../../shared/enums';
import Status from '../Shared/Status/Status';
import NumberFormat from 'react-number-format';
import { ToastAnt } from '../Toast/ToastAnt';
import { AuthService } from '../Auth/AuthService';
import { getQuoteDataList, QuoteData, setQuoteDataList } from '../QuoteData/quoteData.slice';
import ConfirmDialog from '../Shared/ConfirmDialog/ConfirmDialog';
import classNames from 'classnames';

type Props = {
  loading: boolean;
  quote: Quote;
  bidderModalVisible: boolean;
  sites: Array<IdText>;
  showFindingsButton: boolean;
  isSuccessfullyAdded: boolean;
  quoteDataList: QuoteData[];
  onQuoteSave: (quote: Quote) => void;
  openBidderForm: (visible: boolean) => void;
  onDeleteBidder: (bidderId: string) => void;
  onSetBidder: (bidder: Bidder | undefined) => void;
  onSendMail: (quote: Quote) => void;
  onGetQuote: (id: string) => void;
  getSites: () => void;
  getQuoteDataList: (id: string) => void;
  onSetQuoteDataList: (value: QuoteData[]) => void;
};

const QuoteForm: FC<Props & RouteComponentProps<{ id: string }>> = ({
  quote,
  bidderModalVisible,
  sites,
  history,
  match,
  showFindingsButton,
  quoteDataList,
  onGetQuote,
  onQuoteSave,
  openBidderForm,
  onDeleteBidder,
  onSetBidder,
  onSendMail,
  getSites,
  getQuoteDataList,
  onSetQuoteDataList
}) => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [plannedWorkCompleted, setPlannedWorkCompleted] = useState<moment.Moment | null>(null);
  const [plannedWorkStart, setPlannedWorkStart] = useState<moment.Moment | null>(null);
  const [tenderCloseDate, setTenderCloseDate] = useState<moment.Moment | null>(null);
  const [tenderExtension, setTenderExtension] = useState<string>('');
  const [siteName, setSiteName] = useState<string | undefined>('');
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);
  const [disableQuoteField, setDisableQuoteField] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(true);

  const dateFormat = 'YYYY-MM-DD';

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    getSites();
    if (id !== 'new') {
      const { id } = match.params;
      onGetQuote(id);
      getQuoteDataList(id);
    }
    return () => {
      onSetQuoteDataList([]);
    };
  }, [match.params]);

  useEffect(() => {
    if (quote.id) {
      setSaveButtonEnabled(true);
      const { id } = match.params;
      quote.statusEnum !== QuoteStatusEnum.InProgress ? setDisableQuoteField(true) : setDisableQuoteField(false);
      if (id === 'new') {
        history.push(`/quotes/${quote.id}`);
      } else {
        setId(quote.id!);
        form.setFieldsValue({
          projectReference: quote.projectReference,
          plannedWorkStart: quote.plannedWorkStart != null ? moment(quote.plannedWorkStart) : null,
          plannedWorkCompleted: quote.plannedWorkCompleted != null ? moment(quote.plannedWorkCompleted) : null,
          tenderCloseDate: quote.tenderCloseDate != null ? moment(quote.tenderCloseDate) : null,
          tenderExtension: quote.tenderExtension,
          description: quote.description,
          site: quote.siteName
        });
        setComment(quote.comment);
        setPlannedWorkStart(quote.plannedWorkStart);
        setPlannedWorkCompleted(quote.plannedWorkCompleted);
        setTenderCloseDate(quote.tenderCloseDate);
        setTenderExtension(quote.tenderExtension);
        setSiteName(quote.siteName);
      }
    }
  }, [quote, form]);

  const onSiteNameChange = (e: ButtonSubmitEvent) => {
    handleSubmit(e);
  };

  const onSave = (e: ButtonSubmitEvent) => {
    quote.siteName !== siteName && quoteDataList.length
      ? ConfirmDialog(() => onSiteNameChange(e), 'By changing the site, all findings will be removed. Are you sure?')
      : handleSubmit(e);
  };

  const handleSubmit = async (e: ButtonSubmitEvent) => {
    if (!quote.id) {
      setSaveButtonEnabled(false);
    }

    e.preventDefault();
    try {
      if (quote.statusEnum !== QuoteStatusEnum.InProgress) {
        ToastAnt('Quote is in ' + QuoteStatusesForSelect[quote.statusEnum].name + ' status.');
        return;
      }

      await form.validateFields();
      const newItem: Quote = {
        id: id,
        siteId: sites.find(x => x.text === siteName)?.id,
        siteName: siteName,
        description: form.getFieldValue('description'),
        comment: comment,
        statusEnum: QuoteStatusEnum.InProgress,
        status: '',
        plannedWorkCompleted: plannedWorkCompleted,
        plannedWorkStart: plannedWorkStart,
        projectReference: form.getFieldValue('projectReference'),
        tenderCloseDate: tenderCloseDate,
        tenderExtension: tenderExtension,

        awardedDate: null,
        bidders: quote.bidders
      };

      setIsLoading(true);
      onQuoteSave(newItem);
      setIsLoading(false);
    } catch (err) {
      ToastAnt('An error occurred!' + err);
    }
  };

  const disabledDate = (currentDate: moment.Moment, isWorkCompletedDatePicker: boolean) => {
    if (plannedWorkStart && isWorkCompletedDatePicker) {
      return currentDate && currentDate < moment(plannedWorkStart, dateFormat);
    }
    return currentDate && currentDate < moment().startOf('day');
  };

  const onChangePlannedWorkStart = (date: moment.Moment | null, dateFormatted: string) => {
    if (!moment(dateFormatted).isBefore(moment(plannedWorkCompleted, dateFormat))) {
      form.setFieldsValue({
        plannedWorkCompleted: null
      });
      setPlannedWorkCompleted(null);
    }
    setPlannedWorkStart(date);
  };

  const onChangePlannedWorkCompleted = (date: moment.Moment | null, dateFormatted: string) => {
    setPlannedWorkCompleted(date);
  };

  const handleSendMail = async () => {
    onSendMail(quote);
  };

  const sendMail = () => {
    ConfirmDialog(handleSendMail, 'Are you sure you want to send invites?');
  };

  const deleteBidder = (bidder: any) => {
    ConfirmDialog(onDeleteBidder, 'Are you sure you want to delete bidder?', bidder);
  };

  const onAddBidder = () => {
    onSetBidder(undefined);
    openBidderForm(true);
  };

  const onEditBidder = (bidder: Bidder) => {
    onSetBidder(bidder);
    openBidderForm(true);
  };

  const onBack = () => {
    history.push('/');
  };

  const onOpenData = () => {
    history.push(`/quotes/${quote.id}/data`);
  };

  const siteSelected = (value: string) => {
    setSiteName(value);
  };

  const requiredFieldsEmpty = () => {
    if (!form.getFieldValue('site') || !form.getFieldValue('description') || !form.getFieldValue('tenderCloseDate')) {
      return true;
    }
    return false;
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 }
    }
  };

  const columns = [
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => {
        return (
          <>
            {record.status === BidderStatusEnum.InviteNotSent && (
              <span>
                <a
                  onClick={e => {
                    e.stopPropagation();
                    onEditBidder(record);
                  }}
                >
                  Edit
                </a>
              </span>
            )}
            <Divider type='vertical' />

            {record.status === BidderStatusEnum.InviteNotSent && (
              <span>
                <a
                  onClick={() => {
                    deleteBidder(record.id);
                  }}
                >
                  Delete
                </a>
              </span>
            )}
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record: string): JSX.Element => (
        <span>
          <Status status={BidderStatusesToDescription[record].name} />
        </span>
      )
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Contact person',
      dataIndex: 'contactPerson',
      key: 'contactPerson'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    }
  ];
  const { Option } = Select;
  const { Content } = Layout;

  const rowSelection = {
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {}
  };

  return (
    <Layout>
      {bidderModalVisible ? <BidderForm /> : ''}
      <Content>
        <PageHeader
          className='QBRHeader'
          onBack={onBack}
          title={quote.id ? 'Update Quote' : 'Add Quote'}
          extra={[
            showFindingsButton && (
              <Button
                disabled={!quote.id}
                title={quoteDataList.length > 0 ? 'View Findings' : 'Add Findings'}
                type='button'
                key='openData'
                onClick={onOpenData}
              ></Button>
            ),
            quote.statusEnum !== QuoteStatusEnum.BidRewarded && quote.statusEnum !== QuoteStatusEnum.Cancelled && (
              <Button
                isLoading={isLoading}
                disabled={requiredFieldsEmpty() || isLoading || !saveButtonEnabled}
                title='Save'
                type='button'
                key='save'
                onClick={onSave}
              ></Button>
            ),
            quote.statusEnum !== QuoteStatusEnum.BidRewarded && quote.statusEnum !== QuoteStatusEnum.Cancelled && (
              <Button
                disabled={quoteDataList.length === 0 || !quote.bidders.length}
                title='Send Invites'
                type='button'
                key='sendMail'
                onClick={sendMail}
              ></Button>
            )
          ]}
        />
        <Form className='quoteForm' {...formItemLayout} layout='vertical' form={form}>
          <div className={styles.formFieldsWrapper}>
            <Form.Item className={styles.formField} label='ID'>
              <Input readOnly value={quote.intId} className={styles.quoteIDInput} />
            </Form.Item>

            <Form.Item
              className={styles.formField}
              name='tenderCloseDate'
              label='Tender close date'
              rules={[{ type: 'object', required: true, message: 'Select tender closing date', whitespace: true }]}
            >
              <DatePicker
                disabledDate={date => disabledDate(date, false)}
                className={disableQuoteField ? 'customElementsDisabled' : ''}
                disabled={disableQuoteField}
                value={tenderCloseDate}
                onChange={date => {
                  setTenderCloseDate(date);
                }}
              />
            </Form.Item>

            <Form.Item className={styles.formField} name='plannedWorkStart' label='Planned work start'>
              <DatePicker
                className={disableQuoteField ? 'customElementsDisabled' : ''}
                disabled={disableQuoteField}
                value={plannedWorkStart}
                disabledDate={date => disabledDate(date, false)}
                onChange={onChangePlannedWorkStart}
              />
            </Form.Item>

            <div className={classNames(styles.formField, styles.emptySpaceFiller)}></div>

            <Form.Item className={styles.formField} name='site' label='Site' rules={[{ required: true, message: 'Select site', whitespace: true }]}>
              <Select
                className={disableQuoteField ? 'customElementsDisabled' : ''}
                disabled={disableQuoteField}
                showSearch
                notFoundContent={siteName === '' ? <Spin size='small' /> : null}
                style={{ width: '100%' }}
                placeholder='Please select'
                optionFilterProp='children'
                onChange={siteSelected}
                value={siteName}
              >
                {sites.map(s => (
                  <Option key={s.id} value={s.text}>
                    {s.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item className={styles.formField} name='tenderExtension' label='Tender extension in days'>
              <NumberFormat
                className={styles.numberFormat}
                value={tenderExtension}
                onChange={e => {
                  setTenderExtension(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item className={styles.formField} name='plannedWorkCompleted' label='Planned work completed'>
              <DatePicker
                disabledDate={date => disabledDate(date, true)}
                className={disableQuoteField ? 'customElementsDisabled' : ''}
                disabled={disableQuoteField}
                value={plannedWorkCompleted}
                onChange={onChangePlannedWorkCompleted}
              />
            </Form.Item>

            <div className={classNames(styles.formField, styles.emptySpaceFiller)}></div>

            <Form.Item
              className={styles.formField}
              name='description'
              label='Description'
              rules={[{ required: true, message: 'Enter description', whitespace: true }]}
            >
              <Input
                disabled={disableQuoteField}
                placeholder='Enter description'
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item className={styles.formField} label='Project reference' name='projectReference'>
              <Input disabled={disableQuoteField} placeholder='Enter project reference id or Salesforce' />
            </Form.Item>

            <Form.Item className={styles.formField} label='Comment'>
              <Input.TextArea
                className={disableQuoteField ? 'customElementsDisabled' : ''}
                disabled={disableQuoteField}
                placeholder='Enter comment'
                value={comment}
                onChange={e => {
                  setComment(e.target.value);
                }}
              />
            </Form.Item>
          </div>

          <Card
            title='Bidders'
            extra={
              quote.statusEnum !== QuoteStatusEnum.BidRewarded &&
              quote.statusEnum !== QuoteStatusEnum.Cancelled && (
                <Button title='Add Bidder' disabled={match.params.id === 'new'} type='button' onClick={onAddBidder}></Button>
              )
            }
          >
            <Table
              columns={columns}
              dataSource={quote.bidders}
              rowKey='id'
              rowSelection={rowSelection}
              pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
            />
          </Card>
        </Form>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  quote: state.quote.quote,
  loading: state.quoteList.isLoading,
  bidderModalVisible: state.bidder.bidderModalVisible,
  sites: state.quote.sites,
  showFindingsButton: state.quoteData.showFindingsButton,
  quoteDataList: state.quoteData.quoteDataList
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onQuoteSave: (quote: Quote) => dispatch(saveQuote(quote)),
  openBidderForm: (visible: boolean) => dispatch(openBidderForm(visible)),
  onDeleteBidder: (bidderId: string) => dispatch(deleteBidder(bidderId)),
  onSetBidder: (bidder: Bidder | undefined) => dispatch(setBidder(bidder)),
  onSendMail: (quote: Quote) => dispatch(sendMail(quote)),
  onGetQuote: (id: string) => dispatch(getQuote(id)),
  getSites: () => dispatch(getSites()),
  getQuoteDataList: (id: string) => dispatch(getQuoteDataList(id)),
  onSetQuoteDataList: (value: QuoteData[]) => dispatch(setQuoteDataList(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteForm));
