import React, { FC, useState, useEffect, useCallback } from 'react';
import Button from '../Shared/Button/Button';
import { Table, Layout, PageHeader, Card } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { getQuoteBidders, getQuoteList, TAKE, getQuote, QuoteDetail } from './quoteList.slice';
import { prepareQuoteListSelector } from './quoteListSelector';
import SearchForm from './SearchForm/SearchForm';
import { getBidderListColumns } from './quoteListHelper';
import { BidderExpanded } from '../Bidder/bidder.slice';
import { getBid } from '../Bid/bid.slice';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from '../QuoteList/QuoteList.module.scss';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';

type Props = {
  gridData: any;
  currentPage: number;
  totalNumber: number;
  isLoading: boolean;
  getQuoteList: (newPage: number, init?: boolean) => void;
  getQuoteBidders: (quoteId: string) => void;
  getQuote: (id: string) => void;
  getBid: (id: string) => void;
};

const QuoteList: FC<Props & RouteComponentProps> = ({
  gridData,
  currentPage,
  totalNumber,
  isLoading,
  getQuoteList,
  getQuoteBidders,
  getQuote,
  getBid,
  history
}) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    getQuoteList(1, true);
  }, []);

  const getBidderTableContent = (bidders: BidderExpanded[]) => {
    if (!bidders || (bidders && bidders.length === 0)) {
      return {
        isLoading: false,
        columns: [],
        dataSource: []
      };
    }

    let columns = getBidderListColumns();

    const dataSource = bidders.map(x => ({
      ...x,
      totalFixedCost: { value: x.totalFixedCost, currency: x.currency },
      totalRepairCost: { value: x.totalRepairCost, currency: x.currency },
      totalCost: { value: x.totalCost, currency: x.currency },
      key: x.id
    }));

    return {
      isLoading: false,
      columns,
      dataSource
    };
  };

  const expandedRowRender = (quoteDetail: QuoteDetail) => {
    const biddersGridData = getBidderTableContent(quoteDetail.bidders);

    return (
      <Table
        style={{ width: '90%' }}
        className={styles.sortable}
        loading={biddersGridData.isLoading}
        columns={biddersGridData.columns}
        dataSource={biddersGridData.dataSource}
        pagination={false}
      />
    );
  };

  const onAddQuote = () => {
    getQuote('');
    history.push(`/quotes/new`);
  };

  const onExpand = (expanded: boolean, record: QuoteDetail & { key: string }) => {
    if (!expanded) {
      setExpandedKeys([...expandedKeys.filter(x => x !== record.key)]);
      return;
    }

    getQuoteBidders(record.id);

    setExpandedKeys([...expandedKeys, record.key]);
  };

  const { Content } = Layout;

  return (
    <Layout>
      <Content>
        <PageHeader title={'Quote Module'} extra={[]} className='QBRHeader' />
        <Card
          title={<SearchForm />}
          style={{ marginLeft: '0' }}
          extra={
            hasAdminPermission && (
              <Button
                style={{ marginLeft: '30px', borderRadius: '0.25rem' }}
                onClick={onAddQuote}
                type='submit'
                disabled={isLoading}
                title={'Add Quote'}
              />
            )
          }
        >
          <Table
            style={{ minHeight: '320px', height: '100%' }}
            columns={gridData.columns}
            dataSource={gridData.dataSource}
            loading={isLoading}
            scroll={{ y: 520, x: gridData.columns.length * 220 }}
            expandedRowRender={expandedRowRender}
            onExpand={onExpand}
            expandedRowKeys={expandedKeys}
            expandRowByClick={true}
            pagination={{
              current: currentPage,
              total: totalNumber,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              pageSize: TAKE,
              onChange: (page: number) => {
                getQuoteList(page);
              }
            }}
          />
        </Card>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  gridData: prepareQuoteListSelector(state),
  currentPage: state.quoteList.currentPage,
  totalNumber: state.quoteList.totalNumberOfQuotes,
  isLoading: state.quoteList.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getQuoteList: (newPage: number, init: boolean = false) => dispatch(getQuoteList(init, newPage)),
  getQuoteBidders: (quoteId: string) => dispatch(getQuoteBidders(quoteId)),
  getQuote: (id: string) => dispatch(getQuote(id)),
  getBid: (id: string) => dispatch(getBid(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteList));
