import React, { FC, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './SettingsModal.module.scss';
import classNames from 'classnames';
import { Settings } from '../../shared/constants';
import Button from '../Shared/Button/Button';

type SettingsModalProps = {
  show: boolean;
  onHide: () => void;
};

const SettingsModalModal: FC<SettingsModalProps> = ({ show, onHide }) => {
  const [opacity, setOpacity] = useState(
    localStorage.getItem(Settings.Opacity) || '1'
  );
  const [message, setMessage] = useState('');

  const onSave = () => {
    let updatedOpacity = parseFloat(opacity);

    if (!updatedOpacity) {
      setMessage('Invalid opacity value, please provide value between 0 and 1');
      return;
    }

    if (updatedOpacity > 1) {
      updatedOpacity = 1;
    } else if (updatedOpacity < 0) {
      updatedOpacity = 0;
    }
    localStorage.setItem(Settings.Opacity, updatedOpacity.toString());
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <form
            onSubmit={event => {
              event.preventDefault();
              onSave();
            }}
          >
            <div className="form-group">
              <label htmlFor="opacity">Opacity</label>
              <input
                value={opacity}
                onChange={e => setOpacity(e.target.value)}
                type="text"
                className={classNames('form-control', {
                  [styles.invalidInput]: !!message
                })}
                id="opacity"
                name="opacity"
                placeholder="Enter opacity value"
              />
            </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onSave} disabled={false} title="Save" type="submit" />
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsModalModal;
