import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthService } from './AuthService';
import { User } from 'oidc-client';
import { PermissionEnum } from '../../shared/enums';

const RequireAuth = <P extends object>(Component: React.ComponentType<P>) => {
  return class App extends React.Component<P & RouteComponentProps> {
    state = {
      isAuthenticated: false,
      isLoading: true,
      hasPermission: false
    };

    componentDidMount() {
      AuthService.getUser()
        .then(this.getUserHandle)
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }

    getUserHandle: (user: User | null) => void = user => {
      const isAuthenticated = user && user.expires_at > Date.now() / 1000;
      const requiredPermissions = [
        PermissionEnum.QuoteManager_Admin.toString(),
        PermissionEnum.QuoteManager_Bidder.toString(),
        PermissionEnum.RepairManager_Admin.toString(),
        PermissionEnum.BudgetManager_Admin.toString(),
        PermissionEnum.RepairManage_CanUseRepair.toString()
      ];
      const hasPermission = user && user.profile && requiredPermissions.some(x => [...user.profile.permission].indexOf(x) !== -1);
      this.setState({
        isAuthenticated: isAuthenticated,
        isLoading: false,
        hasPermission: hasPermission
      });
    };

    render() {
      const { isAuthenticated, isLoading, hasPermission } = this.state;
      const { match } = this.props;

      if (isLoading) {
        return <div>Loading...</div>;
      }

      if (!isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: match.url }
            }}
          />
        );
      }

      if (!hasPermission) {
        return <Redirect to={{ pathname: '/not-authorized', state: { from: match.url } }} />;
      }
      return <Component {...this.props} />;
    }
  };
};

export { RequireAuth };
