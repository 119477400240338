import React, { FC, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';
import { BudgetDetail } from './budgetList.slice';
import { Divider, Modal } from 'antd';
import ConfirmDialog from '../Shared/ConfirmDialog/ConfirmDialog';

type QuoteTableActionProps = {
  record: BudgetDetail;
};

const QuoteTableAction: FC<QuoteTableActionProps & RouteComponentProps> = ({ record, history }) => {
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  const onOpenQuote = () => {
    history.push(`/quotes/${record.quoteId}`);
  };

  const onDeleteBudgetItem = () => {
    return false;
  };

  const onOpenBudgetItem = () => {
    history.push(`/budget/${record.bidderId}`);
  };

  const deleteBudgetItem = () => {
    ConfirmDialog(onDeleteBudgetItem, 'Are you sure you want to delete this item?');
  };

  return (
    <>
      {hasAdminPermission && (
        <div>
          <span>
            <a
              onClick={e => {
                e.stopPropagation();
                onOpenBudgetItem();
              }}
            >
              Open
            </a>
          </span>
          <Divider type='vertical' />
          <span>
            <a
              onClick={e => {
                e.stopPropagation();
                deleteBudgetItem();
              }}
            >
              Delete
            </a>
          </span>
          <Divider type='vertical' />
          <span>
            <a
              onClick={e => {
                e.stopPropagation();
                onOpenQuote();
              }}
            >
              Quote
            </a>
          </span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: QuoteTableActionProps) => ({
  ...ownProps
});

export default connect(mapStateToProps)(withRouter(QuoteTableAction));
