import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import axios from '../../axios';
import { QuoteStatusEnum } from '../../shared/enums';
import { ToastAnt } from '../Toast/ToastAnt';
import { Bidder } from '../Bidder/bidder.slice';
import { Moment } from 'moment';
import { getQuoteDataList } from '../QuoteData/quoteData.slice';

export type Quote = {
  id: string;
  intId?: number | undefined;
  statusEnum: QuoteStatusEnum;
  status: string;
  projectReference: string;
  siteId: string | undefined;
  siteName: string | undefined;
  description: string;
  comment: string;
  plannedWorkStart: Moment | null;
  plannedWorkCompleted: Moment | null;
  tenderCloseDate: Moment | null;
  awardedDate: Moment | null;
  tenderExtension: string;
  bidders: Bidder[];
};

type State = {
  quote: Quote;
  isLoading: boolean;
  sites: Array<IdText>;
};

export interface IdText {
  id: string;
  text: string;
}

export const initialQuote: Quote = {
  id: '',
  intId: undefined,
  siteId: '',
  siteName: '',
  comment: '',
  description: '',
  plannedWorkCompleted: null,
  plannedWorkStart: null,
  projectReference: '',
  statusEnum: QuoteStatusEnum.InProgress,
  status: '',
  tenderCloseDate: null,
  awardedDate: null,
  tenderExtension: '',
  bidders: []
};

const initialState: State = {
  quote: initialQuote,
  sites: [],
  isLoading: true
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSelectedQuote(state, action: PayloadAction<Quote>) {
      state.quote = action.payload;
    },
    addBidder(state, action: PayloadAction<Bidder>) {
      state.quote.bidders.push(action.payload);
    },
    updateBidder(state, action: PayloadAction<Bidder>) {
      state.quote.bidders = state.quote.bidders.map((x, index) => {
        if (x.id === action.payload.id) {
          return (x = action.payload);
        }
        return x;
      });
    },
    removeBidder(state, action: PayloadAction<string>) {
      state.quote.bidders = state.quote.bidders.filter(x => x.id !== action.payload);
    },
    setSites(state, action: PayloadAction<Array<IdText>>) {
      state.sites = action.payload;
    }
  }
});

export const { setLoading, setSelectedQuote, removeBidder, setSites, addBidder, updateBidder } = quoteSlice.actions;

export default quoteSlice.reducer;

export const getQuote =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    if (id && id !== 'new') {
      const response = await axios.get(`quote/${id}`);
      dispatch(setSelectedQuote(response.data));
    } else {
      dispatch(setSelectedQuote(initialQuote));
    }

    dispatch(setLoading(false));
  };

export const saveQuote =
  (quote: Quote): AppThunk =>
  async dispatch => {
    try {
      if (quote.id) {
        const response = await axios.put(`quote`, {
          id: quote.id,
          projectReference: quote.projectReference,
          siteId: quote.siteId,
          siteName: quote.siteName,
          description: quote.description,
          comment: quote.comment,
          plannedWorkStart: quote.plannedWorkStart,
          plannedWorkCompleted: quote.plannedWorkCompleted,
          tenderCloseDate: quote.tenderCloseDate,
          tenderExtension: quote.tenderExtension,
          bidders: quote.bidders
        });
        
        dispatch(getQuote(response.data));
        dispatch(getQuoteDataList(response.data));
      } else {
        const response = await axios.post(`quote`, {
          projectReference: quote.projectReference,
          siteId: quote.siteId,
          siteName: quote.siteName,
          description: quote.description,
          comment: quote.comment,
          plannedWorkStart: quote.plannedWorkStart,
          plannedWorkCompleted: quote.plannedWorkCompleted,
          tenderCloseDate: quote.tenderCloseDate,
          tenderExtension: quote.tenderExtension,
          bidders: quote.bidders
        });

        dispatch(getQuote(response.data));
      }
      ToastAnt('Quote is saved');
    } catch (error) {
      ToastAnt('An error occurred!');
    }
  };

export const sendMail =
  (quote: Quote): AppThunk =>
  async dispatch => {
    await axios
      .post(`quote/sendInvites/`, {
        id: quote.id
      })
      .then(response => {
        dispatch(getQuote(response.data));
        ToastAnt('Email invitation have been sent to each bidder!');
      })
      .catch(error => {
        ToastAnt('An error occurred!' + error);
      });
  };

export const getSites = (): AppThunk => async dispatch => {
  const response = await axios.get(`quote/getSites`);
  dispatch(setSites(response.data));
};
