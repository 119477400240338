import { useCallback, useEffect, useState } from 'react';
import { AttachmentForUpload, CreatedRepairInfoForUpload, FileWithLabel, NewAttachment } from '../repairList.slice';
import { FilesContextValue } from '../util/FilesContext';

export const useFiles = (
  onAddedAttachments: (attachments: NewAttachment[]) => void,
  createdRepairInfo: CreatedRepairInfoForUpload | null,
  clearCreatedRepairInfo: Function
) => {
  const [scheduledFiles, setScheduledFiles] = useState<AttachmentForUpload[]>([]);
  const [toScheduleFiles, setToScheduleFiles] = useState<FileWithLabel[]>([]);

  useEffect(() => {
    if (createdRepairInfo) {
      onAddedAttachments(
        toScheduleFiles.map(file => ({
          id: createdRepairInfo.attachments.find(x => x.fileName === file.name)!.id,
          fileName: file.name,
          errorOccurred: false,
          isUploading: false,
          repairInfoId: createdRepairInfo.id
        }))
      );
      setScheduledFiles([
        ...scheduledFiles,
        ...toScheduleFiles.map(file => ({
          id: createdRepairInfo.attachments.find(x => x.fileName === file.name)!.id,
          file: file.file,
          fileName: file.name,
          repairInfoId: createdRepairInfo.id,
          label: file.label,
          show: file.show
        }))
      ]);

      clearCreatedRepairInfo();
      setToScheduleFiles([]);
    }
  }, [clearCreatedRepairInfo, createdRepairInfo, onAddedAttachments, scheduledFiles, toScheduleFiles]);

  const onFileRemove = useCallback(
    (name: string) => {
      setToScheduleFiles(toScheduleFiles.filter(x => x.file.name !== name));
    },
    [toScheduleFiles]
  );

  const filesContextValue: FilesContextValue = {
    scheduledFiles,
    toScheduleFiles,
    onFileRemove,
    setToScheduleFiles
  };

  return {
    scheduledFiles,
    setScheduledFiles,
    filesContextValue
  };
};
