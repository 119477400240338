import React, { FC, useEffect, useState, useCallback } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import styles from './AddDataForm.module.scss';
import { showAddDataModal, AddDataFilter, addDataSave, getQuoteDataList, getInspections, getTurbines, getBlades } from '../quoteData.slice';
import { connect } from 'react-redux';
import { AppDispatch } from '../../../store';
import Button from '../../Shared/Button/Button';
import { RootState } from '../../../rootReducer';
import { IdText } from '../../Quote/quote.slice';
import { BladeSideEnum, DefectTypeEnum, DefectLayerEnum, SeverityEnum, PermissionEnum } from '../../../shared/enums';
import { AuthService } from '../../Auth/AuthService';

interface Props {
  showModal: (visible: boolean) => void;
  addDataSave: (filter: AddDataFilter) => void;
  getQuoteDataList: (quoteId: string) => void;
  quoteId: string;
  getInspections: (quoteId: string) => void;
  inspections: Array<IdText>;
  getTurbines: (quoteId: string) => void;
  turbines: Array<IdText>;
  getBlades: (turbineId: string) => void;
  blades: Array<IdText>;
}

const AddDataForm: FC<Props> = ({
  showModal,
  addDataSave,
  quoteId,
  getQuoteDataList,
  getInspections,
  inspections,
  getTurbines,
  turbines,
  getBlades,
  blades
}) => {
  const [form] = Form.useForm();
  const [inspectionId, setInspectionId] = useState('');
  const [turbineId, setTurbineId] = useState('');
  const [bladeId, setBladeId] = useState('');
  const [bladeSides, setBladeSides] = useState<Array<string>>([]);
  const [findingTypes, setFindingTypes] = useState<Array<string>>([]);
  const [layers, setLayers] = useState<Array<string>>([]);
  const [severities, setSeverities] = useState<Array<string>>([]);
  const [bladeSide, setBladeSide] = useState('');
  const [findingType, setFindingType] = useState('');
  const [layer, setLayer] = useState('');
  const [severity, setSeverity] = useState('');
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    getInspections(quoteId);
    getTurbines(quoteId);
    setBladeSides(Object.keys(BladeSideEnum).filter(x => typeof BladeSideEnum[x] === 'number'));
    setFindingTypes(Object.keys(DefectTypeEnum).filter(x => typeof DefectTypeEnum[x] === 'number'));
    setLayers(Object.keys(DefectLayerEnum).filter(x => typeof DefectLayerEnum[x] === 'number'));
    setSeverities(Object.keys(SeverityEnum).filter(x => typeof SeverityEnum[x] === 'string'));
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 }
    }
  };

  const inspectionSelected = (value: string) => {
    setInspectionId(value);
  };

  const turbineSelected = (value: string) => {
    setTurbineId(value);
    getBlades(value);
  };

  const bladeSelected = (value: string) => {
    setBladeId(value);
  };

  const bladeSideSelected = (value: string) => {
    setBladeSide(value);
  };

  const findingTypeSelected = (value: string) => {
    setFindingType(value);
  };

  const severitySelected = (value: string) => {
    setSeverity(value);
  };

  const layerSelected = (value: string) => {
    setLayer(value);
  };

  const handleSubmit = async (e: ButtonSubmitEvent) => {
    e.preventDefault();

    try {
      await form.validateFields();

      const filter: AddDataFilter = {
        quoteId: quoteId,
        inspectionId: inspectionId,
        turbineName: form.getFieldValue('turbineName'),
        bladeName: form.getFieldValue('bladeName'),
        bladeSide: form.getFieldValue('bladeSide'),
        findingType: form.getFieldValue('findingType'),
        severity: form.getFieldValue('severity'),
        layer: form.getFieldValue('layer')
      };
      addDataSave(filter);
      getQuoteDataList(quoteId);
      showModal(false);
    } catch (error) {}
  };
  return (
    <Modal
      title='Add Findings'
      visible={true}
      onCancel={() => {
        showModal(false);
      }}
      footer={[
        <Button title='Add' type='button' key='save' onClick={e => handleSubmit(e)} />,
        <Button className={styles.cancelButton} title='Cancel' type='button' key='back' onClick={() => showModal(false)} />
      ]}
    >
      <Form {...formItemLayout} layout='vertical' form={form}>
        <Form.Item name='inspectionId' label='Inspection Id' rules={[{ required: true, message: 'Select inspection id', whitespace: true }]}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={inspectionSelected}
            value={inspectionId}
          >
            {inspections.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='turbine' label='Turbine'>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={turbineSelected}
            value={turbineId}
          >
            {turbines.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='blade' label='Blade'>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={bladeSelected}
            value={bladeId}
          >
            {blades.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='bladeSide' label='Blade Side'>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={bladeSideSelected}
            value={bladeSide}
          >
            {bladeSides.map(i => (
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='findingType' label='Finding Type'>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={findingTypeSelected}
            value={findingType}
          >
            {findingTypes.map(i => (
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='severity' label='Severity'>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={severitySelected}
            value={severity}
          >
            {severities.map(i => (
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='layer' label='Layer'>
          <Select showSearch style={{ width: '100%' }} placeholder='Please select' optionFilterProp='children' onChange={layerSelected} value={layer}>
            {layers.map(i => (
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  inspections: state.quoteData.inspections,
  turbines: state.quoteData.turbines,
  blades: state.quoteData.blades
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  showModal: (visible: boolean) => dispatch(showAddDataModal(visible)),
  addDataSave: (filter: AddDataFilter) => dispatch(addDataSave(filter)),
  getQuoteDataList: (quoteId: string) => dispatch(getQuoteDataList(quoteId)),
  getInspections: (quoteId: string) => dispatch(getInspections(quoteId)),
  getTurbines: (quoteId: string) => dispatch(getTurbines(quoteId)),
  getBlades: (turbineId: string) => dispatch(getBlades(turbineId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDataForm);
