import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { FilesContext } from './util/FilesContext';
import { AttachmentTable } from './repairList.slice';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';

type Props = {
  attachment: AttachmentTable;
};

const Show: FC<Props> = ({ attachment }) => {
  const { toScheduleFiles, setToScheduleFiles } = useContext(FilesContext);
  const [canToggleVisibility, setCanToggleVisibility] = useState(false);

  const toScheduleFile = toScheduleFiles.find(x => x.file.name === attachment.fileName);

  const onChange = (event: CheckboxChangeEvent) => {
    setToScheduleFiles(
      toScheduleFiles.map(x => {
        if (x.file === toScheduleFile?.file) {
          return {
            ...x,
            show: event.target.checked
          };
        }

        return x;
      })
    );
  };

  const checkPermissions = useCallback(async () => {
    var canTogglePermission = await AuthService.hasPermission([PermissionEnum.RepairManager_CanToggleVisibility.toString()]);
    setCanToggleVisibility(canTogglePermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  if (!canToggleVisibility) {
    return null;
  }

  return (
    <div>
      <Checkbox checked={toScheduleFile?.show} onChange={onChange} />
    </div>
  );
};

export default Show;
