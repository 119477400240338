import { Card, Layout, PageHeader, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { prepareRepairListSelector, prepareRepairInfoListSelector, RepairInfoWithAttachments } from './repairListSelector';
import {
  AttachmentForUpload,
  clearUploadedFiles,
  CreatedRepairInfoForUpload,
  createRepairSummaryReport,
  Filter,
  getRepairList,
  ModalVisibility,
  NewAttachment,
  RepairInfoCreated,
  setAddedAttachments,
  setCreatedRepairInfo,
  setModalVisibility,
  setToNotFixed,
  uploadAttachments
} from './repairList.slice';
import { useCallback } from 'react';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';
import Button from '../Shared/Button/Button';
import RepairInfoModal from './RepairInfoModal';
import { FilesContext } from './util/FilesContext';
import { useFiles } from './hooks/useFiles';
import { useUploadFiles } from './hooks/useUploadFiles';
import { useTabConfirm } from './hooks/useTabConfirm';
import ActiveUploads from './ActiveUploads';
import SearchBox from './Filter/SearchBox';
import { FiltersContext, useFilters } from './hooks/useFilters';
import { prepareSortDirectionAndProperty } from './repairListHelper';
import { RouteComponentProps } from 'react-router';

interface Props {
  gridData: any;
  currentPage: number;
  pageSize: number;
  totalNumber: number;
  isLoading: boolean;
  isFiltersLoading: boolean;
  uploadInProgress: boolean;
  repairListInfo: any;
  createdRepairInfo: CreatedRepairInfoForUpload | null;
  uploadedFiles: NewAttachment[];
  modalVisibility: ModalVisibility;
  repairTypes: string[];
  countries: string[];
  sites: string[];
  inspections: string[];
  turbines: string[];
  blades: string[];
  bladeSides: string[];
  findingTypes: string[];
  severities: string[];
  responseLevels: string[];
  repairCompanies: string[];
  distanceToRootMax: number;
  distanceToRootMin: number;
  isPreparingReport: boolean;
  getRepairList: (newPage: number, filter?: Filter, pageSize?: number) => void;
  createRepairReport: (filter?: Filter) => void;
  clearCreatedRepairInfo: () => void;
  onClearUploadedFiles: () => void;
  onUploadAttachments: (attachments: AttachmentForUpload[]) => void;
  onAddedAttachments: (attachments: NewAttachment[]) => void;
  onSetModalVisibility: (value: ModalVisibility) => void;
  onSetFindingsToNotFixed: (defectIds: string[], filter: Filter | null) => void;
}
const RepairList: FC<Props & RouteComponentProps<{ findingId?: string }>> = ({
  gridData,
  isLoading,
  isFiltersLoading,
  currentPage,
  pageSize,
  totalNumber,
  repairListInfo,
  createdRepairInfo,
  uploadInProgress,
  uploadedFiles,
  modalVisibility,
  repairTypes,
  countries,
  sites,
  inspections,
  turbines,
  blades,
  bladeSides,
  findingTypes,
  severities,
  responseLevels,
  repairCompanies,
  match,
  distanceToRootMax,
  distanceToRootMin,
  isPreparingReport,
  clearCreatedRepairInfo,
  createRepairReport,
  getRepairList,
  onClearUploadedFiles,
  onUploadAttachments,
  onAddedAttachments,
  onSetModalVisibility,
  onSetFindingsToNotFixed
}) => {
  const [hasCanRepairPermission, setHasCanRepairPermission] = useState(false);
  const [hasCanGenerateReportPermission, setHasCanGenerateReportPermission] = useState(false);
  const [isInternalUserPermission, setIsInternalUserPermission] = useState(false);
  const [isCustomerPermission, setIsCustomerPermission] = useState(false);
  const [isExternalUserPermission, setIsExternalUserPermission] = useState(false);
  const [isNotFixedSelected, setIsNotFixedSelected] = useState(false);
  const [isRepairedSelected, setIsRepairedSelected] = useState(false);
  const [isReplacedSelected, setIsReplacedSelected] = useState(false);
  const [isAnyToBeRepairedSelected, setIsAnyToBeRepairedSelected] = useState(false);
  const [isAllToBeRepairedSelected, setIsAllToBeRepairedSelected] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [recordForEdit, setRecordForEdit] = useState<RepairInfoWithAttachments>();
  const [sort, setSort] = useState<{ order: 'descend' | 'ascend'; field: string }>({ order: 'ascend', field: '' });
  const { scheduledFiles, setScheduledFiles, filesContextValue } = useFiles(onAddedAttachments, createdRepairInfo, clearCreatedRepairInfo);
  useUploadFiles(scheduledFiles, uploadInProgress, uploadedFiles, setScheduledFiles, onClearUploadedFiles, onUploadAttachments);
  useTabConfirm(scheduledFiles);

  const { ready, setReady, utils, filter, changed, clearChanged } = useFilters();

  const checkPermissions = useCallback(async () => {
    var canRepairPermission = await AuthService.hasPermission([PermissionEnum.RepairManager_CanRepair.toString()]);
    setHasCanRepairPermission(canRepairPermission);
    var canGenerateReportPermission = await AuthService.hasPermission([PermissionEnum.RepairManager_CanGenerateRepairReport.toString()]);
    setHasCanGenerateReportPermission(canGenerateReportPermission);

    var isInternalUser = await AuthService.hasPermission([PermissionEnum.RepairManager_InternalUserPermission.toString()]);
    var isCustomer = await AuthService.hasPermission([PermissionEnum.RepairManager_CustomerPermission.toString()]);
    var isExternalUser = await AuthService.hasPermission([PermissionEnum.RepairManager_ExternalSupplierPermission.toString()]);
    setIsInternalUserPermission(isInternalUser);
    setIsCustomerPermission(isCustomer);
    setIsExternalUserPermission(isExternalUser);
  }, []);

  useEffect(() => {
    if (match && match.params) {
      const { findingId } = match.params;
      utils.setDefectId(findingId);
      setReady(true);
    }
  }, [match]);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    if (changed && ready) {
      const { sortDirection, sortProperty } = prepareSortDirectionAndProperty(sort);
      getRepairList(1, { ...filter, sortDirection, sortProperty }, pageSize);
      clearChanged();
    }
  }, [filter, pageSize, ready]);

  useEffect(() => {
    if (!modalVisibility || modalVisibility === ModalVisibility.None) {
      setRecordForEdit(undefined);
    }
  }, [modalVisibility]);

  const onExpand = (expanded: boolean, record: any) => {
    if (!expanded) {
      setExpandedKeys([...expandedKeys.filter((x: any) => x !== record.findingId)]);
      return;
    }
    setExpandedKeys([...expandedKeys, record.findingId]);
  };

  const onSelectRow = (selectedRowKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setIsNotFixedSelected(selectedRows.some((x: any) => x.repairType === 'NotFixed'));
    setIsRepairedSelected(selectedRows.some((x: any) => x.repairType === 'Repaired'));
    setIsReplacedSelected(selectedRows.some((x: any) => x.repairType === 'Replaced'));
    setIsAnyToBeRepairedSelected(selectedRows.some((x: any) => x.repairType === 'ToBeRepaired'));
    setIsAllToBeRepairedSelected(selectedRows.every((x: any) => x.repairType === 'ToBeRepaired'));
  };

  const onSetToNotFixed = () => {
    onSetFindingsToNotFixed(selectedRowKeys, filter);
    setSelectedRowKeys([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRow
  };

  const updateFindings = () => {
    setSelectedRowKeys([]);
  };

  return (
    <FiltersContext.Provider value={utils}>
      <FilesContext.Provider value={filesContextValue}>
        <Layout>
          <Content>
            {!!scheduledFiles.length && <ActiveUploads />}
            <PageHeader title={'Repair Module'} className='QBRHeader' />
            <SearchBox
              {...{
                repairTypes,
                countries,
                sites,
                inspections,
                turbines,
                blades,
                bladeSides,
                findingTypes,
                severities,
                responseLevels,
                isFiltersLoading,
                distanceToRootMax,
                distanceToRootMin,
                repairCompanies
              }}
            />
            <Card style={{ marginLeft: '0', marginBottom: '10px', marginTop: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {hasCanRepairPermission && (
                  <span>
                    <Button
                      style={{ marginBottom: '16px' }}
                      type='button'
                      title={'Repair'}
                      onClick={() => onSetModalVisibility(ModalVisibility.Repair)}
                      disabled={selectedRowKeys.length === 0 || isCustomerPermission || isRepairedSelected}
                    />
                    <Button
                      style={{ marginBottom: '16px', marginLeft: '16px' }}
                      type='button'
                      title={'Schedule for repair'}
                      onClick={() => onSetModalVisibility(ModalVisibility.ScheduleForRepair)}
                      disabled={
                        selectedRowKeys.length === 0 || isAnyToBeRepairedSelected || isCustomerPermission || isRepairedSelected || isReplacedSelected
                      }
                    />
                    <Button
                      style={{ marginBottom: '16px', marginLeft: '16px' }}
                      type='button'
                      title={'Return to Not Fixed'}
                      onClick={onSetToNotFixed}
                      disabled={selectedRowKeys.length === 0 || !isAllToBeRepairedSelected || !isInternalUserPermission}
                    />
                  </span>
                )}
                {hasCanGenerateReportPermission && filter.site?.length === 1 && (
                  <Button
                    style={{ marginBottom: '16px' }}
                    type='button'
                    title={'Generate report'}
                    onClick={() => createRepairReport(filter)}
                    isLoading={isPreparingReport}
                    disabled={isPreparingReport}
                  />
                )}
              </div>
              <Table
                className='repairListTable'
                style={{ minHeight: '320px', height: '100%' }}
                columns={gridData.columns}
                dataSource={gridData.dataSource}
                loading={isLoading}
                scroll={{ y: 420, x: gridData.columns.length * 220 }}
                onExpand={onExpand}
                expandedRowKeys={expandedKeys}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <>
                      <Button
                        type='button'
                        title={'Add attachments'}
                        onClick={() => {
                          onSetModalVisibility(ModalVisibility.Repair);
                          setRecordForEdit(record);
                        }}
                      />
                      <Table
                        style={{ width: '35%' }}
                        className='repair-module'
                        columns={repairListInfo.columns}
                        dataSource={record.attachments}
                        rowKey='id'
                        pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                      />
                    </>
                  ),
                  rowExpandable: record => record.repairType !== 'NotFixed'
                }}
                pagination={{
                  current: currentPage,
                  total: totalNumber,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  pageSize: pageSize,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  showSizeChanger: true
                }}
                onChange={(pagination, filters, sorter: any) => {
                  const { sortDirection, sortProperty } = prepareSortDirectionAndProperty(sorter);
                  setSort(sorter);
                  getRepairList(pagination.current!, { ...filter, sortDirection, sortProperty }, pageSize);
                }}
                rowSelection={rowSelection}
              />
              {modalVisibility !== ModalVisibility.None && (
                <RepairInfoModal
                  onSetModalVisibility={onSetModalVisibility}
                  isLoading={isLoading}
                  modalVisibility={modalVisibility}
                  repairInfoUploaded={updateFindings}
                  repairDataFindingIds={selectedRowKeys as string[]}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  recordForEdit={recordForEdit}
                />
              )}
            </Card>
          </Content>
        </Layout>
      </FilesContext.Provider>
    </FiltersContext.Provider>
  );
};

const MapStateToProps = (state: RootState) => ({
  gridData: prepareRepairListSelector(state),
  currentPage: state.repairList.currentPage,
  pageSize: state.repairList.pageSize,
  totalNumber: state.repairList.totalNumberOfRepairItems,
  isLoading: state.repairList.isLoading,
  isFiltersLoading: state.repairList.isFiltersLoading,
  repairListInfo: prepareRepairInfoListSelector(state),
  createdRepairInfo: state.repairList.createdRepairInfo,
  uploadInProgress: state.repairList.uploadInProgress,
  uploadedFiles: state.repairList.uploadedFiles,
  repairTypes: state.repairList.repairTypes,
  countries: state.repairList.countries,
  sites: state.repairList.sites,
  inspections: state.repairList.inspections,
  turbines: state.repairList.turbines,
  blades: state.repairList.blades,
  bladeSides: state.repairList.bladeSides,
  findingTypes: state.repairList.findingTypes,
  severities: state.repairList.severities,
  responseLevels: state.repairList.responseLevels,
  repairCompanies: state.repairList.repairCompanies,
  modalVisibility: state.repairList.modalVisibility,
  distanceToRootMax: state.repairList.distanceToRootMax,
  distanceToRootMin: state.repairList.distanceToRootMin,
  isPreparingReport: state.repairList.isPreparingReport
});

const MapDispatchToProps = (dispatch: AppDispatch) => ({
  getRepairList: (newPage: number, filter: Filter | null = null, pageSize: number = 10) => dispatch(getRepairList(newPage, filter, pageSize)),
  createRepairReport: (filter: Filter | null = null) => dispatch(createRepairSummaryReport(filter)),
  clearCreatedRepairInfo: () => dispatch(setCreatedRepairInfo(null)),
  onClearUploadedFiles: () => dispatch(clearUploadedFiles()),
  onUploadAttachments: (attachments: AttachmentForUpload[]) => dispatch(uploadAttachments(attachments)),
  onAddedAttachments: (attachments: NewAttachment[]) => dispatch(setAddedAttachments(attachments)),
  onSetModalVisibility: (value: ModalVisibility) => dispatch(setModalVisibility(value)),
  onSetFindingsToNotFixed: (defectIds: string[], filter: Filter | null = null) => dispatch(setToNotFixed(defectIds, filter))
});

export default connect(MapStateToProps, MapDispatchToProps)(RepairList);
