import { notification } from 'antd';

export const ToastAnt = (title: string) => {
  notification.open({
    message: `${title}`,
    placement: 'bottomLeft',
    duration: 2.5,
    style: {
      color: '#321850'
    }
  });
};
