import React, { FC, useEffect, useState } from 'react';
import './App.css';
import UserDetailsContext from './userDetails.context';
import { RouteComponentProps, withRouter, Route, Switch } from 'react-router';
import { AuthService } from './components/Auth/AuthService';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import QuoteForm from './components/Quote/QuoteForm';
import BidForm from './components/Bid/BidForm';
import QuoteDataForm from './components/QuoteData/QuoteDataForm';
import Callback from './components/Auth/Callback';
import Login from './components/Auth/Login';
import NotAuthorized from './components/Auth/NotAuthorized';
import IdentityError from './components/Auth/IdentityError';
import { RequireAuth } from './components/Auth/RequireAuth';
import BudgetList from './components/BudgetList/BudgetList';
import BudgetDetail from './components/BudgetDetail/BudgetDetail';
import RepairList from './components/RepairList/RepairList';

const App: FC<RouteComponentProps> = ({ history, location }) => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    AuthService.userManager.events.addUserLoaded((user: any) => {
      setUser(user);
    });
    AuthService.userManager.events.addUserSignedOut(() => {
      AuthService.redirectToAuthority();
    });

    document.title = 'Quote Module';
    if (location.pathname.includes('/budget')) {
      document.title = 'Budget Module';
    }
    if (location.pathname.includes('/repair')) {
      document.title = 'Repair Module';
    }
  }, [history, location]);

  return (
    <React.Fragment>
      <UserDetailsContext.Provider value={{ user: user }}>
        <Header />
        <Switch>
          <Route path='/callback' component={Callback} />
          <Route path='/login' component={Login} />
          <Route path='/not-authorized' component={NotAuthorized} />
          <Route path='/identity-error' component={IdentityError} />
          <Route path='/quotes' exact component={RequireAuth(Home)} />
          <Route path='/quotes/:id' exact component={RequireAuth(QuoteForm)} />
          <Route path='/quotes/:id/data' exact component={RequireAuth(QuoteDataForm)} />
          <Route path='/bid/:id' exact component={RequireAuth(BidForm)} />
          <Route path='/budget' exact component={RequireAuth(BudgetList)} />
          <Route path='/budget/:id' exact component={RequireAuth(BudgetDetail)} />
          <Route path='/repair' exact component={RequireAuth(RepairList)} />
          <Route path='/repair/:findingId' exact component={RequireAuth(RepairList)} />
          <Route path='/' exact component={RequireAuth(Home)} />
        </Switch>
        <Footer />
      </UserDetailsContext.Provider>
    </React.Fragment>
  );
};

export default withRouter(App);
