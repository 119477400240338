import React from 'react';
import QuoteTableAction from './TableAction/QuoteTableAction';
import Status from '../Shared/Status/Status';
import { BidderStatusesToDescription, CurrencyEnum } from '../../shared/enums';
import { getFormattedDate } from '../../shared/utils';
import SpanNumberFormat from '../Shared/Span/SpanNumberFormat';
import BidderExpandTableAction from './TableAction/BidderExpandTableAction';

type ColumnProps = {
  title: string;
  dataIndex?: string;
  key: string;
  render?: (annotation: any) => JSX.Element;
  width?: string;
  editable?: boolean;
};

const DEFAULT_COLUMN_WIDTH = '150px';

const ColumnWidths = new Map<string, string>([
  ['status', '200px'],
  ['siteName', '150px'],
  ['companyName', '250px'],
  ['description', '250px'],
  ['bidderInvitedDate', '250px'],
  ['bidPlacedDate', '250px'],
  ['action', '80px'],
  ['contactPerson', '80px']
]);

const getColumnWidth = (column: string) => {
  if (ColumnWidths.has(column)) {
    return ColumnWidths.get(column)!;
  }

  return DEFAULT_COLUMN_WIDTH;
};

const getCommonColumnProperties = (
  key: string,
  title: string,
  render?: ((record: string) => JSX.Element) | undefined,
  addDataIndex = true,
  editable?: boolean
) => ({
  title,
  dataIndex: addDataIndex ? key : undefined,
  key: key,
  width: getColumnWidth(key),
  render,
  editable
});

type Column = {
  key: string;
  title: string;
  addDataIndex: boolean;
  render?: ((record: any) => JSX.Element) | undefined;
  editable?: boolean;
};

const quoteListColumns: Column[] = [
  {
    key: 'actions',
    title: 'Actions',
    addDataIndex: false,
    render: (record: any): JSX.Element => <QuoteTableAction record={record} />
  },
  {
    key: 'intId',
    title: 'ID',
    addDataIndex: true
  },
  {
    key: 'siteName',
    title: 'Site',
    addDataIndex: true
  },
  {
    key: 'description',
    title: 'Description',
    addDataIndex: true
  },
  {
    key: 'status',
    title: 'Status',
    addDataIndex: true,
    render: (record: string): JSX.Element => <Status status={record} />
  },
  {
    key: 'tenderCloseDate',
    title: 'Tender close date',
    addDataIndex: true
  }
];

const bidderListColumns: Column[] = [
  {
    key: 'actions',
    title: 'Actions',
    addDataIndex: false,
    render: (record: any) => <BidderExpandTableAction bid={record} />
  },
  {
    key: 'companyName',
    title: 'Bidder',
    addDataIndex: true
  },
  {
    key: 'bidderInvitedDate',
    title: 'Sent Date',
    addDataIndex: true,
    render: (record: Date): JSX.Element => <span>{getFormattedDate(record)}</span>
  },
  {
    key: 'bidPlacedDate',
    title: 'Received Date',
    addDataIndex: true,
    render: (record: Date): JSX.Element => <span>{getFormattedDate(record)}</span>
  },
  {
    key: 'status',
    title: 'Status',
    addDataIndex: true,
    render: (record: string): JSX.Element => (
      <span>
        <Status status={BidderStatusesToDescription[record].name} />
      </span>
    )
  },
  {
    key: 'totalFixedCost',
    title: 'Total fixed cost',
    addDataIndex: true,
    render: (record: { value: number; currency: CurrencyEnum }): JSX.Element => (
      <SpanNumberFormat className='alignRight' value={record.value} hideZero={true} currency={record.currency} />
    )
  },
  {
    key: 'totalRepairCost',
    title: 'total repair cost',
    addDataIndex: true,
    render: (record: { value: number; currency: CurrencyEnum }): JSX.Element => (
      <SpanNumberFormat className='alignRight' value={record.value} hideZero={true} currency={record.currency} />
    )
  },
  {
    key: 'totalCost',
    title: 'Total cost',
    addDataIndex: true,
    render: (record: { value: number; currency: CurrencyEnum }): JSX.Element => (
      <SpanNumberFormat className='alignRight' value={record.value} hideZero={true} currency={record.currency} />
    )
  }
];

export const getQuoteListColumns = (): ColumnProps[] =>
  quoteListColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex));

export const getBidderListColumns = (): ColumnProps[] =>
  bidderListColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex));
