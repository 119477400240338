import React, { useState, useEffect, useRef } from 'react';
import { Input, Form } from 'antd';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { connect } from 'react-redux';
import { BidData, bidDataChanged } from './bid.slice';
import styles from './Bid.module.scss';
import InputNumber from '../Shared/InputNumber/InputNumber';

type EditableCellProps = {
  record: BidData;
  dataIndex: string;
  inputType: string;
  disabled?: boolean;
};

type Props = {
  onBidDataChanged: (bidData: BidData) => void;
};

const EditableCell: React.FC<EditableCellProps & Props> = ({ record, dataIndex, inputType, disabled, onBidDataChanged, ...restProps }) => {
  const [editing, setEditing] = useState(true);
  const [value, setValue] = useState(record[dataIndex]);

  useEffect(() => {
    setValue(value);
  }, [record, editing]);

  const save = async () => {
    record[dataIndex] = value;
    onBidDataChanged(record);
  };

  const onChangeNumber = (e: any) => {
    setValue(e);
    save();
  };
  const onChangeText = (e: any) => {
    setValue(e.target.value);
    save();
  };

  const inputNode =
    inputType === 'number' ? (
      <InputNumber disabled={disabled} value={value} setValueChange={onChangeNumber} color={true} onBlur={save} className={styles.numberFormat} />
    ) : (
      <Input.TextArea
        disabled={disabled}
        className={disabled ? 'customElementsDisabled' : ''}
        name={dataIndex}
        value={value}
        onPressEnter={save}
        onBlur={save}
        onChange={onChangeText}
      />
    );

  return (
    <div {...restProps} style={{ margin: 0 }}>
      {inputNode}
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: EditableCellProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onBidDataChanged: (bidData: BidData) => dispatch(bidDataChanged(bidData))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableCell);
