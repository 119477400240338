import { useContext, useEffect } from 'react';
import { FiltersContext } from '../hooks/useFilters';
import { FilterItem } from './utils';

export const useFilterItems = (
  isFiltersLoading: boolean,
  repairTypes: string[],
  countries: string[],
  sites: string[],
  inspections: string[],
  turbines: string[],
  blades: string[],
  bladeSides: string[],
  findingTypes: string[],
  severities: string[],
  responseLevels: string[],
  repairCompanies: string[]
) => {
  const {
    repairType,
    setRepairType,
    setLastChangedField,
    country,
    setCountry,
    site,
    setSite,
    inspection,
    setInspection,
    turbine,
    setTurbine,
    blade,
    setBlade,
    bladeSide,
    setBladeSide,
    findingType,
    setFindingType,
    severity,
    setSeverity,
    responseLevel,
    setResponseLevel,
    repairCompany,
    setRepairCompany,
    clearSelected,
    clearSelectedDefect
  } = useContext(FiltersContext);

  const cascadeFilters: FilterItem[] = [
    {
      loading: isFiltersLoading,
      label: 'Country',
      value: country,
      options: countries,
      onChange: (value: string[] | undefined) => {
        setCountry(value);
        setSite(undefined);
        setInspection(undefined);
        setTurbine(undefined);
        setLastChangedField('country');
      }
    },
    {
      loading: isFiltersLoading,
      label: 'Site',
      value: site,
      options: sites,
      onChange: (value: string[] | undefined) => {
        setSite(value);
        setInspection(undefined);
        setTurbine(undefined);
        setLastChangedField('site');
      }
    },
    {
      loading: isFiltersLoading,
      label: 'Inspection ID',
      value: inspection,
      options: inspections,
      onChange: (value: string[] | undefined) => {
        setInspection(value);
        setLastChangedField('inspection');
      }
    },
    {
      loading: isFiltersLoading,
      label: 'Turbine',
      value: turbine,
      options: turbines,
      onChange: (value: string[] | undefined) => {
        setTurbine(value);
        setLastChangedField('turbine');
      }
    }
  ];

  const filters: FilterItem[] = [
    {
      loading: isFiltersLoading,
      label: 'Status',
      value: repairType,
      options: repairTypes,
      onChange: setRepairType
    },
    {
      loading: isFiltersLoading,
      label: 'Blade Position',
      value: blade,
      options: blades,
      onChange: setBlade
    },
    {
      loading: isFiltersLoading,
      label: 'Blade Side',
      value: bladeSide,
      options: bladeSides,
      onChange: setBladeSide
    },
    {
      loading: isFiltersLoading,
      label: 'Finding Type',
      value: findingType,
      options: findingTypes,
      onChange: setFindingType
    },
    {
      loading: isFiltersLoading,
      label: 'Severity',
      value: severity,
      options: severities,
      onChange: setSeverity
    },
    {
      loading: isFiltersLoading,
      label: 'Response Level',
      value: responseLevel,
      options: responseLevels,
      onChange: setResponseLevel
    },
    {
      loading: isFiltersLoading,
      label: 'Repair Company',
      value: repairCompany,
      options: repairCompanies,
      onChange: setRepairCompany
    }
  ];

  const shouldBeDisabled = (label: string) => {
    switch (label) {
      case 'Country':
        return false;
      case 'Site':
        let countryValue = cascadeFilters.find(x => x.label === 'Country')!.value;
        if (countryValue && countryValue.length > 0) {
          return false;
        }
        break;
      case 'Inspection ID':
      case 'Turbine':
        if (cascadeFilters.find(x => x.label === 'Site')!.value) {
          return false;
        }
        break;
    }
    return true;
  };

  return {
    clearSelected,
    clearSelectedDefect,
    cascadeFilters,
    filters,
    shouldBeDisabled
  };
};
