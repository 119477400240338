import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import user from '../../assets/images/user.png';
import styles from './Header.module.scss';
import useAuth from '../Auth/UseAuth';
import { AuthService } from '../Auth/AuthService';
import SettingsModal from '../Settings/SettingsModal';
import HermesTiles from '../HermesTiles/HermesTiles';

export const HeaderInfo = () => {
  const [givenName, familyName] = useAuth();
  const [showSettings, setShowSettings] = useState(false);

  const logOut = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    AuthService.logout();
  };

  return (
    <>
      <div className={styles.headerInfo}>
        <HermesTiles></HermesTiles>
        <img src={user} alt='user' />
        <Dropdown className={styles.userName}>
          <Dropdown.Toggle variant='success' id='dropdown-basic'>
            {givenName} {familyName}
          </Dropdown.Toggle>
        </Dropdown>
        <span className={styles.delimiter}>|</span>
        <span className={styles.logOut}>
          <a href='#' onClick={logOut}>
            Log out
          </a>
        </span>
      </div>
      {showSettings && <SettingsModal onHide={() => setShowSettings(false)} show={showSettings} />}
    </>
  );
};

export default HeaderInfo;
