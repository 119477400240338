import React, { FC } from 'react';
import ReactNumeric from 'react-numeric';

type Props = {
  value: any;
  className: string;
  setValueChange: (x: any) => void;
  label?: string;
  disabled?: boolean;
  color?: boolean;
  onBlur?: () => void;
};

const InputNumber: FC<Props> = ({ value, className, setValueChange, label, disabled, color = true, onBlur }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <label>{label}</label>
        <ReactNumeric
          disabled={disabled}
          style={{ background: color ? 'PapayaWhip' : 'white', width: '150px' }}
          className={className}
          value={value}
          decimalCharacter=','
          digitGroupSeparator='.'
          onChange={(event: any, value: any) => setValueChange(value)}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};

export default InputNumber;
