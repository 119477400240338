import React, { FC, useEffect, useState, useCallback } from 'react';
import { Modal, Form, Input, Select, Spin } from 'antd';
import Button from '../Shared/Button/Button';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import styles from './Bidder.module.scss';
import { saveBidder, showBidderModal, Bidder, getCompanies } from './bidder.slice';
import { IdText, Quote, saveQuote } from '../Quote/quote.slice';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';

type Props = {
  bidder: Bidder;
  quote: Quote;
  onShowModal: (visible: boolean) => void;
  onBidderSave: (bidder: Bidder) => void;
  companies: Array<IdText>;
  getCompanies: () => void;
  onSave: (quote: Quote) => void;
};
const BidderForm: FC<Props> = ({ onShowModal, bidder, onBidderSave, companies, getCompanies, quote, onSave }) => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    if (bidder.companyName) {
      setId(bidder.id!);
      form.setFieldsValue({
        company: bidder.companyName,
        address: bidder.address,
        contactPerson: bidder.contactPerson,
        email: bidder.email,
        phone: bidder.phone
      });
    }
  }, [bidder, form]);

  const handleSubmit = async (e: ButtonSubmitEvent) => {
    e.preventDefault();

    try {
      await form.validateFields();

      const newItem: Bidder = {
        id: id,
        invitedDate: bidder.invitedDate,
        quoteId: quote.id,
        companyName: companyName.length ? companyName : bidder.companyName,
        companyId: companyName.length ? companies.find(x => x.text === companyName)!.id : companies.find(x => x.text === bidder.companyName)!.id,
        address: form.getFieldValue('address'),
        contactPerson: form.getFieldValue('contactPerson'),
        email: form.getFieldValue('email'),
        phone: form.getFieldValue('phone'),
        receivedDate: bidder.receivedDate,
        status: bidder.status
      };

      onBidderSave(newItem);
      onSave(quote);
      onShowModal(false);
    } catch (error) {}
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 }
    }
  };

  const companySelected = (value: string) => {
    setCompanyName(value);
  };

  return (
    <Modal
      title={bidder.companyName ? 'Update Bidder' : 'Add Bidder'}
      visible={true}
      onCancel={() => onShowModal(false)}
      footer={[
        <Button title='Save' type='button' key='save' onClick={e => handleSubmit(e)} />,
        <Button className={styles.cancelButton} title='Cancel' type='button' key='back' onClick={() => onShowModal(false)} />
      ]}
    >
      <Form {...formItemLayout} layout='vertical' form={form}>
        <Form.Item name='company' label='Company' rules={[{ required: true, message: 'Select company', whitespace: true }]}>
          <Select
            showSearch
            notFoundContent={companyName == '' ? <Spin size='small' /> : null}
            style={{ width: '100%' }}
            placeholder='Please select'
            optionFilterProp='children'
            onChange={companySelected}
            value={companyName}
          >
            {companies.map(s => (
              <Select.Option disabled={quote.bidders.some(item => item.companyName === s.text)} key={s.id} value={s.text}>
                {s.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Address'
          name='address'
          className={styles.antFormItem}
          rules={[{ required: true, message: 'Enter company address', whitespace: true }]}
        >
          <Input placeholder='Enter company address' />
        </Form.Item>
        <Form.Item
          label='Contact Person'
          name='contactPerson'
          className={styles.antFormItem}
          rules={[{ required: true, message: 'Enter contact person' }]}
        >
          <Input placeholder='Enter contact person' />
        </Form.Item>
        <Form.Item label='Email' name='email' className={styles.antFormItem} rules={[{ type: 'email', required: true, message: 'Enter email' }]}>
          <Input placeholder='Enter email' />
        </Form.Item>
        <Form.Item label='Phone' name='phone' className={styles.antFormItem} rules={[{ required: false, message: 'Enter phone' }]}>
          <Input placeholder='Enter phone' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const MapStateToProps = (state: RootState) => ({
  bidder: state.bidder.bidder,
  companies: state.bidder.companies,
  quote: state.quote.quote
});

const MapDispatchToProps = (dispatch: AppDispatch) => ({
  onShowModal: (visible: boolean) => dispatch(showBidderModal(visible)),
  onBidderSave: (bidder: Bidder) => dispatch(saveBidder(bidder)),
  onSave: (quote: Quote) => dispatch(saveQuote(quote)),
  getCompanies: () => dispatch(getCompanies)
});

export default connect(MapStateToProps, MapDispatchToProps)(BidderForm);
