import React, { FC, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';
import { onDeleteAttachment, onTogggleAttachmentVisibility, RepairInfoAttachments } from './repairList.slice';
import { Button, Divider } from 'antd';
import ConfirmDialog from '../Shared/ConfirmDialog/ConfirmDialog';
import { AppDispatch } from '../../store';

type RepairTableActionProps = {
  record: RepairInfoAttachments;
};

type StoreProps = {
  onDeleteAttachment: (repairInfoId: string, id: string) => void;
  onTogggleAttachmentVisibility: (repairInfoId: string, id: string) => void;
};

const RepairTableAction: FC<RepairTableActionProps & RouteComponentProps & StoreProps> = ({
  record,
  history,
  onDeleteAttachment,
  onTogggleAttachmentVisibility
}) => {
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [canToggleVisibility, setCanToggleVisibility] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.RepairManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var canTogglePermission = await AuthService.hasPermission([PermissionEnum.RepairManager_CanToggleVisibility.toString()]);
    setCanToggleVisibility(canTogglePermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  const onDownload = () => {
    const win = window.open(record.url, '_blank');
    win?.focus();
  };

  const onDeleteRepairItem = () => {
    onDeleteAttachment(record.repairInfoId, record.id);
  };

  const togggleAttachmentVisibility = () => {
    onTogggleAttachmentVisibility(record.repairInfoId, record.id);
  };

  const deleteRepairItemModal = () => {
    ConfirmDialog(onDeleteRepairItem, 'Are you sure you want to delete attachment?');
  };

  if (record.errorOccurred) {
    return (
      <div>
        <span>Error occured</span>
      </div>
    );
  }

  if (record.isUploading) {
    return (
      <div>
        <span>Uploading...</span>
      </div>
    );
  }

  if (!record.isUploaded) {
    return (
      <div>
        <span>Scheduled for upload</span>
      </div>
    );
  }

  return (
    <div>
      <span>
        <Button type='link' onClick={onDownload}>
          Open
        </Button>
      </span>
      {record.canBeDeleted && (
        <>
          <Divider type='vertical' />
          <span>
            <Button type='link' onClick={deleteRepairItemModal}>
              Delete
            </Button>
          </span>
        </>
      )}
      {canToggleVisibility && (
        <>
          <Divider type='vertical' />
          <span>
            <Button type='link' onClick={togggleAttachmentVisibility}>
              Toggle visibility
            </Button>
          </span>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: RepairTableActionProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onDeleteAttachment: (repairInfoId: string, id: string) => dispatch(onDeleteAttachment(repairInfoId, id)),
  onTogggleAttachmentVisibility: (repairInfoId: string, id: string) => dispatch(onTogggleAttachmentVisibility(repairInfoId, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RepairTableAction));
