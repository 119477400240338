import { createSelector } from 'reselect';
import { RootState } from '../../rootReducer';
import { RepairDetail, RepairInfoAttachments } from './repairList.slice';
import { getRepairListColumns, getRepairListInfoColumns, getRepairListInfoAttachmentsColumns } from './repairListHelper';

export type RepairInfoWithAttachments = RepairDetail & { attachments: RepairInfoAttachments[] };

const repairList = (state: RootState) => state.repairList.repairList;
const addedAttachmentsList = (state: RootState) => state.repairList.addedAttachments;
const repairListInfo = (state: RootState) => state.repairList.repairListInfo;
const repairListInfoAttachments = (state: RootState) => state.repairList.repairInfoAttachment;

export const prepareRepairListSelector = createSelector([repairList, addedAttachmentsList], (gridData, addedAttachments) => {
  if (!gridData || gridData.length === 0) {
    return {
      columns: [],
      dataSource: []
    };
  }

  let columns = getRepairListColumns();

  let dataSource = gridData.map(x => ({
    ...x,
    key: x.findingId
  }));

  dataSource = dataSource.map(x => {
    let attachments: RepairInfoAttachments[] = [];

    if (x.attachments) {
      attachments = x.attachments.map(y => {
        const addedAttachment = addedAttachments.find(x => x.repairInfoId === y.repairInfoId && x.id === y.id);

        let attachment = {
          ...y
        };

        if (addedAttachment) {
          attachment.isUploading = addedAttachment.isUploading;
          attachment.errorOccurred = addedAttachment.errorOccurred;
          attachment.url = addedAttachment.url;
          attachment.key = addedAttachment.id;
          attachment.id = addedAttachment.id;
          attachment.repairInfoId = addedAttachment.repairInfoId;
          attachment.isUploaded = !!addedAttachment.url;
          attachment.uploadDate = addedAttachment.uploadDate!;
        }

        return attachment;
      });
    }

    return {
      ...x,
      attachments
    };
  });

  return {
    columns,
    dataSource
  };
});

export const prepareRepairInfoListSelector = createSelector([repairList], gridData => {
  if (!gridData || gridData.length === 0) {
    return {
      columns: [],
      dataSource: []
    };
  }

  let columns = getRepairListInfoColumns();

  const dataSource = gridData.map(x => ({
    ...x
  }));

  return {
    columns,
    dataSource
  };
});

export const prepareRepairInfoAttachmentSelector = createSelector([repairListInfoAttachments], gridData => {
  if (!gridData || gridData.length === 0) {
    return {
      columns: [],
      dataSource: []
    };
  }

  let columns = getRepairListInfoAttachmentsColumns();

  const dataSource = gridData.map(x => ({
    ...x
  }));

  return {
    columns,
    dataSource
  };
});
