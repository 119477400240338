import { combineReducers } from '@reduxjs/toolkit';
import quoteListReducer from './components/QuoteList/quoteList.slice';
import quoteReducer from './components/Quote/quote.slice';
import bidderReducer from './components/Bidder/bidder.slice';
import bidReducer from './components/Bid/bid.slice';
import quoteDataReducer from './components/QuoteData/quoteData.slice';
import budgetListReducer from './components/BudgetList/budgetList.slice';
import budgetDetailReducer from './components/BudgetDetail/budgetDetail.slice';
import repairListReducer from './components/RepairList/repairList.slice'

const rootReducer = combineReducers({
  quoteList: quoteListReducer,
  quote: quoteReducer,
  bidder: bidderReducer,
  bid: bidReducer,
  quoteData: quoteDataReducer,
  budgetList: budgetListReducer,
  budgetDetail: budgetDetailReducer,
  repairList: repairListReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;