import React, { FC, useState, useEffect, useCallback } from 'react';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Select, Table, Input, PageHeader, Layout, Tooltip } from 'antd';
import Button from '../Shared/Button/Button';
import styles from './Bid.module.scss';
import {
  Bid,
  BidData,
  saveBid,
  bidDataChanged,
  bidReward,
  getBid,
  getQuoteDataFindings,
  exportBid,
  getFinding,
  setHasAdminPermission,
  setHasBidderPermission
} from './bid.slice';
import { BidderStatusEnum, CurrencyEnum, PermissionEnum } from '../../shared/enums';
import { getBidDataColumns, showRewardBidButton } from '../Bid/BidDataHelper';
import { RouteComponentProps, withRouter } from 'react-router';
import { BidderStatusesToDescription } from '../../shared/enums';
import { getFormattedDate } from '../../shared/utils';
import NumberFormat from 'react-number-format';
import { AuthService } from '../Auth/AuthService';
import InputNumber from '../Shared/InputNumber/InputNumber';
import ConfirmDialog from '../Shared/ConfirmDialog/ConfirmDialog';
import { QuoteDetail } from '../QuoteList/quoteList.slice';
import FindingInfoCard from '../Shared/FindingInfoCard/FindingInfoCard';
import { QuoteDataFinding } from '../QuoteData/quoteData.slice';
import moment from 'moment';

type Props = {
  loading: boolean;
  bid: Bid;
  bidData: BidData[];
  quotes: QuoteDetail[];
  finding: any;
  exportToExcelInProgress: boolean;
  hasAdminPermission: boolean;
  hasBidderPermission;
  onBidSave: (bid: Bid, submit: boolean) => void;
  onBidDataChanged: (bidData: BidData) => void;
  onBidReward: (bid: Bid) => void;
  onGetBid: (id: string) => void;
  onExportBid: (bid: Bid) => void;
  getQuoteDataFindings: (quoteId: string, quoteDataId: string) => void;
  getFinding: (id: any) => void;
  onSetHasAdminPermission: (value: boolean) => void;
  onSetHasBidderPermission: (value: boolean) => void;
};

const BidForm: FC<Props & RouteComponentProps<{ id: string }>> = ({
  loading,
  exportToExcelInProgress,
  bidData,
  bid,
  quotes,
  finding,
  hasAdminPermission,
  hasBidderPermission,
  onBidSave,
  onBidReward,
  history,
  match,
  onGetBid,
  getQuoteDataFindings,
  onExportBid,
  getFinding,
  onSetHasAdminPermission,
  onSetHasBidderPermission
}) => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [vesselType, setVesselType] = useState('');
  const [expProjectDuration, setExpectedProjectDuration] = useState(0);
  const [numberOfTeam, setNumberOfTeam] = useState(0);
  const [teamSize, setTeamSize] = useState(0);
  const [currency, setCurrency] = useState(CurrencyEnum.Euro);
  const [vesselCost, setVesselCost] = useState(0);
  const [mobDemobCost, setMobDemobCost] = useState(0);
  const [trainingCost, setTrainingCost] = useState(0);
  const [weatherRiskCost, setWeatherRiskCost] = useState(0);
  const [additionalCost, setAdditionalCost] = useState(0);
  const [totalRepairCost, setTotalRepairCost] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [showFindingInfoCard, setShowFindingInfoCard] = useState<boolean>(false);
  const [findingInfoCardData, setFindingInfoCardData] = useState<QuoteDataFinding | null>(null);
  const [expandedRow, setExpandedRow] = useState<string[] | undefined>();

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    onSetHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    onSetHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    let totalRepairCost = 0;
    bidData.forEach(element => {
      totalRepairCost += element.tooling + element.additionalCosts + element.totalManPower;
    });
    setTotalRepairCost(totalRepairCost);
  }, [bidData]);

  useEffect(() => {
    const { id } = match.params;
    onGetBid(id);
  }, [match]);

  useEffect(() => {
    if (bid) {
      setId(bid.id!);
      form.setFieldsValue({
        invitedDate: bid.bidderInvitedDate,
        bidPlacedDate: bid.bidPlacedDate,
        rewardedDate: bid.rewardedDate,
        vesselType: bid.vesselType,
        expectedProjectDuration: bid.expectedProjectDuration,
        numberOfTeamAssigned: bid.numberOfTeamAssigned,
        teamSize: bid.teamSize,
        currency: bid.currency,

        totalFixedCost: bid.vesselCost + bid.mobDemobCost + bid.trainingCost + bid.weatherRiskCost + bid.additionalCost,
        totalRepairCost: 0
      });
      setVesselCost(bid.vesselCost);
      setMobDemobCost(bid.mobDemobCost);
      setAdditionalCost(bid.additionalCost);
      setTrainingCost(bid.trainingCost);
      setWeatherRiskCost(bid.weatherRiskCost);
    }
  }, [bid, form]);

  useEffect(() => {
    form.setFieldsValue({
      totalFixedCost: bid.vesselCost + bid.mobDemobCost + bid.trainingCost + bid.weatherRiskCost + bid.additionalCost
    });
  });

  const getTotalFixedCost = () => {
    return vesselCost + mobDemobCost + trainingCost + weatherRiskCost + additionalCost;
  };

  const getBidDataTableContent = (bid: Bid) => {
    if (!bid.bidData || (bid.bidData && bid.bidData.length === 0)) {
      return {
        isLoading: false,
        columns: [],
        dataSource: []
      };
    }

    const dataSource = bidData.map(x => ({
      ...x,
      key: x.id
    }));

    let columns = getBidDataColumns(onRowChanged);

    return {
      isLoading: false,
      columns,
      dataSource
    };
  };

  const onRowChanged = (record: any) => {};

  let bidDataGrid: any = '';
  bidDataGrid = getBidDataTableContent(bid);

  const updateBid = async (submitBid: boolean) => {
    try {
      await form.validateFields();

      const newItem: Bid = {
        id: id,
        quoteId: bid.quoteId,
        quoteIntId: bid.quoteIntId,
        bidderId: bid.bidderId,
        companyName: bid.companyName,
        bidderStatus: bid.bidderStatus,
        bidderInvitedDate: '',
        bidPlacedDate: '',
        rewardedDate: '',
        tenderCloseDate: '',
        tenderExtension: null,
        totalManPower: bid.totalManPower,
        estimatedHours: bid.estimatedHours,
        vesselType: form.getFieldValue('vesselType'),
        currency: form.getFieldValue('currency'),
        expectedProjectDuration: form.getFieldValue('expectedProjectDuration'),
        mobDemobCost: mobDemobCost,
        numberOfTeamAssigned: form.getFieldValue('numberOfTeamAssigned'),
        teamSize: form.getFieldValue('teamSize'),
        weatherRiskCost: weatherRiskCost,
        trainingCost: trainingCost,
        vesselCost: vesselCost,
        additionalCost: additionalCost,
        totalCases: 0,
        totalNumberOfWTG: 0,
        totalNumberOfBlades: 0,
        canEdit: true,
        bidData: bidData
      };

      onBidSave(newItem, submitBid);
    } catch (error) {}
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    updateBid(false);
  };

  const currencies = Object.keys(CurrencyEnum).filter(key => !isNaN(Number(CurrencyEnum[key])));

  const handleReward = async () => {
    onBidReward(bid);
    history.push('/');
  };

  const handleSubmitBid = async () => {
    updateBid(true);
  };

  const submitBid = () => {
    ConfirmDialog(handleSubmitBid, 'Are you sure you want to submit bid?');
  };

  const rewardBid = () => {
    ConfirmDialog(handleReward, 'Are you sure you want to reward bid?');
  };

  const onBack = () => {
    history.push('/');
  };

  const onExpand = (expanded: boolean, record: BidData) => {
    if (!expanded) {
      setExpandedKeys([...expandedKeys.filter(x => x !== record.bidId)]);

      return;
    }
    getQuoteDataFindings(bid.quoteId, record.quoteDataId);
    setExpandedKeys([...expandedKeys, record.bidId]);
  };

  const onExpandFindingTable = (expanded: boolean, record: QuoteDataFinding) => {
    setExpandedRow(expanded ? [record.id] : undefined);
    expanded && getFinding(record.id);
    setFindingInfoCardData(record);
    setShowFindingInfoCard(expanded);
  };

  const findingColumns = [
    {
      title: 'Inspection Id',
      dataIndex: 'inspectionId',
      key: 'inspectionId'
    },
    {
      title: 'Response Level',
      dataIndex: 'defectMaintenanceTriggerCode',
      key: 'defectMaintenanceTriggerCode'
    },
    {
      title: 'Turbine Name',
      dataIndex: 'turbineName',
      key: 'turbineName'
    },
    {
      title: 'Turbine Serial No',
      dataIndex: 'turbineSerial',
      key: 'turbineSerial'
    },
    {
      title: 'Blade Position',
      dataIndex: 'bladePosition',
      key: 'bladePosition'
    },
    {
      title: 'Blade Serial No',
      dataIndex: 'bladeSerial',
      key: 'bladeSerial'
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity'
    },
    {
      title: 'Layer',
      dataIndex: 'layer',
      key: 'layer'
    }
  ];

  const handleExport = async (e: any) => {
    e.preventDefault();

    onExportBid(bid);
  };
  const { Option } = Select;
  const { Content } = Layout;

  const rowSelection = {
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      setShowFindingInfoCard(selected);
    }
  };

  const isTenderClosed = () => {
    if (bid.tenderCloseDate) {
      return moment().isAfter(moment(bid.tenderCloseDate).add(bid.tenderExtension, 'd'), 'd');
    }
  };

  return (
    <Layout>
      {!loading && (
        <>
          <Content>
            <PageHeader
              className='QBRHeader'
              onBack={onBack}
              title={bid.id ? 'Bid Details: ' + bid.companyName : 'Add Bid'}
              extra={[
                hasAdminPermission &&
                  (bid.bidderStatus === BidderStatusEnum.BidReceived || bid.bidderStatus === BidderStatusEnum.BidRejected
                    ? showRewardBidButton(quotes, bid, rewardBid)
                    : ''),
                hasBidderPermission &&
                  (bid.bidderStatus === BidderStatusEnum.WaitingForBid ? (
                    <Tooltip title={isTenderClosed() ? 'Tender is closed' : undefined} color='white' overlayInnerStyle={{ color: '#321850' }}>
                      <span className='xsw' style={{ cursor: isTenderClosed() ? 'auto' : 'pointer' }}>
                        <Button
                          style={{ pointerEvents: isTenderClosed() ? 'none' : 'auto' }}
                          key='submit'
                          onClick={submitBid}
                          type='button'
                          disabled={isTenderClosed()}
                          title='Submit bid'
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    ''
                  )),
                hasBidderPermission && bid.canEdit && (
                  <Tooltip title={isTenderClosed() ? 'Tender is closed' : undefined} color='white' overlayInnerStyle={{ color: '#321850' }}>
                    <span style={{ cursor: isTenderClosed() ? 'auto' : 'pointer' }}>
                      <Button
                        style={{ pointerEvents: isTenderClosed() ? 'none' : 'auto' }}
                        title='Save'
                        type='button'
                        key='save'
                        disabled={isTenderClosed()}
                        onClick={handleSave}
                      ></Button>
                    </span>
                  </Tooltip>
                ),

                <Button title='Export to Excel' type='button' key='export' onClick={handleExport} isLoading={exportToExcelInProgress}></Button>
              ]}
            />

            <Form layout='horizontal' form={form}>
              <Card className='bid-details'>
                <Row gutter={16}>
                  <Col span={6} style={{ textAlign: 'end' }}>
                    <Form.Item label='Quote ID' className={styles.antFormItem}>
                      <span className={styles.quoteID}>{bid.quoteIntId}</span>
                    </Form.Item>
                    <Form.Item label='Status' className={styles.antFormItem}>
                      {BidderStatusesToDescription[bid.bidderStatus].name}
                      {/* {GetDescriptionBidderStatus(status)} */}
                      {/* <Select  className={styles.input}
                    placeholder='Status'
                    disabled = {true}
                    value = {status}
                    onChange={statusSelected}>
                    {statuses}
                  </Select> */}
                    </Form.Item>
                    <Form.Item name='invitedDate' label='Invited' className={styles.antFormItem}>
                      {getFormattedDate(bid.bidderInvitedDate)}
                    </Form.Item>
                    <Form.Item name='submittedDate' label='Received' className={styles.antFormItem}>
                      {getFormattedDate(bid.bidPlacedDate)}
                    </Form.Item>
                    <Form.Item name='rewardedDate' label='Rewarded' className={styles.antFormItem}>
                      {getFormattedDate(bid.rewardedDate)}
                    </Form.Item>

                    <Form.Item name={'vesselType'} label='Vessel type' className={styles.antFormItem}>
                      <Input
                        disabled={hasAdminPermission}
                        style={{ background: 'PapayaWhip' }}
                        className={styles.input}
                        placeholder='Enter vessel type'
                        value={vesselType}
                        onChange={e => {
                          setVesselType(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='expectedProjectDuration' label='Expected project duration' className={styles.antFormItem}>
                      <Input
                        disabled={hasAdminPermission}
                        style={{ background: 'PapayaWhip' }}
                        className={styles.input}
                        placeholder='Enter expected project duration'
                        value={expProjectDuration}
                        onChange={e => {
                          setExpectedProjectDuration(parseInt(e.target.value));
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='numberOfTeamAssigned' label='Number of team assigned' className={styles.antFormItem}>
                      <Input
                        disabled={hasAdminPermission}
                        style={{ background: 'PapayaWhip' }}
                        className={styles.input}
                        placeholder='Enter number of team assigned'
                        value={numberOfTeam}
                        onChange={e => {
                          setNumberOfTeam(parseInt(e.target.value));
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='teamSize' label='Team size' className={styles.antFormItem}>
                      <Input
                        disabled={hasAdminPermission}
                        style={{ background: 'PapayaWhip' }}
                        className={styles.input}
                        placeholder='Enter team size'
                        value={teamSize}
                        onChange={e => {
                          setTeamSize(parseInt(e.target.value));
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='currency' label='Currency' className={styles.antFormItem}>
                      <Select
                        disabled={hasAdminPermission}
                        style={{ background: 'PapayaWhip' }}
                        className={styles.input}
                        placeholder='Enter currency'
                        value={currency}
                        onChange={e => {
                          setCurrency(e);
                        }}
                      >
                        {currencies.map(t => (
                          <Option key={t} value={t}>
                            {t}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ textAlign: 'end' }}>
                    <Card title='Fixed cost'>
                      <InputNumber
                        disabled={hasAdminPermission}
                        value={vesselCost}
                        setValueChange={setVesselCost}
                        label='Vessel cost :'
                        className={styles.numberFormat}
                      />

                      <InputNumber
                        disabled={hasAdminPermission}
                        value={mobDemobCost}
                        setValueChange={setMobDemobCost}
                        label='Mob / demob cost :'
                        className={styles.numberFormat}
                      />

                      <InputNumber
                        disabled={hasAdminPermission}
                        value={trainingCost}
                        setValueChange={setTrainingCost}
                        label='Training cost :'
                        className={styles.numberFormat}
                      />

                      <InputNumber
                        disabled={hasAdminPermission}
                        value={weatherRiskCost}
                        setValueChange={setWeatherRiskCost}
                        label='Total weather risk cost :'
                        className={styles.numberFormat}
                      />

                      <InputNumber
                        disabled={hasAdminPermission}
                        value={additionalCost}
                        setValueChange={setAdditionalCost}
                        label='Additional cost :'
                        className={styles.numberFormat}
                      />
                    </Card>
                  </Col>
                  <Col span={6} style={{ textAlign: 'end' }}>
                    <Card title='Summary' className='bid-details'>
                      <Col>
                        <Form.Item label='Total number of WTGs' className={styles.antFormItem}>
                          <Input
                            disabled={true}
                            className={styles.input}
                            style={{ background: 'White', color: 'black' }}
                            placeholder='Total number of WTGs'
                            value={bid.totalNumberOfWTG}
                          />
                        </Form.Item>
                        <Form.Item label='Total number of blades' className={styles.antFormItem}>
                          <Input
                            disabled={true}
                            className={styles.input}
                            style={{ background: 'White', color: 'black' }}
                            placeholder='Total number of blades'
                            value={bid.totalNumberOfBlades}
                          />
                        </Form.Item>
                        <Form.Item label='Total number of cases' className={styles.antFormItem}>
                          <Input
                            disabled={true}
                            className={styles.input}
                            style={{ background: 'White', color: 'black' }}
                            placeholder='Total number of cases'
                            value={bid.totalCases}
                          />
                        </Form.Item>
                      </Col>
                    </Card>
                  </Col>
                  <Col span={6} style={{ textAlign: 'end' }}>
                    <Card title='Total cost'>
                      <Col>
                        <Form.Item label='Total fixed cost' className={styles.antFormItem}>
                          <NumberFormat
                            disabled={true}
                            className={styles.numberFormat}
                            style={{ background: 'AliceBlue', color: 'black', width: '150px' }}
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            placeholder='Total fixed cost'
                            value={getTotalFixedCost()}
                          />
                        </Form.Item>
                        <Form.Item label='Total repair cost' className={styles.antFormItem}>
                          <NumberFormat
                            disabled={true}
                            className={styles.numberFormat}
                            style={{ background: 'AliceBlue', color: 'black', width: '150px' }}
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            placeholder='Total repair cost'
                            value={totalRepairCost}
                          />
                        </Form.Item>
                        <Form.Item label='Total cost' className={styles.antFormItem}>
                          <NumberFormat
                            className={styles.numberFormat}
                            disabled={true}
                            style={{ background: 'AliceBlue', color: 'black', width: '150px' }}
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            placeholder='Total cost'
                            value={getTotalFixedCost() + totalRepairCost}
                          />
                        </Form.Item>
                      </Col>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <Row style={{ width: '100%' }}>
                <Col style={{ width: '100%', marginBottom: '50px' }}>
                  <Card title='Bid data'>
                    {/* <div style={{ display: 'flex', justifyContent: ' space-between' }}> */}

                    <Table
                      size='small'
                      // pagination={false}
                      className='bidder-table'
                      scroll={{ x: 1 }}
                      columns={bidDataGrid.columns}
                      dataSource={bidDataGrid.dataSource}
                      rowKey='id'
                      expandedRowRender={(record: BidData, index: any, indent: any, expanded: boolean) => (
                        <Table
                          style={{ transition: 'all 1s ease-in' }}
                          className='inner-table-pagination'
                          columns={findingColumns}
                          dataSource={record.findings}
                          onExpand={onExpandFindingTable}
                          expandedRowKeys={expandedRow}
                          expandable={{
                            expandedRowRender: (record: any) => {
                              if (showFindingInfoCard) {
                                return <FindingInfoCard title={'Severity'} record={findingInfoCardData} />;
                              }
                            }
                          }}
                          rowKey='id'
                          pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                        />
                      )}
                      onExpand={onExpand}
                      pagination={{
                        //current: currentPage,
                        //total: totalNumber,
                        //pageSize: TAKE,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        onChange: (page: number) => {
                          //getQuoteList(page);
                        }
                      }}
                    />

                    {/* </div> */}
                  </Card>
                </Col>
              </Row>
            </Form>
          </Content>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  bid: state.bid.bid,
  bidData: state.bid.bidData,
  loading: state.bid.isLoading,
  finding: state.bid.finding,
  exportToExcelInProgress: state.bid.exportToExcelInProgress,
  hasAdminPermission: state.bid.hasAdminPermission,
  hasBidderPermission: state.bid.hasBidderPermission
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onBidSave: (bid: Bid, submit: boolean) => dispatch(saveBid(bid, submit)),
  onBidDataChanged: (bidData: BidData) => dispatch(bidDataChanged(bidData)),
  onBidReward: (bid: Bid) => dispatch(bidReward(bid)),
  onGetBid: (id: string) => dispatch(getBid(id)),
  getQuoteDataFindings: (quoteId: string, quoteDataId: string) => dispatch(getQuoteDataFindings(quoteId, quoteDataId)),
  onExportBid: (bid: Bid) => dispatch(exportBid(bid)),
  getFinding: (id: any) => dispatch(getFinding(id)),
  onSetHasAdminPermission: (value: boolean) => dispatch(setHasAdminPermission(value)),
  onSetHasBidderPermission: (value: boolean) => dispatch(setHasBidderPermission(value))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BidForm));
