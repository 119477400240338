import React, { FC } from 'react';
import styles from './FindingInfoCard.module.scss';
import { roundNumberTo2Decimals } from './Helpers';

type Props = {
  distanceToRoot: number;
  length: number;
  area: number;
};

const RLA: FC<Props> = ({ distanceToRoot, length, area }) => {
  return (
    <div className={styles.cardRla}>
      <div>
        <h2>{roundNumberTo2Decimals(distanceToRoot)}</h2>
        <span>R(m)</span>
      </div>
      <div>
        <h2>{roundNumberTo2Decimals(length)}</h2>
        <span>L(mm)</span>
      </div>
      <div>
        <h2>{roundNumberTo2Decimals(area)}</h2>
        <span>
          A(mm<sup>2</sup>)
        </span>
      </div>
    </div>
  );
};

export default RLA;
