import React, { FC, useContext } from 'react';
import { Input } from 'antd';
import { FilesContext } from './util/FilesContext';
import { AttachmentTable } from './repairList.slice';

type Props = {
  attachment: AttachmentTable;
};

const Label: FC<Props> = ({ attachment }) => {
  const { toScheduleFiles, setToScheduleFiles } = useContext(FilesContext);

  const toScheduleFile = toScheduleFiles.find(x => x.file.name === attachment.fileName);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToScheduleFiles(
      toScheduleFiles.map(x => {
        if (x.file === toScheduleFile?.file) {
          return {
            ...x,
            label: event.target.value
          };
        }

        return x;
      })
    );
  };

  return (
    <div>
      <Input value={toScheduleFile?.label} onChange={onChange} />
    </div>
  );
};

export default Label;
