import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import axios from '../../axios';
import { QuoteStatusEnum } from '../../shared/enums';
import { setSelectedQuote, Quote, initialQuote } from '../Quote/quote.slice';
import { Bidder, BidderExpanded } from '../Bidder/bidder.slice';
import { ToastAnt } from '../Toast/ToastAnt';
export const TAKE = 10;

type PagingConfiguration = {
  take: number;
  skip: number;
  siteSearchTerm: string;
  descriptionSearchTerm: string;
  statusSearchTerm: QuoteStatusEnum;
};

type PagingResult = {
  totalNumberOfRecords?: number;
  items: QuoteDetail[];
};

export interface QuoteDetail {
  id: string;
  intId?: number | undefined;
  statusEnum: QuoteStatusEnum;
  status: string;
  projectReference: string;
  siteId: string | undefined;
  siteName: string | undefined;
  description: string;
  comment: string;
  plannedWorkStart: Date | '';
  plannedWorkCompleted: Date | '';
  tenderCloseDate: Date | '';
  awardedDate: Date | '';
  tenderExtension: number | '';
  bidders: BidderExpanded[];
}

type State = {
  quotes: QuoteDetail[];
  initialStatus: QuoteStatusEnum;
  currentPage: number;
  totalNumberOfQuotes: number;
  isLoading: boolean;
  siteSearchTerm: string;
  descriptionSearchTerm: string;
  statusSearchTerm: QuoteStatusEnum;
  showDeleteQuoteDialog: boolean;
  selectedQuoteForEdit: Quote | null;
  selectedQuoteIdForDelete: string | null;
  selectedBidderForEdit: Bidder | null;
};

const initialState: State = {
  quotes: [],
  currentPage: 0,
  initialStatus: 0,
  totalNumberOfQuotes: 0,
  isLoading: true,
  siteSearchTerm: '',
  descriptionSearchTerm: '',
  statusSearchTerm: 0,
  showDeleteQuoteDialog: false,
  selectedQuoteForEdit: null,
  selectedQuoteIdForDelete: null,
  selectedBidderForEdit: null
};

const quoteListSlice = createSlice({
  name: 'quoteList',
  initialState,
  reducers: {
    setShowDeleteQuoteDialog(state, action: PayloadAction<boolean>) {
      state.showDeleteQuoteDialog = action.payload;
    },
    setQuoteIdForDelete(state, action: PayloadAction<string | null>) {
      state.selectedQuoteIdForDelete = action.payload;
    },
    setQuoteList(state, action: PayloadAction<{ quotes: QuoteDetail[]; currentPage: number; totalNumberOfQuotes?: number }>) {
      const { quotes, currentPage, totalNumberOfQuotes } = action.payload;
      state.quotes = quotes;
      state.currentPage = currentPage;
      if (totalNumberOfQuotes) {
        state.totalNumberOfQuotes = totalNumberOfQuotes;
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setQuoteBidder(state, action: PayloadAction<{ quoteId: string; bidders: BidderExpanded[] }>) {
      const { quoteId, bidders } = action.payload;
      state.quotes = state.quotes.map(x => {
        if (x.id === quoteId) {
          return {
            ...x,
            bidders
          };
        }
        return x;
      });
    },
    setSearchTerms(state, action: PayloadAction<{ site: string; description: string; status: QuoteStatusEnum }>) {
      const { description, status, site } = action.payload;
      state.siteSearchTerm = site;
      state.descriptionSearchTerm = description;
      state.statusSearchTerm = status;
    }
  }
});

export const { setQuoteList, setLoading, setQuoteBidder, setSearchTerms, setShowDeleteQuoteDialog, setQuoteIdForDelete } = quoteListSlice.actions;

export default quoteListSlice.reducer;

export const getQuote =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    if (id) {
      const response = await axios.get(`quote/${id}`);
      dispatch(setSelectedQuote(response.data));
    } else {
      dispatch(setSelectedQuote(initialQuote));
    }

    dispatch(setLoading(false));
  };

export const getQuoteList =
  (init: boolean, newPage: number): AppThunk =>
  async (dispatch, getState) => {
    const { siteSearchTerm, descriptionSearchTerm, statusSearchTerm } = getState().quoteList;
    const config: PagingConfiguration = {
      take: TAKE,
      skip: (newPage - 1) * TAKE,
      siteSearchTerm,
      descriptionSearchTerm,
      statusSearchTerm
    };

    dispatch(setLoading(true));

    const response = await axios.post<PagingResult>(`quote/search`, config);

    if (response && response.data) {
      const { items, totalNumberOfRecords } = response.data;
      dispatch(
        setQuoteList({
          currentPage: newPage,
          quotes: items,
          totalNumberOfQuotes: totalNumberOfRecords
        })
      );
    }

    dispatch(setLoading(false));
  };

export const getQuoteBidders =
  (quoteId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    const response = await axios.get<BidderExpanded[]>(`bidder/quoteId/${quoteId}`);

    if (response && response.data) {
      dispatch(
        setQuoteBidder({
          quoteId,
          bidders: response.data
        })
      );
    }

    dispatch(setLoading(false));
  };

export const searchQuotes =
  (site: string, description: string, status: QuoteStatusEnum): AppThunk =>
  async dispatch => {
    dispatch(setSearchTerms({ site, description, status }));
    dispatch(getQuoteList(true, 1));
  };

export const deleteQuote =
  (quoteId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));
    const response = await axios.delete(`quote/${quoteId}`);

    if (response && response.data) {
      dispatch(searchQuotes('', '', 0));
      ToastAnt('Quote deleted');
    }

    dispatch(setLoading(false));
  };
