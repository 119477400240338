import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import axios from '../../axios';
import { BidderStatusEnum, CurrencyEnum } from '../../shared/enums';
import { removeBidder, IdText, addBidder, updateBidder, getQuote } from '../Quote/quote.slice';
export const TAKE = 10;

export type Bidder = {
  id: string;
  quoteId: string;
  status: BidderStatusEnum;
  invitedDate: Date | '';
  receivedDate: Date | '';
  companyId: string | undefined;
  companyName: string | undefined;
  address: string;
  contactPerson: string;
  email: string;
  phone: string;
};

export interface BidderExpanded {
  id: string;
  status: string;
  companyId: string;
  companyName: string;
  address: string;
  bidderInvitedDate: Date | '';
  bidPlacedDate: Date | '';
  quoteId: string;
  bidId: string;
  contactPersonId: string;
  contactPerson: string;
  email: string;
  phone: string;
  totalRepairCost: number;
  totalFixedCost: number;
  totalCost: number;
  currency: CurrencyEnum;
}

type State = {
  bidder: Bidder;
  isLoading: boolean;
  bidderModalVisible: boolean;
  companies: Array<IdText>;
};

const initialBidder: Bidder = {
  id: '',
  quoteId: '',
  status: BidderStatusEnum.InviteNotSent,
  invitedDate: '',
  receivedDate: '',
  companyId: '',
  companyName: '',
  address: '',
  contactPerson: '',
  email: '',
  phone: ''
};

const initialState: State = {
  bidder: initialBidder,
  companies: [],
  isLoading: true,
  bidderModalVisible: false
};

const quoteListSlice = createSlice({
  name: 'bidder',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    showBidderModal(state, action: PayloadAction<boolean>) {
      state.bidderModalVisible = action.payload;
    },
    setBidder(state, action: PayloadAction<Bidder | undefined>) {
      if (action.payload) {
        state.bidder = action.payload;
      } else {
        state.bidder = initialBidder;
      }
    },
    setCompanies(state, action: PayloadAction<Array<IdText>>) {
      state.companies = action.payload;
    }
  }
});

export const { setLoading, setBidder, showBidderModal, setCompanies } = quoteListSlice.actions;

export default quoteListSlice.reducer;

export const getBidder =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    const response = await axios.get(`bidder/${id}`);
    if (id) {
      dispatch(setBidder(response.data));
    } else {
      dispatch(setBidder(initialBidder));
    }

    dispatch(setLoading(false));
  };

export const deleteBidder =
  (bidderId: string): AppThunk =>
  async dispatch => {
    const response = await axios.delete(`bidder/${bidderId}`);
    dispatch(removeBidder(bidderId));
  };

export const saveBidder =
  (bidder: Bidder): AppThunk =>
  async dispatch => {
    dispatch(setBidder(bidder));
    if (bidder.id) {
      dispatch(updateBidder(bidder));
    } else {
      dispatch(addBidder(bidder));
    }

    const bidderRequest = {
      id: bidder.id,
      companyId: bidder.companyId,
      companyName: bidder.companyName,
      address: bidder.address,
      quoteId: bidder.quoteId,
      contactPersonName: bidder.contactPerson,
      eMail: bidder.email,
      phone: bidder.phone
    };

    if (bidderRequest.id) {
      await axios.put('bidder', bidderRequest);
    } else {
      await axios.post('bidder', bidderRequest);
    }
    dispatch(getQuote(bidderRequest.quoteId));
  };

export const getCompanies = (): AppThunk => async dispatch => {
  const response = await axios.get(`company`);
  dispatch(setCompanies(response.data));
};

export const openBidderForm =
  (visible: boolean): AppThunk =>
  async dispatch => {
    if (visible) {
      dispatch(getCompanies());
    }
    dispatch(showBidderModal(visible));
  };
