import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import HeaderInfo from './HeaderInfo';
import { connect } from 'react-redux';
import styles from './Header.module.scss';
import './Header.scss';
import useAuth from '../Auth/UseAuth';

const Header: FC<RouteComponentProps> = ({ history, location }) => {
  const [firstName, lastName, apps, logoUrl] = useAuth();

  const goToHome = () => {
    if (location.pathname.includes('/budget')) {
      history.push('/budget');
    } else if (location.pathname.includes('/repair')) {
      history.push('/repair');
    } else {
      history.push('/');
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLogoContainer} onClick={goToHome}>
        <div>
          <img src={logoUrl} alt='logo' />
        </div>
      </div>
      <div className={styles.clippedPart} />
      <div className={styles.headerInfoContainer}>
        <HeaderInfo />
      </div>
    </div>
  );
};

export default connect()(withRouter(Header));
