import { AppThunk } from "../../store";
import axios from '../../axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export const TAKE = 10;

type PagingConfiguration = {
  take: number;
  skip: number;
};

type PagingResult = {
  totalNumberOfRecords?: number;
  items: BudgetDetail[];
};

export interface BudgetDetail {
  quoteId: string;
  bidderId: string;
  siteId: string | undefined;
  siteName: string | undefined;
  description: string;
  totalFixedCost: number;
  totalRepairCost: number;
  total: number;
  plannedWorkStart: Date | '';
}
type State = {
  budgetList: BudgetDetail[];
  currentPage: number;
  totalNumberOfBudgetItems: number;
  isLoading: boolean;
};

const initialState: State = {
  budgetList: [],
  currentPage: 0,
  totalNumberOfBudgetItems: 0,
  isLoading: true,
};

const budgetListSlice = createSlice({
  name: 'budgetList',
  initialState,
  reducers: {
    setBudgetList(state, action: PayloadAction<{ budgetList: BudgetDetail[]; currentPage: number; totalNumberOfBudgetItems?: number }>) {
      const { budgetList, currentPage, totalNumberOfBudgetItems } = action.payload;
      state.budgetList = budgetList;
      state.currentPage = currentPage;
      if (totalNumberOfBudgetItems) {
        state.totalNumberOfBudgetItems = totalNumberOfBudgetItems;
      }
    },
    setLoading(state, action: PayloadAction<boolean>){
      state.isLoading = action.payload;
    }
  }
});

export const { setBudgetList, setLoading } = budgetListSlice.actions;

export default budgetListSlice.reducer;

export const getBudgetList = (init: boolean, newPage: number): AppThunk => async (dispatch, getState) => {
  const config: PagingConfiguration = {
    take: TAKE,
    skip: (newPage - 1) * TAKE,
  };

  dispatch(setLoading(true));

  const response = await axios.post<PagingResult>(`budget/search`, config);

  if (response && response.data) {
    const { items, totalNumberOfRecords } = response.data;
    dispatch(
      setBudgetList({
        currentPage: newPage,
        budgetList: items,
        totalNumberOfBudgetItems: totalNumberOfRecords
      })
    );
  }

  dispatch(setLoading(false));
};
