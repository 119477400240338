import React from 'react';
import EditableCell from '../Bid/EditableCell';
import { BidData, Bid } from '../Bid/bid.slice';
import SpanNumberFormat from '../Shared/Span/SpanNumberFormat';
import { QuoteDetail } from '../QuoteList/quoteList.slice';
import btnStyles from '../Shared/Button/Button.module.scss';
import { Tooltip } from 'antd';
import Store from '../../store';
import moment from 'moment';

type ColumnProps = {
  title: string;
  dataIndex?: string;
  key: string;
  render?: (data: any, bidDataChanged: (record: any) => void) => JSX.Element;
  width?: string;
  editable?: boolean;
};

const DEFAULT_COLUMN_WIDTH = '150px';

const ColumnWidths = new Map<string, string>([
  ['status', '150px'],
  ['siteName', '150px'],
  ['companyName', '250px'],
  ['description', '250px'],
  ['action', '80px'],
  ['contactPerson', '80px']
]);

const getColumnWidth = (column: string) => {
  if (ColumnWidths.has(column)) {
    return ColumnWidths.get(column)!;
  }

  return DEFAULT_COLUMN_WIDTH;
};

const getCommonColumnProperties = (
  key: string,
  title: string,
  render?: ((record: string) => JSX.Element) | undefined,
  bidDataChanged?: (record: BidData) => void,
  addDataIndex = true,
  align?: string | undefined
) => ({
  title,
  dataIndex: addDataIndex ? key : undefined,
  key: key,
  width: getColumnWidth(key),
  render,
  bidDataChanged,
  align
});

type Column = {
  key: string;
  title: string;
  addDataIndex: boolean;
  render?: ((record: any) => JSX.Element) | undefined;
  bidDataChanged?: (record: any) => void;
  align?: string | undefined;
};

const bidDataColumns: Column[] = [
  {
    key: 'accessType',
    title: 'Access type',
    addDataIndex: true
  },
  {
    key: 'findingType',
    title: 'Finding type',
    addDataIndex: true
  },
  {
    key: 'cases',
    title: 'Cases',
    addDataIndex: true,
    align: 'center',
    render: record => <span className='alignRight'>{record}</span>
  },
  {
    key: 'scopeOfWorkReference',
    title: 'Scope of work reference',
    addDataIndex: true,
    align: 'center'
  },
  {
    key: 'numberOfWTG',
    title: 'Number of WTG',
    addDataIndex: true,
    align: 'center',
    render: record => <span className='alignRight'>{record}</span>
  },
  {
    key: 'numberOfBlades',
    title: 'Number of blades',
    addDataIndex: true,
    align: 'center',
    render: record => <span className='alignRight'>{record}</span>
  },
  {
    key: 'tooling',
    title: 'Tooling',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <EditableCell disabled={Store.getState().bid.hasAdminPermission} record={record} inputType={'number'} dataIndex={'tooling'} />
    )
  },
  {
    key: 'additionalCosts',
    title: 'Additional costs',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <EditableCell disabled={Store.getState().bid.hasAdminPermission} record={record} inputType={'number'} dataIndex={'additionalCosts'} />
    )
  },
  {
    key: 'totalManPower',
    title: 'Total manpower costs',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <EditableCell disabled={Store.getState().bid.hasAdminPermission} record={record} inputType={'number'} dataIndex={'totalManPower'} />
    )
  },
  {
    key: 'estimatedHours',
    title: 'Estimated hours',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <EditableCell disabled={Store.getState().bid.hasAdminPermission} record={record} inputType={'number'} dataIndex={'estimatedHours'} />
    )
  },
  {
    key: 'hours',
    title: 'Hours / finding',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <SpanNumberFormat
        className='alignRight'
        value={record.cases === 0 ? 0 : record.estimatedHours / record.cases}
        currency={undefined}
        hideZero={true}
      />
    )
  },
  {
    key: 'totalFixedCost',
    title: 'Total fixed cost',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <SpanNumberFormat
        className='alignRight'
        value={record.additionalCosts + record.tooling + record.totalManPower}
        currency={undefined}
        hideZero={true}
      />
    )
  },
  {
    key: 'comment',
    title: 'Comment',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <EditableCell disabled={Store.getState().bid.hasAdminPermission} record={record} inputType={'text'} dataIndex={'comment'} />
    )
  }
];

export const getBidDataColumns = (onRowChanged: (record: any) => void): ColumnProps[] =>
  bidDataColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, onRowChanged, x.addDataIndex, x.align));

const TenderCloseDateHasPassed = (bid: Bid) => {
  return moment().isAfter(bid.tenderCloseDate, 'day');
};

const btnClassRegular = `${btnStyles.customBtn} ${btnStyles.customBtnPrimary}`;
const btnClassDisabled = `${btnStyles.customBtn} ${btnStyles.customBtnPrimary} disabled`;

export const showRewardBidButton = (quotes: QuoteDetail[], bid: Bid, rewardBid: () => void) => {
  return TenderCloseDateHasPassed(bid) ? (
    <button className={btnClassRegular} key='reward' onClick={rewardBid} type='submit' disabled={false} title='Reward Bid' style={{ padding: '6px' }}>
      Reward bid
    </button>
  ) : (
    <Tooltip title='Tender is still open'>
      <button
        className={btnClassDisabled}
        key='reward'
        onClick={rewardBid}
        type='submit'
        disabled={true}
        title='Reward Bid'
        style={{ padding: '6px' }}
      >
        Reward bid
      </button>
    </Tooltip>
  );
};
