import React from 'react';
import BudgetTableAction from './BudgetTableAction';
import { getShortFormattedDate } from '../../shared/utils';
import SpanNumberFormat from '../Shared/Span/SpanNumberFormat';

type ColumnProps = {
  title: string;
  dataIndex?: string;
  key: string;
  render?: (annotation: any) => JSX.Element;
  width?: string;
  editable?: boolean;
  align?: string | undefined;
};

const DEFAULT_COLUMN_WIDTH = '150px';

const ColumnWidths = new Map<string, string>([
  ['status', '200px'],
  ['siteName', '150px'],
  ['companyName', '250px'],
  ['description', '150px'],
  ['bidderInvitedDate', '250px'],
  ['bidPlacedDate', '250px'],
  ['action', '80px'],
  ['contactPerson', '80px']
]);

const getColumnWidth = (column: string) => {
  if (ColumnWidths.has(column)) {
    return ColumnWidths.get(column)!;
  }

  return DEFAULT_COLUMN_WIDTH;
};

const getCommonColumnProperties = (
  key: string,
  title: string,
  render?: ((record: string) => JSX.Element) | undefined,
  addDataIndex = true,
  align?: string | undefined,
  editable?: boolean
) => ({
  title,
  dataIndex: addDataIndex ? key : undefined,
  key: key,
  width: getColumnWidth(key),
  render,
  editable,
  align
});

type Column = {
  key: string;
  title: string;
  addDataIndex: boolean;
  render?: ((record: any) => JSX.Element) | undefined;
  editable?: boolean;
  align?: string | undefined;
};

const budgetListColumns: Column[] = [
  {
    key: 'actions',
    title: 'Actions',
    addDataIndex: false,
    render: (record: any): JSX.Element => <BudgetTableAction record={record} />
  },
  {
    key: 'siteName',
    title: 'Site',
    addDataIndex: true
  },
  {
    key: 'description',
    title: 'Description',
    addDataIndex: true
  },
  {
    key: 'totalFixedCost',
    title: 'Total Fixed Cost',
    addDataIndex: true,
    align: 'center',
    render: (record: any): JSX.Element => <SpanNumberFormat className='alignRight' value={record} currency={record.currency} hideZero={false} />
  },
  {
    key: 'totalRepairCost',
    title: 'Total Repair Cost',
    addDataIndex: true,
    align: 'center',
    render: (record: any): JSX.Element => <SpanNumberFormat className='alignRight' value={record} currency={record.currency} hideZero={false} />
  },
  {
    key: 'total',
    title: 'Total Cost',
    addDataIndex: true,
    align: 'center',
    render: (record: any): JSX.Element => <SpanNumberFormat className='alignRight' value={record} currency={record.currency} hideZero={false} />
  },
  {
    key: 'plannedWorkStart',
    title: 'Planned Work Start',
    addDataIndex: true,
    align: 'center',
    render: (record: Date): JSX.Element => <span style={{ textAlign: 'center' }}>{getShortFormattedDate(record)}</span>
  }
];

export const getBudgetListColumns = (): ColumnProps[] =>
  budgetListColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex, x.align));
