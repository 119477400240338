import React, { FC, useEffect, useState, useCallback } from 'react';
import { Layout, PageHeader, Table, Divider } from 'antd';
import Button from '../Shared/Button/Button';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import {
  QuoteData,
  saveQuoteData,
  getQuoteDataFindings,
  showAddDataModal,
  getQuoteDataList,
  deleteFinding,
  deleteAllFindings
} from './quoteData.slice';
import { AppDispatch } from '../../store';
import AddDataForm from './AddData/AddDataForm';
import { QuoteStatusEnum, PermissionEnum } from '../../shared/enums';
import { Quote } from '../Quote/quote.slice';
import { AuthService } from '../Auth/AuthService';
import ConfirmDialog from '../Shared/ConfirmDialog/ConfirmDialog';
import * as Utils from 'hermes-tiles-grid-popup';

interface Props {
  quoteDataList: Array<QuoteData>;
  saveQuoteData: (quoteId: string) => void;
  getQuoteDataList: (quoteId: string) => void;
  getQuoteDataFindings: (quoteId: string, quoteDataId: string) => void;
  isLoading: boolean;
  addDataModalVisible: boolean;
  showAddDataModal: (visible: boolean) => void;
  deleteFinding: (findingId: string, quoteDataId: string, quoteId: string) => void;
  deleteAllFindings: (findingId: string, quoteId: string) => void;
  quote: Quote;
}
const QuoteDataForm: FC<Props & RouteComponentProps<{ id: string }>> = ({
  match,
  history,
  quoteDataList,
  isLoading,
  getQuoteDataFindings,
  addDataModalVisible,
  showAddDataModal,
  getQuoteDataList,
  deleteFinding,
  deleteAllFindings,
  quote
}) => {
  const [quoteId, setQuoteId] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    setQuoteId(match.params.id);
    getQuoteDataList(match.params.id);
  }, [match]);

  const onBack = () => {
    history.push(`/quotes/${quoteId}`);
  };

  const onViewFinding = (findingId: string) => {
    const portalUrl = Utils.replaceTenantNamePlaceHolder(process.env.REACT_APP_PORTAL!);
    const newWindow = window.open(`${portalUrl}/finding-preview/${findingId}`, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const onDeleteFinding = (findingId: string, quoteDataId: string) => {
    ConfirmDialog(() => deleteFinding(findingId, quoteDataId, quoteId), 'Are you sure you want to delete finding?');
  };

  const onDeleteAllFindings = (quoteDataId: string) => {
    ConfirmDialog(() => deleteAllFindings(quoteDataId, quoteId), 'Are you sure you want to delete finding type?');
  };

  const onAddData = () => {
    showAddDataModal(true);
  };

  const findingColumns = [
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <>
          <span>
            <a
              onClick={e => {
                e.stopPropagation();
                onViewFinding(record.id);
              }}
            >
              View
            </a>
          </span>
          {quote.statusEnum === QuoteStatusEnum.InProgress && (
            <>
              <Divider type='vertical' />
              <span>
                <a onClick={() => onDeleteFinding(record.id, record.quoteDataId)}>Delete</a>
              </span>
            </>
          )}
        </>
      )
    },
    {
      title: 'Inspection Id',
      dataIndex: 'inspectionId',
      key: 'inspectionId'
    },
    {
      title: 'Response Level',
      dataIndex: 'defectMaintenanceTriggerCode',
      key: 'defectMaintenanceTriggerCode'
    },
    {
      title: 'Turbine Name',
      dataIndex: 'turbineName',
      key: 'turbineName'
    },
    {
      title: 'Turbine Serial No',
      dataIndex: 'turbineSerial',
      key: 'turbineSerial'
    },
    {
      title: 'Blade Position',
      dataIndex: 'bladePosition',
      key: 'bladePosition'
    },
    {
      title: 'Blade Serial No',
      dataIndex: 'bladeSerial',
      key: 'bladeSerial'
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity'
    },
    {
      title: 'Layer',
      dataIndex: 'layer',
      key: 'layer'
    }
  ];

  const quoteDataColumns = [
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <span>
          {quote.statusEnum === QuoteStatusEnum.InProgress && (
            <a
              onClick={e => {
                e.stopPropagation();
                onDeleteAllFindings(record.id);
              }}
            >
              Delete
            </a>
          )}
        </span>
      )
    },
    {
      title: 'Finding Type',
      dataIndex: 'findingType',
      key: 'findingType'
    },
    {
      title: 'Cases',
      dataIndex: 'cases',
      key: 'cases'
    },
    {
      title: 'Number of WTG',
      dataIndex: 'numberOfWTG',
      key: 'numberOfWTG'
    },
    {
      title: 'Number of Blades',
      dataIndex: 'numberOfBlades',
      key: 'numberOfBlades'
    }
  ];

  const onExpand = (expanded: boolean, record: QuoteData) => {
    if (!expanded) {
      setExpandedKeys([...expandedKeys.filter(x => x !== record.quoteId)]);
      return;
    }

    getQuoteDataFindings(quoteId, record.id);

    setExpandedKeys([...expandedKeys, record.quoteId]);
  };

  const { Content } = Layout;

  const getTable = (record: QuoteData) => {
    return (
      <Table
        columns={findingColumns}
        dataSource={record.quoteDataFindings}
        rowKey='id'
        pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
      />
    );
  };

  return (
    <>
      {addDataModalVisible ? <AddDataForm quoteId={quoteId} /> : ''}
      <Layout>
        <Content>
          <PageHeader
            className='QBRHeader'
            onBack={onBack}
            title='Findings'
            extra={[
              <>
                {quote.statusEnum === QuoteStatusEnum.InProgress && (
                  <Button title='Add Findings' type='button' key='addData' onClick={onAddData}></Button>
                )}
              </>
            ]}
          />
          <Table
            style={{ minHeight: '320px', height: '100%' }}
            columns={quoteDataColumns}
            dataSource={quoteDataList}
            rowKey='id'
            loading={isLoading}
            onExpand={onExpand}
            expandedRowRender={record => getTable(record)}
            expandRowByClick={true}
            pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
          />
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  quoteDataList: state.quoteData.quoteDataList,
  isLoading: state.quoteData.loading,
  addDataModalVisible: state.quoteData.addDataModalVisible,
  quote: state.quote.quote
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  saveQuoteData: (quoteId: string) => dispatch(saveQuoteData(quoteId)),
  getQuoteDataFindings: (quoteId: string, quoteDataId: string) => dispatch(getQuoteDataFindings(quoteId, quoteDataId)),
  showAddDataModal: (visible: boolean) => dispatch(showAddDataModal(visible)),
  getQuoteDataList: (quoteId: string) => dispatch(getQuoteDataList(quoteId)),
  deleteFinding: (findingId: string, quoteDataId: string, quoteId: string) => dispatch(deleteFinding(findingId, quoteDataId, quoteId)),
  deleteAllFindings: (quoteDataId: string, quoteId: string) => dispatch(deleteAllFindings(quoteDataId, quoteId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteDataForm));
