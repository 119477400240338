import React, { useState, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AuthService } from './AuthService';

const Login: React.SFC<RouteComponentProps> = ({ location }) => {
  const [error, setError] = useState(false);
  const [returnUrl, setReturnUrl] = useState({});

  useEffect(() => {
    if (location.state && location.state.hasOwnProperty('from')) {
      const returnUrl: string = (location.state as any).from;
      setReturnUrl(returnUrl);
    }

    try {
      AuthService.login({
        state: returnUrl
      });
    } catch (err) {
      setError(true);
    }
  }, [location.state, returnUrl]);

  if (error) {
    return <Redirect to="/identity-error" />;
  }

  return <h1>Redirecting to identity provider</h1>;
};

export default Login;
