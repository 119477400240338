import React, { FC, useState, useEffect, useCallback } from 'react';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Select, Table, Input, PageHeader, Layout } from 'antd';
import styles from './BudgetDetail.module.scss';
import { BudgetDetail, BidData, getBudgetDetail, getQuoteDataFindings } from './budgetDetail.slice';
import { CurrencyEnum, PermissionEnum } from '../../shared/enums';
import { RouteComponentProps, withRouter } from 'react-router';
import { BidderStatusesToDescription } from '../../shared/enums';
import { getFormattedDate } from '../../shared/utils';
import NumberFormat from 'react-number-format';
import { AuthService } from '../Auth/AuthService';
import { getBidDataColumns } from './BidDataHelper';

type Props = {
  loading: boolean;
  budgetDetail: BudgetDetail;
  bidData: BidData[];
  onGetBudgetDetail: (id: string) => void;
  getQuoteDataFindings: (quoteId: string, quoteDataId: string) => void;
};

const BidForm: FC<Props & RouteComponentProps<{ id: string }>> = ({
  budgetDetail,
  bidData,
  history,
  match,
  onGetBudgetDetail,
  getQuoteDataFindings
}) => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [vesselType, setVesselType] = useState('');
  const [expProjectDuration, setExpectedProjectDuration] = useState(0);
  const [numberOfTeam, setNumberOfTeam] = useState(0);
  const [teamSize, setTeamSize] = useState(0);
  const [currency, setCurrency] = useState(CurrencyEnum.Euro);
  const [vesselCost, setVesselCost] = useState(0);
  const [mobDemobCost, setMobDemobCost] = useState(0);
  const [trainingCost, setTrainingCost] = useState(0);
  const [weatherRiskCost, setWeatherRiskCost] = useState(0);
  const [additionalCost, setAdditionalCost] = useState(0);
  const [totalRepairCost, setTotalRepairCost] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    let totalRepairCost = 0;
    bidData.forEach(element => {
      totalRepairCost += element.tooling + element.additionalCosts + element.totalManPower;
    });
    setTotalRepairCost(totalRepairCost);
  }, [bidData]);

  useEffect(() => {
    const { id } = match.params;
    onGetBudgetDetail(id);
  }, [match]);

  useEffect(() => {
    if (budgetDetail) {
      setId(budgetDetail.id!);
      form.setFieldsValue({
        invitedDate: budgetDetail.bidderInvitedDate,
        bidPlacedDate: budgetDetail.bidPlacedDate,
        rewardedDate: budgetDetail.rewardedDate,
        vesselType: budgetDetail.vesselType,
        expectedProjectDuration: budgetDetail.expectedProjectDuration,
        numberOfTeamAssigned: budgetDetail.numberOfTeamAssigned,
        teamSize: budgetDetail.teamSize,
        currency: budgetDetail.currency,
        vesselCost: budgetDetail.vesselCost,
        mobDemobCost: budgetDetail.mobDemobCost,
        additionalCost: budgetDetail.additionalCost,
        trainingCost: budgetDetail.trainingCost,
        weatherRiskCost: budgetDetail.weatherRiskCost,

        totalFixedCost:
          budgetDetail.vesselCost +
          budgetDetail.mobDemobCost +
          budgetDetail.trainingCost +
          budgetDetail.weatherRiskCost +
          budgetDetail.additionalCost,
        totalRepairCost: 0
      });
    }
  }, [budgetDetail, form]);

  useEffect(() => {
    form.setFieldsValue({
      totalFixedCost:
        budgetDetail.vesselCost + budgetDetail.mobDemobCost + budgetDetail.trainingCost + budgetDetail.weatherRiskCost + budgetDetail.additionalCost
    });
  });

  const getTotalFixedCost = () => {
    return vesselCost + mobDemobCost + trainingCost + weatherRiskCost + additionalCost;
  };

  const getBidDataTableContent = (budgetDetail: BudgetDetail) => {
    if (!budgetDetail.bidData || (budgetDetail.bidData && budgetDetail.bidData.length === 0)) {
      return {
        isLoading: false,
        columns: [],
        dataSource: []
      };
    }

    const dataSource = bidData.map(x => ({
      ...x,
      key: x.id
    }));

    let columns = getBidDataColumns(onRowChanged);

    return {
      isLoading: false,
      columns,
      dataSource
    };
  };

  const onRowChanged = (record: any) => {};

  const bidDataGrid = getBidDataTableContent(budgetDetail);

  const currencies = Object.keys(CurrencyEnum).filter(key => !isNaN(Number(CurrencyEnum[key])));

  const onBack = () => {
    history.push('/budget');
  };

  const onExpand = (expanded: boolean, record: BidData) => {
    if (!expanded) {
      setExpandedKeys([...expandedKeys.filter(x => x !== record.bidId)]);
      return;
    }
    getQuoteDataFindings(budgetDetail.quoteId, record.quoteDataId);
    setExpandedKeys([...expandedKeys, record.bidId]);
  };

  const findingColumns = [
    {
      title: 'Inspection Id',
      dataIndex: 'inspectionId',
      key: 'inspectionId'
    },
    {
      title: 'Response Level',
      dataIndex: 'defectMaintenanceTriggerCode',
      key: 'defectMaintenanceTriggerCode'
    },
    {
      title: 'Turbine Name',
      dataIndex: 'turbineName',
      key: 'turbineName'
    },
    {
      title: 'Turbine Serial No',
      dataIndex: 'turbineSerial',
      key: 'turbineSerial'
    },
    {
      title: 'Blade Position',
      dataIndex: 'bladePosition',
      key: 'bladePosition'
    },
    {
      title: 'Blade Serial No',
      dataIndex: 'bladeSerial',
      key: 'bladeSerial'
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity'
    },
    {
      title: 'Layer',
      dataIndex: 'layer',
      key: 'layer'
    }
  ];

  const { Option } = Select;
  const { Content } = Layout;
  return (
    <Layout>
      <Content>
        <PageHeader className='QBRHeader' onBack={onBack} title={`Budget Item Details - ${budgetDetail.companyName}`} />
        <Form layout='horizontal' form={form}>
          <Card>
            <Row gutter={16}>
              <Col span={6} style={{ textAlign: 'end' }}>
                <Form.Item label='Status' className={styles.antFormItem}>
                  {BidderStatusesToDescription[budgetDetail.bidderStatus].name}
                </Form.Item>
                <Form.Item name='invitedDate' label='Invited' className={styles.antFormItem}>
                  {getFormattedDate(budgetDetail.bidderInvitedDate)}
                </Form.Item>
                <Form.Item name='submittedDate' label='Received' className={styles.antFormItem}>
                  {getFormattedDate(budgetDetail.bidPlacedDate)}
                </Form.Item>
                <Form.Item name='rewardedDate' label='Rewarded' className={styles.antFormItem}>
                  {getFormattedDate(budgetDetail.rewardedDate)}
                </Form.Item>
                <Form.Item name={'vesselType'} label='Vessel type' className={styles.antFormItem}>
                  <Input
                    disabled={true}
                    style={{ background: 'PapayaWhip', width: '150px' }}
                    className={styles.input}
                    placeholder='Enter vessel type'
                    value={vesselType}
                    onChange={e => {
                      setVesselType(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item name='expectedProjectDuration' label='Expected project duration' className={styles.antFormItem}>
                  <Input
                    disabled={true}
                    style={{ background: 'PapayaWhip', width: '150px' }}
                    className={styles.input}
                    placeholder='Enter expected project duration'
                    value={expProjectDuration}
                    onChange={e => {
                      setExpectedProjectDuration(parseInt(e.target.value));
                    }}
                  />
                </Form.Item>
                <Form.Item name='numberOfTeamAssigned' label='Number of team assigned' className={styles.antFormItem}>
                  <Input
                    disabled={true}
                    style={{ background: 'PapayaWhip', width: '150px' }}
                    className={styles.input}
                    placeholder='Enter number of team assigned'
                    value={numberOfTeam}
                    onChange={e => {
                      setNumberOfTeam(parseInt(e.target.value));
                    }}
                  />
                </Form.Item>
                <Form.Item name='teamSize' label='Team size' className={styles.antFormItem}>
                  <Input
                    disabled={true}
                    style={{ background: 'PapayaWhip', width: '150px' }}
                    className={styles.input}
                    placeholder='Enter team size'
                    value={teamSize}
                    onChange={e => {
                      setTeamSize(parseInt(e.target.value));
                    }}
                  />
                </Form.Item>
                <Form.Item name='currency' label='Currency' className={styles.antFormItem}>
                  <Select
                    disabled={true}
                    style={{ background: 'PapayaWhip', width: '150px' }}
                    className={styles.input}
                    placeholder='Enter currency'
                    value={currency}
                    onChange={e => {
                      setCurrency(e);
                    }}
                  >
                    {currencies.map(t => (
                      <Option key={t} value={t}>
                        {t}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: 'end' }}>
                <Card title='Fixed cost' className='bid-details'>
                  <Form.Item name='vesselCost' label='Vessel cost' className={styles.antFormItem}>
                    <NumberFormat
                      disabled={true}
                      className={styles.numberFormat}
                      style={{ background: 'PapayaWhip', width: '150px' }}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder='Enter vessel cost'
                      value={vesselCost}
                      onValueChange={(value: any) => {
                        setVesselCost(value.floatValue);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='mobDemobCost' label='Mob / demob cost' className={styles.antFormItem}>
                    <NumberFormat
                      disabled={true}
                      style={{ background: 'PapayaWhip', width: '150px' }}
                      className={styles.numberFormat}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder='Enter mob / demob cost'
                      value={mobDemobCost}
                      onValueChange={(value: any) => {
                        setMobDemobCost(value.floatValue);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='trainingCost' label='Training cost' className={styles.antFormItem}>
                    <NumberFormat
                      disabled={true}
                      style={{ background: 'PapayaWhip', width: '150px' }}
                      className={styles.numberFormat}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder='Enter training cost'
                      value={trainingCost}
                      onValueChange={(value: any) => {
                        setTrainingCost(value.floatValue);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='weatherRiskCost' label='Total weather risk cost' className={styles.antFormItem}>
                    <NumberFormat
                      disabled={true}
                      style={{ background: 'PapayaWhip', width: '150px' }}
                      className={styles.numberFormat}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder='Enter total weather risk cost'
                      value={weatherRiskCost}
                      onValueChange={(value: any) => {
                        setWeatherRiskCost(value.floatValue);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='additionalCost' label='Additional cost' className={styles.antFormItem}>
                    <NumberFormat
                      disabled={true}
                      style={{ background: 'PapayaWhip', width: '150px' }}
                      className={styles.numberFormat}
                      thousandSeparator='.'
                      decimalSeparator=','
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder='Enter additional cost'
                      value={additionalCost}
                      onValueChange={(value: any) => {
                        setAdditionalCost(value.floatValue);
                      }}
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={6} style={{ textAlign: 'end' }}>
                <Card title='Summary' className='bid-details'>
                  <Col>
                    <Form.Item label='Total number of WTGs' className={styles.antFormItem}>
                      <Input
                        disabled={true}
                        className={styles.input}
                        style={{ background: 'White', color: 'black', width: '150px' }}
                        placeholder='Total number of WTGs'
                        value={budgetDetail.totalNumberOfWTG}
                      />
                    </Form.Item>
                    <Form.Item label='Total number of blades' className={styles.antFormItem}>
                      <Input
                        disabled={true}
                        className={styles.input}
                        style={{ background: 'White', color: 'black', width: '150px' }}
                        placeholder='Total number of blades'
                        value={budgetDetail.totalNumberOfBlades}
                      />
                    </Form.Item>
                    <Form.Item label='Total number of cases' className={styles.antFormItem}>
                      <Input
                        disabled={true}
                        className={styles.input}
                        style={{ background: 'White', color: 'black', width: '150px' }}
                        placeholder='Total number of cases'
                        value={budgetDetail.totalCases}
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>
              <Col span={6} style={{ textAlign: 'end' }}>
                <Card title='Total cost' className='bid-details'>
                  <Col>
                    <Form.Item label='Total fixed cost' className={styles.antFormItem}>
                      <NumberFormat
                        disabled={true}
                        className={styles.numberFormat}
                        style={{ background: 'AliceBlue', color: 'black', width: '150px' }}
                        thousandSeparator='.'
                        decimalSeparator=','
                        decimalScale={2}
                        fixedDecimalScale={true}
                        placeholder='Total fixed cost'
                        value={getTotalFixedCost()}
                      />
                    </Form.Item>
                    <Form.Item label='Total repair cost' className={styles.antFormItem}>
                      <NumberFormat
                        disabled={true}
                        className={styles.numberFormat}
                        style={{ background: 'AliceBlue', color: 'black', width: '150px' }}
                        thousandSeparator='.'
                        decimalSeparator=','
                        decimalScale={2}
                        fixedDecimalScale={true}
                        placeholder='Total repair cost'
                        value={totalRepairCost}
                      />
                    </Form.Item>
                    <Form.Item label='Total cost' className={styles.antFormItem}>
                      <NumberFormat
                        className={styles.numberFormat}
                        disabled={true}
                        style={{ background: 'AliceBlue', color: 'black', width: '150px' }}
                        thousandSeparator='.'
                        decimalSeparator=','
                        decimalScale={2}
                        fixedDecimalScale={true}
                        placeholder='Total cost'
                        value={getTotalFixedCost() + totalRepairCost}
                      />
                    </Form.Item>
                  </Col>
                </Card>
              </Col>
            </Row>
          </Card>
          <Row style={{ width: '100%' }}>
            <Col style={{ width: '100%' }}>
              <Card title='Bid data'>
                <Table
                  size='small'
                  // pagination={false}
                  className='bidder-table'
                  scroll={{ x: 1 }}
                  columns={bidDataGrid.columns}
                  dataSource={bidDataGrid.dataSource}
                  rowKey='id'
                  expandedRowRender={(record: BidData) => (
                    <Table
                      className='inner-table-pagination'
                      columns={findingColumns}
                      dataSource={record.findings}
                      pagination={{ showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                      rowKey='id'
                    />
                  )}
                  onExpand={onExpand}
                  pagination={{
                    //current: currentPage,
                    //total: totalNumber,
                    //pageSize: TAKE,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    onChange: (page: number) => {
                      //getQuoteList(page);
                    }
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  budgetDetail: state.budgetDetail.budgetDetail,
  bidData: state.budgetDetail.bidData,
  loading: state.quoteList.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onGetBudgetDetail: (id: string) => dispatch(getBudgetDetail(id)),
  getQuoteDataFindings: (quoteId: string, quoteDataId: string) => dispatch(getQuoteDataFindings(quoteId, quoteDataId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BidForm));
