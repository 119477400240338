import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefectLayerEnum, SeverityEnum, BladeSideEnum, BladePositionEnum, DefectTypeEnum } from '../../shared/enums';
import { AppThunk } from '../../store';
import axios from '../../axios';
import { IdText, getQuote } from '../Quote/quote.slice';
import { ToastAnt } from '../Toast/ToastAnt';

export interface QuoteData {
  id: string;
  quoteId: string;
  findingType: string | '';
  cases: number;
  numberOfWTG: number;
  numberOfBlades: number;
  quoteDataFindings: Array<QuoteDataFinding>;
}

export interface AddDataFilter {
  quoteId: string;
  inspectionId: string;
  turbineName: string;
  bladeName: string;
  bladeSide: string;
  findingType: string;
  severity: SeverityEnum;
  layer: DefectLayerEnum;
}

export interface QuoteDataFinding {
  id: string;
  selected: boolean;
  quoteDataId: string;
  turbineId: string;
  turbineName: string;
  turbineSerial: string;
  bladeId: string;
  bladePosition: BladePositionEnum;
  bladeSerial: string;
  findingType: DefectTypeEnum;
  bladeSide: BladeSideEnum;
  severity: SeverityEnum;
  layer: DefectLayerEnum;
  length: number;
  height: number;
  area: number;
  distanceToRoot: number;
  distanceToEdge: number;
  comments: string;
}

interface State {
  blades: Array<IdText>;
  turbines: Array<IdText>;
  inspections: Array<IdText>;
  quoteDataList: Array<QuoteData>;
  loading: boolean;
  addDataModalVisible: boolean;
  quoteId: string;
  selectedFindings: Array<QuoteDataFinding>;
  showFindingsButton: boolean;
}

const initialState: State = {
  blades: [],
  turbines: [],
  inspections: [],
  quoteDataList: [],
  loading: false,
  addDataModalVisible: false,
  quoteId: '',
  selectedFindings: [],
  showFindingsButton: false
};

const quoteDataSlice = createSlice({
  name: 'quoteData',
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setQuoteDataList(state, action: PayloadAction<Array<QuoteData>>) {
      state.quoteDataList = action.payload;
    },
    setQuoteData(state, action: PayloadAction<{ quoteDataId: string; data: QuoteData }>) {
      const index = state.quoteDataList.findIndex(x => x.id === action.payload.quoteDataId);
      if (index >= 0) {
        state.quoteDataList[index] = action.payload.data;
      }
    },
    setQuoteDataFindings(state, action: PayloadAction<{ quoteDataId: string; data: Array<QuoteDataFinding> }>) {
      const index = state.quoteDataList.findIndex(x => x.id === action.payload.quoteDataId);
      if (index >= 0) {
        state.quoteDataList[index].quoteDataFindings = action.payload.data;
      }
    },
    showAddDataModal(state, action: PayloadAction<boolean>) {
      state.addDataModalVisible = action.payload;
    },
    setInspections(state, action: PayloadAction<Array<IdText>>) {
      state.inspections = action.payload;
    },
    setTurbines(state, action: PayloadAction<Array<IdText>>) {
      state.turbines = action.payload;
    },
    setBlades(state, action: PayloadAction<Array<IdText>>) {
      state.blades = action.payload;
    },
    setShowFindingsButton(state, action: PayloadAction<boolean>) {
      state.showFindingsButton = action.payload;
    }
  }
});

export const {
  setLoading,
  setQuoteDataList,
  setQuoteData,
  setQuoteDataFindings,
  showAddDataModal,
  setInspections,
  setTurbines,
  setBlades,
  setShowFindingsButton
} = quoteDataSlice.actions;

export default quoteDataSlice.reducer;

export const saveQuoteData =
  (quoteId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const findingIds = getState().quoteData.quoteDataList.flatMap(x => x.quoteDataFindings);

    await axios.post(`quote/${quoteId}/data/`, {
      findingIds: findingIds
    });
    dispatch(setLoading(false));
  };

export const getQuoteDataList =
  (quoteId: string): AppThunk =>
  async dispatch => {
    dispatch(setShowFindingsButton(false));

    if (quoteId !== 'new') {
      const response = await axios.get(`quote/${quoteId}/data`);

      dispatch(setQuoteDataList(response.data));
      dispatch(getQuote(quoteId));
      if (response && response.data) {
        dispatch(setShowFindingsButton(true));
      } else {
        dispatch(setShowFindingsButton(false));
      }
    }
  };

export const getQuoteDataFindings =
  (quoteId: string, quoteDataId: string): AppThunk =>
  async dispatch => {
    const response = await axios.get(`quote/${quoteId}/data/${quoteDataId}`);
    dispatch(setQuoteDataFindings({ quoteDataId, data: response.data }));
  };

export const addDataSave =
  (filter: AddDataFilter): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    const response = await axios.post(`quoteData/addFindings/`, filter);
    if (response && response.data === 0) {
      ToastAnt(`There is no additional finding data to be added!`);
    }

    dispatch(setLoading(false));
    dispatch(getQuoteDataList(filter.quoteId));
  };

export const getInspections =
  (quoteId: string): AppThunk =>
  async dispatch => {
    const response = await axios.get(`quoteData/getInspections/${quoteId}`);
    dispatch(setInspections(response.data));
  };

export const getTurbines =
  (quoteId: string): AppThunk =>
  async dispatch => {
    const response = await axios.get(`quoteData/getTurbines/${quoteId}`);
    dispatch(setTurbines(response.data));
  };

export const getBlades =
  (turbineId: string): AppThunk =>
  async dispatch => {
    const response = await axios.get(`quoteData/getBlades/${turbineId}`);
    dispatch(setBlades(response.data));
  };

export const deleteFinding =
  (findingId: string, quoteDataId: string, quoteId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().quoteData.loading) {
        console.log('loading');
        return;
      }
      dispatch(setLoading(true));
      const response = await axios.delete(`quoteData/finding/${findingId}`);
      if (response.data) {
        dispatch(setQuoteData({ quoteDataId, data: response.data }));
      } else {
        dispatch(getQuoteDataList(quoteId));
      }
    } catch {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteAllFindings =
  (quoteDataId: string, quoteId: string): AppThunk =>
  async dispatch => {
    try {
      await axios.delete(`quoteData/findingType/${quoteDataId}`);
      dispatch(getQuoteDataList(quoteId));
    } catch {}
  };
