import React, { FC, useEffect, useRef, useState } from 'react';
import { RootState } from '../../../rootReducer';
import { connect } from 'react-redux';
import styles from './FindingInfoCard.module.scss';
import { Card, Spin, Switch } from 'antd';
import { drawFindings, getPropertyBackgroundColor } from './Helpers';
import classNames from 'classnames';
import brokenImage from '../../../assets/images/brokenImage.jpg';
import { FindingAnnotations } from '../../Bid/bid.slice';
import { QuoteDataFinding } from '../../QuoteData/quoteData.slice';
import RLA from './RLA';
import * as Utils from 'hermes-tiles-grid-popup';

type Props = {
  record: QuoteDataFinding | null;
  title: string;
  finding: FindingAnnotations | null;
  loading: boolean;
};

const FindingInfoCard: FC<Props> = ({ title, finding, loading, record }) => {
  const canvas = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const [showFinding, setShowFinding] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imageLoaded && canvas.current && image.current && showFinding && finding && finding.images[0].imageURL) {
      canvas.current.innerHTML = '';
      drawFindings(canvas.current, image.current, finding!);
    }
  }, [canvas, finding, image, imageLoaded, showFinding]);

  if (loading || !record || !finding) {
    return (
      <div style={{ width: '10px', margin: '0 auto' }}>
        <Spin />
      </div>
    );
  }

  const imageSrc = finding.images[0].imageURL !== null ? finding.images[0].imageURL : brokenImage;

  const onViewFinding = (findingId: string) => {
    const portalUrl = Utils.replaceTenantNamePlaceHolder(process.env.REACT_APP_PORTAL!);
    const newWindow = window.open(`${portalUrl}/finding-preview/${findingId}`, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <Card className='finding-card' title='FINDING INFO' style={{ margin: '1rem 0 1rem 33px', width: '850px', height: '350px' }}>
      <div className={styles.cardBody}>
        <div className={styles.cardImg} style={{ position: 'relative' }}>
          <a
            onClick={e => {
              e.stopPropagation();
              onViewFinding(record.id);
            }}
          >
            <img onLoad={() => setImageLoaded(true)} ref={image} src={imageSrc} alt='' />
            {showFinding && <div ref={canvas} id='annotations-canvas' style={{ position: 'absolute', top: 0, left: 0 }}></div>}
          </a>
        </div>
        <div className={styles.cardArrowAndInfo}>
          <div className={styles.cardInfoDamage}>
            <p>{finding.annotationData.name}</p>
            <p></p>
          </div>

          <div className={styles.cardSeverty}>
            <div style={{ display: 'flex' }}>
              <span
                className={classNames({ [styles.severity]: title === 'Severity' })}
                style={{ backgroundColor: getPropertyBackgroundColor(title, 3), color: 'black', opacity: 0.7 }}
              >
                {record.severity}
              </span>
              <span>Severity</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Switch
                disabled={finding.images[0].imageURL === null}
                className={styles.swich}
                checked={showFinding}
                onChange={checked => setShowFinding(checked)}
              />
              <p>Display finding outline</p>
            </div>
          </div>
          <RLA distanceToRoot={record.distanceToRoot} area={record.area} length={record.length} />
          <div className={styles.changeCard}>
            <h2>{finding.annotationData.serialNumber}</h2>
            <div className={styles.arrowContainer}></div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  bidData: state.bid.bidData[0].findings,
  loading: state.bid.isLoading,
  finding: state.bid.finding
});

export default connect(mapStateToProps)(FindingInfoCard);
