import { AppThunk } from '../../store';
import axios from '../../axios';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RepairType } from '../../shared/enums';
import { ToastAnt } from '../Toast/ToastAnt';
import { Moment } from 'moment';
import { RepairInfoWithAttachments } from './repairListSelector';
export const take = 10;

export enum OrderDirection {
  Ascending,
  Descending
}

interface PagingConfiguration {
  take: number;
  skip: number;
  sortProperty?: string | null;
  sortDirection?: OrderDirection | null | undefined;

  repairType?: string[] | null;
  country?: string[] | null;
  site?: string[] | null;
  inspection?: string[] | null;
  turbine?: string[] | null;
  blade?: string[] | null;
  bladeSide?: string[] | null;
  findingType?: string[] | null;
  severity?: string[] | null;
  defectId?: string | null;
  responseLevel?: string[] | null;
  repairCompany?: string[] | null;
  distanceToRootFrom?: number | null;
  distanceToRootTo?: number | null;
  startRepairDate?: string | null;
  endRepairDate?: string | null;
}

interface FiltersConfiguration {
  repairType?: string[] | null;
  country?: string[] | null;
  site?: string[] | null;
  inspection?: string[] | null;
  turbine?: string[] | null;
  blade?: string[] | null;
  bladeSide?: string[] | null;
  findingType?: string[] | null;
  severity?: string[] | null;
  defectId?: string | null;
  responseLevel?: string[] | null;
  repairCompany?: string[] | null;
}

interface PagingResult {
  totalNumberOfRecords?: number;
  items: RepairDetail[];
}

export interface RepairDetail {
  bladeId: string;
  bladePosition: string;
  bladeSide: string;
  comment: string;
  findingDate: Date;
  inspection: string;
  findingId: string;
  findingType: string;
  repairDate: string;
  workOrderNumber: number;
  repairInfoId: string;
  defectMaintenanceTriggerCode: string;
  severity: string;
  siteId: string;
  siteName: string;
  repairType: string;
  turbineId: string;
  turbineName: string;
  attachments: RepairInfoAttachments[];
  findingDateCreated: Date;
}

export interface RepairDetailInfo {
  fileName: string;
  uploadDate: Date | undefined;
  label: string;
}
export interface RepairInfo {
  companyId: string | undefined;
  comment: string;
  repairType: RepairType;
  repairDataFindingIds: string[];
  repairDate: Moment | null;
  workOrderNumber?: number;
  attachments: CreateAttachment[];
}

export type CreateAttachment = {
  fileName: string;
  label: string;
  show: boolean;
};
export interface RepairInfoAttachments {
  id: string;
  repairInfoId: string;
  fileName: string;
  label: string;
  show: boolean;
  url?: string;
  isUploaded: boolean;
  isUploading: boolean;
  errorOccurred: boolean;
  key?: string;
  uploadDate: string;
  canBeDeleted: boolean;
}

export type AttachmentInfo = {
  id: string;
  repairInfoId: string;
  fileName: string;
};

export type AttachmentForUpload = {
  id: string;
  repairInfoId: string;
  fileName: string;
  file: File;
  label: string;
  show: boolean;
};

export type NewAttachment = {
  id: string;
  repairInfoId: string;
  fileName: string;
  isUploading: boolean;
  errorOccurred: boolean;
  uploadDate?: string;
  url?: string;
};

export type RepairInfoCreated = {
  id: string;
  attachments: RepairInfoAttachmentCreated[];
  repairType: RepairType;
  repairDataFindingIds: string[];
  repairDate: Moment | null;
  workOrderNumber: number;
  repairAttachments: CreateAttachment[];
  comment: string;
};

export type CreatedRepairInfoForUpload = {
  id: string;
  attachments: RepairInfoAttachmentCreated[];
};

export type RepairInfoAttachmentCreated = {
  id: string;
  fileName: string;
  label: string;
  show: boolean;
};

export type Filter = {
  repairType?: string[];
  lastChangedField?: string | undefined;
  country?: string[];
  site?: string[];
  sortProperty?: string;
  sortDirection?: OrderDirection;
  inspection?: string[];
  turbine?: string[];
  blade?: string[];
  bladeSide?: string[];
  findingType?: string[];
  severity?: string[];
  responseLevel?: string[];
  repairCompany?: string[];
  defectId?: string;
  distanceToRootFrom?: number;
  distanceToRootTo?: number;
  startRepairDate?: string;
  endRepairDate?: string;
};

export type QuickFilter = {
  site: string[];
  turbine: string[];
  bladePosition: string[];
  findingType: string[];
};

export type FilterOptionsResult = {
  repairTypes: string[];
  inspections: string[];
  countryNames: string[];
  siteNames: string[];
  turbineNames: string[];
  bladePositions: string[];
  surfaces: string[];
  categories: string[];
  severities: string[];
  responseLevels: string[];
  repairCompanies: string[];
  distanceToRootMax: number;
  distanceToRootMin: number;
};

export type FilterOptionsExtended = {
  optionsResult: FilterOptionsResult;
  lastChangedField: string | undefined;
};

type AttachmentUploadCompletedResult = {
  id: string;
  repairInfoId: string;
  fileName: string;
  url?: string;
  errorOccurred: boolean;
};

export type AttachmentTable = {
  fileName: string;
  label: string;
  key: string;
};

export type FileWithLabel = {
  file: File;
  label: string;
  name: string;
  show: boolean;
};

export enum ModalVisibility {
  Repair = 'repair',
  ScheduleForRepair = 'scheduleForRepair',
  None = 'none'
}

type State = {
  isFirstLoading: boolean;
  repairList: RepairDetail[];
  currentPage: number;
  pageSize: number;
  sortProperty: string;
  sortDirection: OrderDirection;
  modalVisibility: ModalVisibility;
  totalNumberOfRepairItems: number;
  isLoading: boolean;
  isPreparingReport: boolean;
  isFiltersLoading: boolean;
  repairListInfo: RepairDetailInfo[];
  repairInfoAttachment: RepairInfoAttachments[];
  createdRepairInfo: CreatedRepairInfoForUpload | null;
  uploadInProgress: boolean;
  uploadedFiles: NewAttachment[];
  addedAttachments: NewAttachment[];
  repairTypes: string[];
  countries: string[];
  sites: string[];
  inspections: string[];
  turbines: string[];
  blades: string[];
  bladeSides: string[];
  findingTypes: string[];
  severities: string[];
  responseLevels: string[];
  repairCompanies: string[];
  distanceToRootMax: number;
  distanceToRootMin: number;
};

const initialState: State = {
  isFirstLoading: true,
  repairList: [],
  currentPage: 0,
  pageSize: take,
  sortProperty: '',
  sortDirection: OrderDirection.Ascending,
  modalVisibility: ModalVisibility.None,
  totalNumberOfRepairItems: 0,
  isLoading: true,
  isPreparingReport: false,
  isFiltersLoading: true,
  repairListInfo: [],
  repairInfoAttachment: [],
  createdRepairInfo: null,
  uploadInProgress: false,
  uploadedFiles: [],
  addedAttachments: [],
  repairTypes: [],
  inspections: [],
  countries: [],
  sites: [],
  turbines: [],
  blades: [],
  bladeSides: [],
  findingTypes: [],
  severities: [],
  responseLevels: [],
  repairCompanies: [],
  distanceToRootMax: 150,
  distanceToRootMin: 0
};

const repairListSlice = createSlice({
  name: 'repairList',
  initialState,
  reducers: {
    setRepairList(state, action: PayloadAction<{ repairList: RepairDetail[]; currentPage: number; totalNumberOfRepairItems?: number }>) {
      const { repairList, currentPage, totalNumberOfRepairItems } = action.payload;
      state.repairList = repairList;
      state.currentPage = currentPage;
      if (totalNumberOfRepairItems) {
        state.totalNumberOfRepairItems = totalNumberOfRepairItems;
      }
    },
    setModalVisibility(state, action: PayloadAction<ModalVisibility>) {
      state.modalVisibility = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsPreparingReport(state, action: PayloadAction<boolean>) {
      state.isPreparingReport = action.payload;
    },
    setFiltersLoading(state, action: PayloadAction<boolean>) {
      state.isFiltersLoading = action.payload;
    },
    setNewAttachmentsUploaded(
      state,
      action: PayloadAction<{ repairInfoWithAttachments: RepairInfoWithAttachments; attachments: RepairInfoAttachmentCreated[] }>
    ) {
      const { attachments, repairInfoWithAttachments } = action.payload;
      const { repairInfoId } = repairInfoWithAttachments;

      const repairInfo = state.repairList.find(x => x.repairInfoId === repairInfoId);

      if (repairInfo) {
        const newAttachments = attachments.map(a => ({
          id: a.id,
          repairInfoId: repairInfoId,
          fileName: a.fileName,
          label: a.label,
          show: a.show,
          url: '',
          isUploaded: false,
          isUploading: true,
          errorOccurred: false,
          uploadDate: repairInfo.repairDate.toString(),
          canBeDeleted: true
        }));

        state.repairList = state.repairList.map(x => {
          if (x.repairInfoId === repairInfoId) {
            return {
              ...x,
              attachments: [...x.attachments, ...newAttachments]
            };
          }

          return x;
        });

        state.createdRepairInfo = {
          id: repairInfoId,
          attachments: attachments
        };
      }
    },
    setCreatedRepairInfo(state, action: PayloadAction<RepairInfoCreated | null>) {
      if (action.payload) {
        const { repairDate, repairDataFindingIds, repairType, repairAttachments, comment } = action.payload;
        const repairInfoCreated = action.payload;
        state.createdRepairInfo = repairInfoCreated;
        state.repairList = state.repairList.map(x => {
          if (repairDataFindingIds.some(r => r === x.findingId)) {
            const repairItem = { ...x };
            repairItem.repairDate = repairDate?.toString() ?? '';
            repairItem.repairInfoId = repairInfoCreated.id;
            repairItem.repairType = repairType.toString();
            repairItem.comment = comment;
            repairItem.workOrderNumber = repairInfoCreated.workOrderNumber;
            repairItem.attachments = [];
            repairInfoCreated.attachments.forEach(a => {
              repairItem.attachments.push({
                id: a.id,
                repairInfoId: repairInfoCreated.id,
                fileName: a.fileName,
                label: repairAttachments.find(r => r.fileName === a.fileName)?.label ?? '',
                url: '',
                show: a.show,
                isUploaded: false,
                isUploading: true,
                errorOccurred: false,
                uploadDate: repairDate?.toString() ?? Date.now().toString(),
                canBeDeleted: true
              });
            });
            return repairItem;
          } else {
            return x;
          }
        });
      } else {
        state.createdRepairInfo = null;
      }
    },
    setUploadInProgress: (state, action: PayloadAction<boolean>) => {
      state.uploadInProgress = action.payload;
    },
    clearUploadedFiles(state) {
      state.uploadedFiles = [];
    },
    uploadStarted(state, action: PayloadAction<AttachmentInfo[]>) {
      action.payload.forEach(forUpload => {
        const attachment = state.addedAttachments.find(x => x.repairInfoId === forUpload.repairInfoId && x.id === forUpload.id);
        if (attachment) {
          attachment.isUploading = true;
          attachment.errorOccurred = false;
        }
      });
    },
    uploadCompleted(state, action: PayloadAction<AttachmentUploadCompletedResult[]>) {
      action.payload.forEach(result => {
        const attachment = state.addedAttachments.find(x => x.repairInfoId === result.repairInfoId && x.id === result.id);
        if (attachment) {
          attachment.url = result.url;
          attachment.errorOccurred = result.errorOccurred;
          attachment.isUploading = false;
          attachment.uploadDate = formatDate(new Date());

          if (!result.errorOccurred) {
            state.uploadedFiles.push(attachment);
          }
        }
      });
    },
    setAddedAttachments(state, action: PayloadAction<NewAttachment[]>) {
      state.addedAttachments.push(...action.payload);
    },
    deleteAttachment(state, action: PayloadAction<{ repairInfoId: string; id: string }>) {
      const { repairInfoId, id } = action.payload;
      const repairInfo = state.repairList.find(x => x.repairInfoId === repairInfoId);

      if (repairInfo) {
        repairInfo.attachments = repairInfo.attachments.filter(x => x.id !== id);
      }
    },
    toggleAttachmentVisibility(state, action: PayloadAction<{ repairInfoId: string; id: string }>) {
      const { repairInfoId, id } = action.payload;
      const repairInfo = state.repairList.find(x => x.repairInfoId === repairInfoId);

      if (repairInfo) {
        repairInfo.attachments = repairInfo.attachments.map(x => {
          if (x.id === id) {
            return {
              ...x,
              show: !x.show
            };
          }

          return x;
        });
      }
    },
    setSites(state, action: PayloadAction<string[]>) {
      state.sites = action.payload;
    },
    setTurbines(state, action: PayloadAction<string[]>) {
      state.turbines = action.payload;
    },
    setBlades(state, action: PayloadAction<string[]>) {
      state.blades = action.payload;
    },
    setFindingTypes(state, action: PayloadAction<string[]>) {
      state.findingTypes = action.payload;
    },
    setFilterOptions(state, action: PayloadAction<FilterOptionsExtended>) {
      const {
        repairTypes,
        inspections,
        countryNames,
        siteNames,
        turbineNames,
        bladePositions,
        surfaces,
        categories,
        severities,
        responseLevels,
        repairCompanies,
        distanceToRootMax,
        distanceToRootMin
      } = action.payload.optionsResult;
      state.repairTypes = repairTypes;

      if (action.payload.lastChangedField === 'site') {
        state.inspections = [
          ...new Set(
            inspections
              .map(x => {
                //x: name|number / date
                if (x.includes('|')) {
                  const parsedLine = x.split('|');
                  if (parsedLine[1].includes('/')) {
                    const parsedSlash = parsedLine[1].split('/');
                    return parsedSlash[0].trim();
                  }
                }

                return x;
              })
              .sort((a, b) => {
                return +a - +b;
              })
          )
        ];
        state.turbines = turbineNames;
      }

      if (action.payload.lastChangedField === undefined) {
        state.countries = countryNames;
      }

      if (action.payload.lastChangedField === 'country') {
        state.sites = siteNames;
      }

      state.blades = bladePositions ?? [];
      state.bladeSides = surfaces ?? [];
      state.findingTypes = categories ?? [];
      state.severities = severities ?? [];
      state.responseLevels = responseLevels ?? [];
      state.repairCompanies = repairCompanies ?? [];
      state.distanceToRootMax = distanceToRootMax ?? 0;
      state.distanceToRootMin = distanceToRootMin ?? 0;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    }
  }
});

export const {
  setRepairList,
  setModalVisibility,
  setLoading,
  setIsPreparingReport,
  setFiltersLoading,
  setCreatedRepairInfo,
  setUploadInProgress,
  clearUploadedFiles,
  uploadStarted,
  uploadCompleted,
  setAddedAttachments,
  deleteAttachment,
  setSites,
  setTurbines,
  setBlades,
  setFindingTypes,
  setFilterOptions,
  setPageSize,
  setNewAttachmentsUploaded,
  toggleAttachmentVisibility
} = repairListSlice.actions;

export default repairListSlice.reducer;

export const getRepairList =
  (newPage: number, filter: Filter | null = null, take: number): AppThunk =>
  dispatch => {
    dispatch(getRepairListResult(newPage, filter, take));
    dispatch(getRepairListFilters(filter));
  };

const getRepairListResult =
  (newPage: number, filter: Filter | null = null, take: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { pageSize } = getState().repairList;

    if (take !== pageSize) {
      dispatch(setPageSize(take));
    }

    const config: PagingConfiguration = {
      take: take,
      skip: newPage - 1,
      repairType: filter && filter.repairType,
      country: filter && filter.country,
      site: filter && filter.site,
      inspection: filter && filter.inspection,
      turbine: filter && filter.turbine,
      blade: filter && filter.blade,
      bladeSide: filter && filter.bladeSide,
      findingType: filter && filter.findingType,
      severity: filter && filter.severity,
      responseLevel: filter && filter.responseLevel,
      repairCompany: filter && filter.repairCompany,
      defectId: filter && filter.defectId,
      sortProperty: filter && filter.sortProperty,
      sortDirection: filter && filter.sortDirection,
      distanceToRootFrom: filter && filter.distanceToRootFrom,
      distanceToRootTo: filter && filter.distanceToRootTo,
      startRepairDate: filter && filter.startRepairDate,
      endRepairDate: filter && filter.endRepairDate
    };

    await axios
      .post<PagingResult>(`repair/search`, config)
      .then(response => {
        if (response && response.data) {
          const { items, totalNumberOfRecords } = response.data;
          dispatch(
            setRepairList({
              currentPage: newPage,
              repairList: items,
              totalNumberOfRepairItems: totalNumberOfRecords
            })
          );
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const createRepairSummaryReport =
  (filter: Filter | null = null): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsPreparingReport(true));

    const { pageSize } = getState().repairList;

    const config: PagingConfiguration = {
      take: take,
      skip: 0,
      repairType: filter && filter.repairType,
      country: filter && filter.country,
      site: filter && filter.site,
      inspection: filter && filter.inspection,
      turbine: filter && filter.turbine,
      blade: filter && filter.blade,
      bladeSide: filter && filter.bladeSide,
      findingType: filter && filter.findingType,
      severity: filter && filter.severity,
      responseLevel: filter && filter.responseLevel,
      repairCompany: filter && filter.repairCompany,
      defectId: filter && filter.defectId,
      sortProperty: filter && filter.sortProperty,
      sortDirection: filter && filter.sortDirection,
      distanceToRootFrom: filter && filter.distanceToRootFrom,
      distanceToRootTo: filter && filter.distanceToRootTo,
      startRepairDate: filter && filter.startRepairDate,
      endRepairDate: filter && filter.endRepairDate
    };

    await axios
      .post(`repair/generate-repair-summary`, config)
      .then(response => {
        if (response) {
          ToastAnt('Report generation has been started, you will be notified on email when it is completed...');
        }
      })
      .catch(err => {
        ToastAnt('Error occurred while generating report');
      })
      .finally(() => {
        dispatch(setIsPreparingReport(false));
      });
  };

const getRepairListFilters =
  (filter: Filter | null = null): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setFiltersLoading(true));

    const config: FiltersConfiguration = {
      repairType: filter && filter.repairType,
      country: filter && filter.country,
      site: filter && filter.site,
      inspection: filter && filter.inspection,
      turbine: filter && filter.turbine,
      blade: filter && filter.blade,
      bladeSide: filter && filter.bladeSide,
      findingType: filter && filter.findingType,
      severity: filter && filter.severity,
      responseLevel: filter && filter.responseLevel,
      repairCompany: filter && filter.repairCompany,
      defectId: filter && filter.defectId
    };

    await axios
      .post<FilterOptionsResult>(`repair/filters`, config)
      .then(response => {
        if (response && response.data) {
          const data: FilterOptionsExtended = {
            optionsResult: response.data,
            lastChangedField: filter?.lastChangedField
          };

          dispatch(setFilterOptions(data));
        }
      })
      .finally(() => {
        dispatch(setFiltersLoading(false));
      });
  };

export const saveRepairInfo =
  (repairInfo: RepairInfo, filter: Filter | null = null): AppThunk =>
  async (dispatch, getState) => {
    const { currentPage, pageSize } = getState().repairList;
    dispatch(setLoading(true));
    try {
      const result = await axios.post<RepairInfoCreated>(`repair`, {
        companyId: repairInfo.companyId,
        comment: repairInfo.comment,
        repairType: repairInfo.repairType,
        repairDataFindingIds: repairInfo.repairDataFindingIds,
        repairDate: repairInfo.repairDate,
        workOrderNumber: repairInfo.workOrderNumber,
        attachments: repairInfo.attachments
      });

      if (result && result.data) {
        dispatch(
          setCreatedRepairInfo({
            ...result.data,
            repairAttachments: repairInfo.attachments,
            repairDataFindingIds: repairInfo.repairDataFindingIds,
            repairDate: repairInfo.repairDate,
            repairType: repairInfo.repairType,

            comment: repairInfo.comment
          })
        );
      }
    } catch (err) {
      ToastAnt(err.response);
    }
    dispatch(setLoading(false));
    dispatch(setModalVisibility(ModalVisibility.None));
  };

export const addAttachmentsForExistingRepair =
  (repairInfo: RepairInfoWithAttachments, attachments: CreateAttachment[]): AppThunk =>
  async dispatch => {
    try {
      dispatch(setLoading(true));

      const result = await axios.put<RepairInfoAttachmentCreated[]>(`repair/attachments`, {
        attachments,
        repairInfoId: repairInfo.repairInfoId
      });

      dispatch(
        setNewAttachmentsUploaded({
          repairInfoWithAttachments: repairInfo,
          attachments: result.data
        })
      );
    } catch (error) {}
    dispatch(setLoading(false));
  };

export const onAttachmentsAdded =
  (attachmentForUpload: RepairInfoAttachments[]): AppThunk =>
  async dispatch => {};

export const onDeleteAttachment =
  (repairInfoId: string, id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = await axios.delete(`repair/attachment/${id}`);

      dispatch(deleteAttachment({ repairInfoId, id }));

      ToastAnt('Deleted');
    } catch (error) {
      console.log(error);
    }
  };

export const onTogggleAttachmentVisibility =
  (repairInfoId: string, id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = await axios.put(`repair/attachment/${id}/toggleVisibility`);

      dispatch(toggleAttachmentVisibility({ repairInfoId, id }));

      ToastAnt('Done');
    } catch (error) {
      console.log(error);
    }
  };

export const setToNotFixed =
  (defectIds: string[], filter: Filter | null = null): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { currentPage, pageSize } = getState().repairList;

      const response = await axios.post(`repair/setToNotFixed`, { defectIds });

      dispatch(getRepairList(currentPage, filter, pageSize));

      ToastAnt('Done');
    } catch (error) {
      console.log(error);
    }
  };

export const uploadAttachments =
  (attachmentForUpload: AttachmentForUpload[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setUploadInProgress(true));

    dispatch(
      uploadStarted(
        attachmentForUpload.map(x => ({
          fileName: x.fileName,
          id: x.id,
          repairInfoId: x.repairInfoId
        }))
      )
    );

    const tasks = attachmentForUpload.map(async attachment => {
      const result = await uploadFile(attachment);
      return result;
    });

    const results = await Promise.allSettled(tasks);

    const attachmentUploadResult: AttachmentUploadCompletedResult[] = [];

    results.forEach((result, index) => {
      const attachment = attachmentForUpload[index];

      if (result.status === 'fulfilled') {
        attachmentUploadResult.push({
          id: attachment.id,
          errorOccurred: false,
          fileName: attachment.file.name,
          repairInfoId: attachment.repairInfoId,
          url: result?.value?.url ? result?.value?.url : ''
        });
      }

      if (result.status === 'rejected') {
        attachmentUploadResult.push({
          id: attachment.id,
          errorOccurred: true,
          fileName: attachment.file.name,
          repairInfoId: attachment.repairInfoId
        });
      }
    });

    dispatch(uploadCompleted(attachmentUploadResult));
    dispatch(setUploadInProgress(false));
  };

const uploadFile = async (attachment: AttachmentForUpload) => {
  let result: string | null = null;

  try {
    const bodyFormData = new FormData();
    bodyFormData.append('file', attachment.file);
    bodyFormData.append('attachmentId', attachment.id);

    const response = await axios.post<string>('repair/attachment', bodyFormData);

    result = response.data;
  } catch (error) {
    console.log(error);
  }

  return {
    url: result ? result : null
  };
};

function formatDate(d: Date) {
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
