import React, { FC } from 'react';
import styles from '../RepairList.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  message: string;
  fontSize?: string;
};

const InfoMessage: FC<Props> = ({ message, fontSize }) => {
  return (
    <div className={styles.InfoBox}>
      <InfoCircleOutlined />
      <span className={styles.InfoMessageContainer}>{message}</span>
    </div>
  );
};

export default InfoMessage;
