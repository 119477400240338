import axios from 'axios';
import { AuthService } from './components/Auth/AuthService';
import { ToastAnt } from './components/Toast/ToastAnt';
import * as Utils from 'hermes-tiles-grid-popup';

const apiBaseUrl = Utils.replaceTenantNamePlaceHolder(process.env.REACT_APP_QBR_MANAGER_API!);

const instance = axios.create({
  baseURL: apiBaseUrl
});

instance.interceptors.request.use(
  config => {
    return AuthService.getUser().then(user => {
      if (user != null && user.access_token != null) {
        const { access_token } = user;
        config.headers.Authorization = `Bearer ${access_token}`;
      }
      return Promise.resolve(config);
    });
  },
  err => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  config => config,
  err => {
    if (err) {
      if (err.response) {
        if (err.response.status === 401) {
          window.location.pathname = './not-authorized';
        }
        if (err.response.status === 403) {
          window.location.pathname = './not-authorized';
        }
        if (err.response.status >= 500 && err.response.status <= 511) {
          ToastAnt('A server error occurred. Please contact the administrator.');
        }
      } else if (err.request) {
        ToastAnt('A server error occurred. The request was made but no response was received. Please contact the administrator.');
      } else {
        ToastAnt('Unknown error occurred.');
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
