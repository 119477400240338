import React, { useState, useEffect } from 'react';
import { AuthService } from './AuthService';
import { Redirect } from 'react-router';

const Callback = () => {
  const [loaded, setLoaded] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState<string | null>('');

  useEffect(() => {
    window.document.title = 'Authentication callback processing..';
    AuthService.signinRedirectCallback().then(
      user => {
        setRedirectRoute(user.state);
        setLoaded(true);
      },
      error => {
        console.error(error);
        setLoaded(true);
        setRedirectRoute(null);
      }
    );
  }, []);

  if (loaded) {
    if (redirectRoute) {
      return <Redirect to={redirectRoute} />;
    } else {
      return <Redirect to="/" />;
    }
  }
  return <h1>Authentication callback processing...</h1>;
};

export default Callback;
