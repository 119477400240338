import { FindingAnnotations, Point, ImageData, Annotation } from '../../Bid/bid.slice';
import Raphael from 'raphael';

const severityColors = ['#fcd5a6', '#fab45e', '#ee753f', '#c8c1ce', '#ba0e49', '#b9b9b9'];

const getSeverityColor = (value: any) => {
  return severityColors[value - 1];
};

export const getPropertyBackgroundColor = (title: string, value: any) => {
  if (title === 'Severity') {
    return getSeverityColor(value);
  }
  return '';
};

export const roundNumberTo2Decimals = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

const getScaleFactor = (image: HTMLImageElement, imageData: ImageData): Point => {
  return {
    x: image.width / imageData.width,
    y: image.height / imageData.height
  };
};

const thickness = 2;
const transparency = 1;

export const drawFindings = (canvas: HTMLDivElement, image: HTMLImageElement, finding: FindingAnnotations) => {
  const scaledFactor = getScaleFactor(image, finding.images[0]);

  const paper = Raphael(canvas, image.clientWidth, image.clientHeight);

  paper.clear();
  const scaledData = toSVG(finding.annotationData, scaledFactor);
  paper.setSize(image.clientWidth, image.clientHeight);
  const c = paper.path(scaledData!.svg).attr({
    stroke: finding.annotationData.color,
    'stroke-width': thickness,
    'stroke-opacity': transparency
  });
};

const toSVG = (defect: Annotation, scaledFactor: Point) => {
  let x = 0,
    y = 0;

  const xoffset = 0;
  const yoffset = 0;
  const contour = defect.shape;

  if (contour.length <= 0) {
    return;
  }

  x = (contour[0].x - xoffset) * scaledFactor.x;
  y = (contour[0].y - yoffset) * scaledFactor.y;
  let svg = 'M' + x.toString() + ' ' + y.toString();

  for (let i = 1; i < contour.length; i++) {
    x = (contour[i].x - xoffset) * scaledFactor.x;
    y = (contour[i].y - yoffset) * scaledFactor.y;
    svg += 'L' + x.toString() + ' ' + y.toString();
  }

  return {
    svg: svg,
    scaledWidth: defect.width * scaledFactor.x,
    scaledHeight: defect.height * scaledFactor.y
  };
};
