import React from 'react';
import EditableCell from '../Bid/EditableCell';
import { BidData } from '../Bid/bid.slice';
import SpanNumberFormat from '../Shared/Span/SpanNumberFormat';

type ColumnProps = {
  title: string;
  dataIndex?: string;
  key: string;
  render?: (data: any) => JSX.Element;
  width?: string;
  editable?: boolean;
};

const DEFAULT_COLUMN_WIDTH = '150px';

const ColumnWidths = new Map<string, string>([
  ['status', '150px'],
  ['siteName', '150px'],
  ['companyName', '250px'],
  ['description', '250px'],
  ['action', '80px'],
  ['contactPerson', '80px']
]);

const getColumnWidth = (column: string) => {
  if (ColumnWidths.has(column)) {
    return ColumnWidths.get(column)!;
  }

  return DEFAULT_COLUMN_WIDTH;
};

const getCommonColumnProperties = (
  key: string,
  title: string,
  render?: ((record: string) => JSX.Element) | undefined,
  addDataIndex = true,
  align?: string | undefined
) => ({
  title,
  dataIndex: addDataIndex ? key : undefined,
  key: key,
  width: getColumnWidth(key),
  render,
  align
});

type Column = {
  key: string;
  title: string;
  addDataIndex: boolean;
  render?: ((record: any) => JSX.Element) | undefined;
  align?: string | undefined;
};

const bidDataColumns: Column[] = [
  {
    key: 'accessType',
    title: 'Access type',
    addDataIndex: true
  },
  {
    key: 'findingType',
    title: 'Finding type',
    addDataIndex: true
  },
  {
    key: 'cases',
    title: 'Cases',
    align: 'center',
    addDataIndex: true,
    render: record => <span className='alignRight'>{record}</span>
  },
  {
    key: 'scopeOfWorkReference',
    title: 'Scope of work reference',
    addDataIndex: true
  },
  {
    key: 'numberOfWTG',
    title: 'Number of WTG',
    align: 'center',
    addDataIndex: true,
    render: record => <span className='alignRight'>{record}</span>
  },
  {
    key: 'numberOfBlades',
    title: 'Number of blades',
    align: 'center',
    addDataIndex: true,
    render: record => <span className='alignRight'>{record}</span>
  },
  {
    key: 'tooling',
    title: 'Tooling',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => <EditableCell disabled={true} record={record} inputType={'number'} dataIndex={'tooling'} />
  },
  {
    key: 'additionalCosts',
    title: 'Additional costs',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => <EditableCell disabled={true} record={record} inputType={'number'} dataIndex={'additionalCosts'} />
  },
  {
    key: 'totalManPower',
    title: 'Total manpower costs',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => <EditableCell disabled={true} record={record} inputType={'number'} dataIndex={'totalManPower'} />
  },
  {
    key: 'estimatedHours',
    title: 'Estimated hours',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => <EditableCell disabled={true} record={record} inputType={'number'} dataIndex={'estimatedHours'} />
  },
  {
    key: 'hours',
    title: 'Hours / finding',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <SpanNumberFormat className='alignRight' value={record.estimatedHours / record.cases} currency={undefined} hideZero={true} />
    )
  },
  {
    key: 'totalFixedCost',
    title: 'Total fixed cost',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => (
      <SpanNumberFormat
        className='alignRight'
        value={record.additionalCosts + record.tooling + record.totalManPower}
        currency={undefined}
        hideZero={true}
      />
    )
  },
  {
    key: 'comment',
    title: 'Comment',
    addDataIndex: false,
    align: 'center',
    render: (record: BidData): JSX.Element => <EditableCell disabled={true} record={record} inputType={'text'} dataIndex={'comment'} />
  }
];

export const getBidDataColumns = (onRowChanged: (record: any) => void): ColumnProps[] =>
  bidDataColumns.map(x => getCommonColumnProperties(x.key, x.title, x.render, x.addDataIndex, x.align));
