import { createContext, useCallback, useEffect, useState } from 'react';
import { Filter } from '../repairList.slice';

export const useFilters = () => {
  const [repairType, setRepairType] = useState<string[]>();
  const [lastChangedField, setLastChangedField] = useState<string>();
  const [site, setSite] = useState<string[]>();
  const [country, setCountry] = useState<string[]>();
  const [inspection, setInspection] = useState<string[]>();
  const [turbine, setTurbine] = useState<string[]>();
  const [blade, setBlade] = useState<string[]>();
  const [bladeSide, setBladeSide] = useState<string[]>();
  const [findingType, setFindingType] = useState<string[]>();
  const [severity, setSeverity] = useState<string[]>();
  const [responseLevel, setResponseLevel] = useState<string[]>();
  const [repairCompany, setRepairCompany] = useState<string[]>();
  const [defectId, setDefectId] = useState<string>();
  const [changed, setChanged] = useState<boolean>(true);
  const [ready, setReady] = useState(false);
  const [distanceToRootFrom, setDistanceToRootFrom] = useState<number>();
  const [distanceToRootTo, setDistanceToRootTo] = useState<number>();
  const [startRepairDate, setStartRepairDate] = useState<string>();
  const [endRepairDate, setEndRepairDate] = useState<string>();

  const clearSelected = useCallback(() => {
    setRepairType(undefined);
    setLastChangedField(undefined);
    setCountry(undefined);
    setSite(undefined);
    setInspection(undefined);
    setTurbine(undefined);
    setBlade(undefined);
    setBladeSide(undefined);
    setFindingType(undefined);
    setSeverity(undefined);
    setResponseLevel(undefined);
    setRepairCompany(undefined);
    setDistanceToRootFrom(undefined);
    setDistanceToRootTo(undefined);
    setStartRepairDate(undefined);
    setEndRepairDate(undefined);
  }, []);

  const clearSelectedDefect = useCallback(() => {
    if (defectId) {
      setDefectId(undefined);
      const url = `/repair`;
      window.history.replaceState(null, 'Repair Module', url);
    }
  }, [defectId]);

  useEffect(() => {
    setChanged(true);

    !inspection && !site && setTurbine(undefined);
  }, [
    country,
    inspection,
    site,
    turbine,
    repairType,
    blade,
    bladeSide,
    findingType,
    severity,
    responseLevel,
    repairCompany,
    distanceToRootFrom,
    distanceToRootTo,
    startRepairDate,
    endRepairDate
  ]);

  useEffect(() => {
    setChanged(true);

    if (defectId) {
      clearSelected();
    }
  }, [clearSelected, defectId]);

  const utils: FiltersUtils = {
    repairType,
    setRepairType,
    lastChangedField,
    setLastChangedField,
    country,
    setCountry,
    site,
    setSite,
    inspection,
    setInspection,
    turbine,
    setTurbine,
    blade,
    setBlade,
    bladeSide,
    setBladeSide,
    findingType,
    setFindingType,
    severity,
    setSeverity,
    defectId,
    setDefectId,
    responseLevel,
    setResponseLevel,
    repairCompany,
    setRepairCompany,
    distanceToRootFrom,
    setDistanceToRootFrom,
    distanceToRootTo,
    setDistanceToRootTo,
    clearSelected,
    clearSelectedDefect,
    startRepairDate,
    endRepairDate,
    setStartRepairDate,
    setEndRepairDate
  };

  const filter: Filter = {
    repairType,
    lastChangedField,
    country,
    site,
    inspection,
    turbine,
    blade,
    bladeSide,
    findingType,
    severity,
    responseLevel,
    repairCompany,
    defectId,
    distanceToRootFrom,
    distanceToRootTo,
    startRepairDate,
    endRepairDate
  };

  return {
    utils,
    filter,
    changed,
    clearChanged: () => setChanged(false),
    ready,
    setReady
  };
};

export type FiltersUtils = {
  repairType: string[] | undefined;
  lastChangedField: string | undefined;
  country: string[] | undefined;
  site: string[] | undefined;
  inspection: string[] | undefined;
  turbine: string[] | undefined;
  blade: string[] | undefined;
  bladeSide: string[] | undefined;
  findingType: string[] | undefined;
  severity: string[] | undefined;
  defectId: string | undefined;
  responseLevel: string[] | undefined;
  repairCompany: string[] | undefined;
  distanceToRootFrom: number | undefined;
  distanceToRootTo: number | undefined;
  startRepairDate: string | undefined;
  endRepairDate: string | undefined;
  setRepairType: (value: string[] | undefined) => void;
  setLastChangedField: (value: string | undefined) => void;
  setCountry: (value: string[] | undefined) => void;
  setSite: (value: string[] | undefined) => void;
  setInspection: (value: string[] | undefined) => void;
  setTurbine: (value: string[] | undefined) => void;
  setBlade: (value: string[] | undefined) => void;
  setBladeSide: (value: string[] | undefined) => void;
  setFindingType: (value: string[] | undefined) => void;
  setSeverity: (value: string[] | undefined) => void;
  setDefectId: (value: string | undefined) => void;
  setResponseLevel: (value: string[] | undefined) => void;
  setRepairCompany: (value: string[] | undefined) => void;
  setDistanceToRootFrom: (value: number | undefined) => void;
  setDistanceToRootTo: (value: number | undefined) => void;
  setStartRepairDate: (value: string | undefined) => void;
  setEndRepairDate: (value: string | undefined) => void;
  clearSelected: () => void;
  clearSelectedDefect: () => void;
};

export const FiltersContext = createContext<FiltersUtils>({} as FiltersUtils);
