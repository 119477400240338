import { createSelector } from 'reselect';
import { RootState } from '../../rootReducer';
import { getBudgetListColumns } from './budgetListHelper';

const budgetList = (state: RootState) => state.budgetList.budgetList;

export const prepareBudgetListSelector = createSelector([budgetList], gridData => {
  if (!gridData || gridData.length === 0) {
    return {
      columns: [],
      dataSource: []
    }
  }

  let columns = getBudgetListColumns();

  const dataSource = gridData.map(x => ({
    ...x,
    key: x.quoteId
  }));

  return {
    columns,
    dataSource
  }
});
