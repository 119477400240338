export enum PermissionEnum {
  // QBRManager permissions
  QuoteManager_Admin = 5000,
  BudgetManager_Admin = 5001,
  RepairManager_Admin = 5002,
  QuoteManager_Bidder = 5003,
  RepairManager_AddAttachment = 5004,
  RepairManager_CanDeleteAnyAttachments = 5005,
  RepairManager_CanToggleVisibility = 5006,
  RepairManager_IgnoreVisibilityFlag = 5007,
  RepairManager_CanDeleteOnlyItsOwnAttachments = 5008,
  RepairManager_CanRepair = 5009,
  RepairManager_CanGenerateRepairReport = 5010,
  RepairManager_CanSetToBeRepaired = 5011,
  RepairManager_CanSetNotFixed = 5012,

  RepairManage_CanSeeNotFixedFindings = 5013,
  RepairManage_CanUseRepair = 5014,

  RepairManager_InternalUserPermission = 5015,
  RepairManager_CustomerPermission = 5016,
  RepairManager_ExternalSupplierPermission = 5017
}

export enum QuoteStatusEnum {
  InProgress = 1,
  WaitingForBids = 2,
  BidReceived = 3,
  BidRewarded = 4,
  Cancelled = 5
}

export enum BidderStatusEnum {
  InviteNotSent = 0,
  WaitingForBid = 1,
  BidReceived = 2,
  BidRewarded = 3,
  BidRejected = 4
}

export enum CurrencyEnum {
  Euro = 1,
  Dollar = 2
}

export const QuoteStatusesForSelect = {
  1: {
    id: QuoteStatusEnum.InProgress,
    name: 'In progress'
  },
  2: {
    id: QuoteStatusEnum.WaitingForBids,
    name: 'Waiting for bids'
  },
  3: {
    id: QuoteStatusEnum.BidReceived,
    name: 'Bid received'
  },
  4: {
    id: QuoteStatusEnum.BidRewarded,
    name: 'Bid rewarded'
  },
  5: {
    id: QuoteStatusEnum.Cancelled,
    name: 'Cancelled'
  }
};

export const BidderStatusesForSelect = {
  InviteNotSent: {
    id: BidderStatusEnum.InviteNotSent,
    name: 'Invite not sent'
  },
  WaitingForBid: {
    id: BidderStatusEnum.WaitingForBid,
    name: 'Waiting for bid'
  },
  BidReceived: {
    id: BidderStatusEnum.BidReceived,
    name: 'Bid received'
  },
  BidRewarded: {
    id: BidderStatusEnum.BidRewarded,
    name: 'Bid rewarded'
  },
  BidRejected: {
    id: BidderStatusEnum.BidRejected,
    name: 'Bid rejected'
  }
};

export const BidderStatusesToDescription = {
  0: {
    id: BidderStatusEnum.InviteNotSent,
    name: 'Invite not sent'
  },
  1: {
    id: BidderStatusEnum.WaitingForBid,
    name: 'Waiting for bid'
  },
  2: {
    id: BidderStatusEnum.BidReceived,
    name: 'Bid received'
  },
  3: {
    id: BidderStatusEnum.BidRewarded,
    name: 'Bid rewarded'
  },
  4: {
    id: BidderStatusEnum.BidRejected,
    name: 'Bid rejected'
  }
};

export enum BladePositionEnum {
  A = 1,
  B = 2,
  C = 3,
  Unknown = 4
}

export enum BladeSideEnum {
  PressureSide = 1,
  SuctionSide = 2,
  LeadingEdge = 3,
  TrailingEdge = 4
}

export enum SeverityEnum {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5
}

export enum DefectLayerEnum {
  Unknown = 0,
  Surface = 1,
  AddOn = 2,
  LEP = 3,
  Paint = 4,
  Filler = 5,
  Laminate = 6,
  CriticalDefect = 7,
  Wood = 8
}

export enum DefectTypeEnum {
  Undefined = 0,
  Contamination = 1,
  PreviousRepairs = 3,
  ChippedPeelingPaint = 4,
  DrainHoleDefects = 5,
  Lps = 6,
  Pinholes = 9,
  SuperficialTransverseCrack = 10,
  SuperficialLongitudinalCrack = 11,
  ScratchesAndGouges = 12,
  StructuralCracks = 14,
  TrailingEdgeDamage = 15,
  Erosion = 17,
  SurfaceVoids = 18,
  Unknown = 19,
  GurneyFlap = 21,
  VortexGenerator = 22,
  DinoTail = 24,
  DinoShell = 25,
  StallStrip = 26,
  Delamination = 27,
  PowerEdge = 28
}

export enum RepairType {
  Repaired = 'Repaired',
  Replaced = 'Replaced',
  NotFixed = 'NotFixed',
  ToBeRepaired = 'ToBeRepaired'
}
