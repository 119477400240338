import React, { FC, CSSProperties } from 'react';
import styles from './Button.module.scss';
import { Spin } from 'antd';

interface ButtonProps {
  onClick: (e: ButtonSubmitEvent) => void;
  disabled?: boolean;
  title: JSX.Element | string;
  type: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  style?: CSSProperties | undefined;
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({ onClick, disabled, title, type, className, style, isLoading }) => {
  return (
    <button onClick={onClick} type={type} className={`${styles.customBtn} ${styles.customBtnPrimary} ${className}`} disabled={disabled} style={style}>
      {title}
      {isLoading && <Spin />}
    </button>
  );
};

export default Button;
