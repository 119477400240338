import React from 'react';
import styles from './Auth.module.scss';

const NotAuthorized = () => (
  <div>
    <h1 className={styles.center}>You are not authorized to see this page</h1>
  </div>
);

export default NotAuthorized;
