import { createSelector } from 'reselect';
import { RootState } from '../../rootReducer';
import { getShortFormattedDate } from '../../shared/utils';
import { getQuoteListColumns } from './quoteListHelper';

const quotes = (state: RootState) => state.quoteList.quotes;

export const prepareQuoteListSelector = createSelector([quotes], gridData => {
  if (!gridData || gridData.length === 0) {
    return {
      columns: [],
      dataSource: []
    };
  }

  let columns = getQuoteListColumns();

  const dataSource = gridData.map(x => ({
    ...x,
    tenderCloseDate: getShortFormattedDate(x.tenderCloseDate),
    key: x.id
  }));

  return {
    columns,
    dataSource
  };
});
