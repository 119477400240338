import React, { FC } from 'react';
import { Badge } from 'antd';

type Props = {
  status: string;
};

const Status: FC<Props> = ({ status }) => {
    const getColorBadgeForStatus = ()  => {
      switch (status) {
        case 'Bid received':
          return 'green';
        case 'In progress':
          return 'orange';
        case 'Bid rewarded':
          return 'blue';
        case 'Cancelled':
          return 'red';
        case 'Bid rejected':
          return 'red';
        case 'Waiting for bid':
          return 'yellow';
        case 'Waiting for bids':
          return 'yellow';
        default:
          return '#D7DBDD';
      }
  };

  return (
    <span>
      <Badge color={getColorBadgeForStatus()}/>
      {status} 
    </span>
  );
};

export default Status;
