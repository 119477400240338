import React, { useState, useEffect } from 'react';
import './HermesTiles.scss';
import { Popover } from 'antd';
import useAuth from '../Auth/UseAuth';
import 'hermes-tiles-grid-popup';
import { Icon } from '@iconify/react';
import appstoreOutlined from '@iconify/icons-ant-design/appstore-outlined';

const HermesTiles = () => {
  const [givenName, familyName, apps] = useAuth();
  const [appsArr, setAppsArr] = useState<Array<any>>([]);

  const spliter = (array: any) => {
    let parsedApps: any = [];

    array.forEach((el: any) => {
      let newAr = el.split('|');
      parsedApps.push(`/assets/${newAr[0]}.png|${newAr[1]}|${newAr[2]}`);
    });
    return parsedApps;
  };

  useEffect(() => {
    if (apps.length > 0) {
      setAppsArr(spliter(apps));
    }
  }, [apps]);

  return (
    <div className='hermes-tiles-modal-container'>
      <Popover
        trigger='click'
        content={
          appsArr.length > 0 ? (
            <div style={{ width: '300px', height: '300px' }}>
              <gm-react-web-component
                class='tiles'
                style={{ overflowWrap: 'break-word' }}
                background-color='white'
                apps={JSON.stringify(appsArr)}
              ></gm-react-web-component>
            </div>
          ) : (
            ''
          )
        }
        placement='bottomRight'
      >
        <Icon style={{ height: '21px', width: '21px', cursor: 'pointer' }} icon={appstoreOutlined} />
      </Popover>
    </div>
  );
};

export default HermesTiles;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      [elemName: string]: any;
    }
  }
}
