import { useEffect } from 'react';
import { AttachmentForUpload, NewAttachment, RepairInfoAttachments } from '../repairList.slice';
import { clearUploadedFiles, FILE_FOR_UPLOAD_PARALLEL_COUNT } from '../util';

export const useUploadFiles = (
  scheduledFiles: AttachmentForUpload[],
  uploadInProgress: boolean,
  uploadedFiles: NewAttachment[],
  setScheduledFiles: (files: AttachmentForUpload[]) => void,
  onClearUploadedFiles: () => void,
  onUploadFiles: (files: AttachmentForUpload[]) => void
) => {
  useEffect(() => {
    if (uploadInProgress || scheduledFiles.length === 0) {
      return;
    }

    if (uploadedFiles.length) {
      setScheduledFiles(clearUploadedFiles(scheduledFiles, uploadedFiles));
      onClearUploadedFiles();
      return;
    }

    const toUpload = scheduledFiles.slice(0, FILE_FOR_UPLOAD_PARALLEL_COUNT);

    if (!toUpload.length) {
      return;
    }

    onUploadFiles(toUpload);
  }, [onClearUploadedFiles, onUploadFiles, scheduledFiles, setScheduledFiles, uploadInProgress, uploadedFiles]);
};
