import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Radio, Select, DatePicker, Table, Spin, Button } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { prepareRepairInfoAttachmentSelector, RepairInfoWithAttachments } from './repairListSelector';
import { getCompanies } from '../Bidder/bidder.slice';
import { IdText } from '../Quote/quote.slice';
import {
  saveRepairInfo,
  RepairInfo,
  FileWithLabel,
  CreateAttachment,
  addAttachmentsForExistingRepair,
  ModalVisibility,
  Filter
} from './repairList.slice';
import { RepairType } from '../../shared/enums';
import { FilesContext } from './util/FilesContext';
import { getRepairListInfoAttachmentsColumns } from './repairListHelper';
import { ToastAnt } from '../Toast/ToastAnt';
import moment from 'moment';
import { useFilters } from './hooks/useFilters';

type ModalProps = {
  modalVisibility: ModalVisibility;
  companies: Array<IdText>;
  gridData: any;
  isLoading: boolean;
  currentPage: number;
  pageSize: number;
  onSetModalVisibility: (value: ModalVisibility) => void;
  getCompanies: () => void;
  saveRepairInfo: (repairInfo: RepairInfo, filter: Filter | null) => void;
  repairDataFindingIds: string[];
  repairInfoUploaded: () => void;
  recordForEdit?: RepairInfoWithAttachments;
  extendAttachments: (repairInfo: RepairInfoWithAttachments, attachments: CreateAttachment[]) => void;
};

const RepairInfoModal: FC<ModalProps> = ({
  modalVisibility,
  gridData,
  companies,
  isLoading,
  currentPage,
  pageSize,
  onSetModalVisibility,
  getCompanies,
  saveRepairInfo,
  repairDataFindingIds,
  repairInfoUploaded,
  recordForEdit,
  extendAttachments
}) => {
  const { setToScheduleFiles, toScheduleFiles } = useContext(FilesContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const { filter } = useFilters();

  const [form] = Form.useForm();
  const [companyName, setCompanyName] = useState('');
  const [repairType, setRepairType] = useState<RepairType>(
    modalVisibility === ModalVisibility.Repair ? RepairType.Repaired : RepairType.ToBeRepaired
  );
  const [repairDate, setRepairedDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    form.resetFields();
    getCompanies();
  }, [form, getCompanies]);

  useEffect(() => {
    switch (modalVisibility) {
      case ModalVisibility.Repair:
        setRepairType(RepairType.Repaired);
        break;
      case ModalVisibility.ScheduleForRepair:
        setRepairType(RepairType.ToBeRepaired);
        break;
      default:
        break;
    }
  }, [modalVisibility]);
  const onChange = (e: any) => {
    var fileList: FileList = e.target.files;
    var addedFiles = Array.prototype.slice.call(fileList);
    const toAdd: FileWithLabel[] = addedFiles.map(x => ({
      file: x,
      label: '',
      name: x.name,
      show: true
    }));
    setToScheduleFiles([...toScheduleFiles, ...toAdd]);
  };

  const companySelected = (value: string) => {
    setCompanyName(value);
  };

  const clearFileInput = useCallback(() => {
    if (fileRef && fileRef.current) {
      fileRef.current.value = '';
    }
  }, []);

  const resetState = () => {
    setCompanyName('');
    setRepairedDate(null);
    setRepairType(RepairType.Repaired);
  };

  const onUpload = async () => {
    if (!!recordForEdit) {
      extendAttachments(
        recordForEdit,
        toScheduleFiles.map(x => ({
          fileName: x.file.name,
          label: x.label,
          show: x.show
        }))
      );
      clearFileInput();
      form.resetFields();
      onSetModalVisibility(ModalVisibility.None);
      return;
    }

    try {
      await form.validateFields();

      const repairInfo: RepairInfo = {
        companyId: companies.find(x => x.text === companyName)?.id,
        comment: form.getFieldValue('comment'),
        repairDataFindingIds: repairDataFindingIds,
        repairType: repairType,
        repairDate: repairDate,
        workOrderNumber: !isNaN(form.getFieldValue('workOrderNumber')) ? Number(form.getFieldValue('workOrderNumber')) : 0,
        attachments: toScheduleFiles.map(x => ({
          fileName: x.file.name,
          label: x.label,
          show: x.show
        }))
      };
      saveRepairInfo(repairInfo, filter);
    } catch (error) {
      ToastAnt(error.response);
    }

    repairInfoUploaded();

    clearFileInput();
    resetState();
    form.resetFields();
  };

  const onCancel = () => {
    clearFileInput();
    form.resetFields();
    onSetModalVisibility(ModalVisibility.None);
    setToScheduleFiles([]);
  };

  const columns = getRepairListInfoAttachmentsColumns();
  const dataSource = toScheduleFiles.map(x => ({
    fileName: x.file.name,
    label: x.label,
    key: x.file.name
  }));

  return (
    <>
      <Modal
        title='Repair Info'
        visible={modalVisibility !== ModalVisibility.None}
        onCancel={onCancel}
        footer={
          <Button
            htmlType='submit'
            title='Upload'
            loading={isLoading}
            onClick={onUpload}
            disabled={
              (!recordForEdit && (repairDate === null || companyName === '') && modalVisibility === ModalVisibility.Repair) ||
              (!!recordForEdit && !toScheduleFiles.length && modalVisibility === ModalVisibility.Repair)
            }
          >
            Upload
          </Button>
        }
      >
        <Form form={form} layout='vertical' name='form_in_modal' initialValues={{ modifier: 'public' }}>
          {!recordForEdit && modalVisibility === ModalVisibility.Repair && (
            <Form.Item
              name='Repair company'
              label='Repair company'
              rules={[{ required: modalVisibility === ModalVisibility.Repair, message: 'Please select repair company', whitespace: true }]}
            >
              <Select
                showSearch
                notFoundContent={companyName === '' ? <Spin size='small' /> : null}
                style={{ width: '100%' }}
                placeholder='Please select'
                optionFilterProp='children'
                onChange={companySelected}
                value={companyName}
                disabled={!!recordForEdit}
              >
                {companies.map(s => (
                  <Select.Option key={s.id} value={s.text}>
                    {s.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {!recordForEdit && modalVisibility === ModalVisibility.Repair && (
            <Form.Item name='comment' label='Comment'>
              <Input.TextArea disabled={!!recordForEdit} placeholder='Write comment here' />
            </Form.Item>
          )}
          {modalVisibility === ModalVisibility.Repair && (
            <Form.Item name='Attachments and table'>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '2px' }}>
                <label style={{ marginLeft: '2px' }}>Attachments (Max file size 100mb)</label>
                <input style={{ marginTop: '8px' }} type='file' multiple onChange={onChange} ref={fileRef} />
              </div>
              <Table columns={columns} dataSource={dataSource} pagination={false} style={{ maxHeight: '280px', overflowY: 'auto' }} />
            </Form.Item>
          )}
          {!recordForEdit && modalVisibility === ModalVisibility.Repair && (
            <Form.Item>
              <Radio.Group value={repairType} onChange={(e: any) => setRepairType(e.target.value as RepairType)} defaultValue={RepairType.Repaired}>
                <Radio value={RepairType.Repaired}>Repaired</Radio>
                <Radio value={RepairType.Replaced}>Replaced</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {!recordForEdit && modalVisibility === ModalVisibility.Repair && (
            <Form.Item
              label='Repair / Replace date'
              name='repairDate'
              rules={[
                {
                  required: modalVisibility === ModalVisibility.Repair,
                  message: 'Please select Repair / Replace date',
                  whitespace: true,
                  type: 'date'
                }
              ]}
            >
              <DatePicker
                disabled={!!recordForEdit}
                value={repairDate}
                onChange={date => {
                  setRepairedDate(date);
                }}
              />
            </Form.Item>
          )}
          {!recordForEdit && modalVisibility === ModalVisibility.ScheduleForRepair && (
            <Form.Item name='workOrderNumber' label='Work order number'>
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

const MapStateToProps = (state: RootState) => ({
  gridData: prepareRepairInfoAttachmentSelector(state),
  companies: state.bidder.companies
});

const MapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompanies: () => dispatch(getCompanies()),
  saveRepairInfo: (repairInfo: RepairInfo, filter: Filter | null = null) => dispatch(saveRepairInfo(repairInfo, filter)),
  extendAttachments: (repairInfo: RepairInfoWithAttachments, attachments: CreateAttachment[]) =>
    dispatch(addAttachmentsForExistingRepair(repairInfo, attachments))
});

export default connect(MapStateToProps, MapDispatchToProps)(RepairInfoModal);
