import React, { FC } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../../../store';
import { RootState } from '../../../rootReducer';
import { getBid } from '../../Bid/bid.slice';
import { RouteComponentProps, withRouter } from 'react-router';

type QuoteTableActionProps = {
  bid: any;
};

type Props = {
  getBid: (id: string) => void;
};

const BidderExpandTableAction: FC<QuoteTableActionProps & RouteComponentProps & Props> = ({ bid, getBid, history }) => {
  const onBidRowClick = (bidId: string) => {
    getBid(bidId);
    history.push(`/bid/${bidId}`);
  };
  return (
    <>
      <a onClick={() => onBidRowClick(bid.id)}>Open</a>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: QuoteTableActionProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getBid: (id: string) => dispatch(getBid(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BidderExpandTableAction));
