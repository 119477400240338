import { useState, useEffect, useContext } from 'react';
import { AuthService } from './AuthService';
import UserDetailsContext from '../../userDetails.context';
import { User } from 'oidc-client';
import * as Utils from 'hermes-tiles-grid-popup';

const useAuth = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [apps, setApps] = useState<any | Array<any>>([]);
  const [logoUrl, setLogoUrl] = useState<string>();
  const { user } = useContext<any>(UserDetailsContext);

  const setUserDetails = (user: any) => {
    const { given_name, family_name, apps } = user.profile;
    setFirstName(given_name);
    setLastName(family_name);
    setApps(apps);
    setLogoUrl(Utils.extractLogoUrlFromToken(user.profile));
  };

  useEffect(() => {
    if (!user) {
      AuthService.getUser().then((userFromStorage: User | null) => {
        if (!userFromStorage) return;
        setUserDetails(userFromStorage);
      });
    } else {
      setUserDetails(user);
    }
  }, [user]);

  return [firstName, lastName, apps, logoUrl];
};

export default useAuth;
