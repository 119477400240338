import { useEffect } from 'react';
import { AttachmentForUpload } from '../repairList.slice';

export const useTabConfirm = (files: AttachmentForUpload[]) => {
  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if (files.length) {
        return "Upload is in progress, please do not close tab until it's completed.";
      }
    }
  }, [files.length]);
};
