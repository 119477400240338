import React, { FC, useContext } from 'react';
import SearchItem from './SearchItem';
import styles from '../RepairList.module.scss';
import { ClearOutlined } from '@ant-design/icons';
import { Col, Form, Row, Slider, Tooltip, DatePicker, Input } from 'antd';
import Button from '../../Shared/Button/Button';
import { useFilterItems } from './useFilterItems';
import { FiltersContext } from '../hooks/useFilters';

type Props = {
  isFiltersLoading: boolean;
  repairTypes: string[];
  countries: string[];
  sites: string[];
  inspections: string[];
  turbines: string[];
  blades: string[];
  bladeSides: string[];
  findingTypes: string[];
  severities: string[];
  responseLevels: string[];
  repairCompanies: string[];
  distanceToRootMax: number;
  distanceToRootMin: number;
};

const SearchBox: FC<Props> = ({
  isFiltersLoading,
  repairTypes,
  inspections,
  countries,
  sites,
  turbines,
  blades,
  bladeSides,
  findingTypes,
  severities,
  responseLevels,
  repairCompanies,
  distanceToRootMax,
  distanceToRootMin
}) => {
  const { clearSelected, clearSelectedDefect, filters, cascadeFilters, shouldBeDisabled } = useFilterItems(
    isFiltersLoading,
    repairTypes,
    countries,
    sites,
    inspections,
    turbines,
    blades,
    bladeSides,
    findingTypes,
    severities,
    responseLevels,
    repairCompanies
  );

  const {
    distanceToRootFrom,
    distanceToRootTo,
    defectId,
    setDefectId,
    setDistanceToRootFrom,
    setDistanceToRootTo,
    setStartRepairDate,
    setEndRepairDate
  } = useContext(FiltersContext);

  const onClear = () => {
    clearSelected();
    clearSelectedDefect();
  };

  const sliderValueChange = value => {
    setDistanceToRootFrom(value[0]);
    let valueTo = value[1] < 1 ? distanceToRootMax : value[1];
    setDistanceToRootTo(valueTo);
  };

  const startRepairDateChange = (date, dateString) => {
    setStartRepairDate(dateString);
  };

  const endRepairDateChange = (date, dateString) => {
    setEndRepairDate(dateString);
  };

  return (
    <div className={styles.searchBoxContainer}>
      <div>
        <div className={styles.filterBoxContainer}>
          {cascadeFilters.map((filter, index) => (
            <SearchItem key={index} disabled={shouldBeDisabled(filter.label)} {...filter} />
          ))}
        </div>
        <div className={styles.filterBoxContainer}>
          {filters.map((filter, index) => (
            <SearchItem key={index} disabled={false} {...filter} />
          ))}
          <Form.Item label={'Start repair date'} style={{ width: '180px', marginRight: '1rem' }} colon={false}>
            <DatePicker onChange={startRepairDateChange} />
          </Form.Item>
          <Form.Item label={'End repair date'} style={{ width: '180px', marginRight: '1rem' }} colon={false}>
            <DatePicker onChange={endRepairDateChange} />
          </Form.Item>
          <Form.Item label={'Distance to root'} style={{ width: '180px', marginRight: '1rem' }} colon={false}>
            <Slider
              range
              max={150}
              min={0}
              defaultValue={[distanceToRootMin, distanceToRootMax]}
              onAfterChange={sliderValueChange}
              tooltipVisible={true}
              tooltipPlacement={'bottom'}
            />
          </Form.Item>
          <Form.Item label={'Finding ID'} style={{ width: '180px', marginRight: '1rem' }} colon={false}>
            <Input value={defectId} onChange={event => setDefectId(event.target.value)} />
          </Form.Item>
        </div>
      </div>
      <div>
        <Tooltip placement='bottom' title='Clear'>
          <div>
            <Button type='button' className={styles.searchButton} onClick={onClear} title={<ClearOutlined />} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SearchBox;
