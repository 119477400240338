import { RouteComponentProps } from 'react-router';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import QuoteList from '../QuoteList/QuoteList';

const Home: FC = () => {
  return <QuoteList />;
};

export default connect()(Home);
