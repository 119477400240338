import { Card, Layout, PageHeader, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { FC, useEffect, useState } from 'react';
import { TAKE } from '../Bidder/bidder.slice';
import SearchForm from '../QuoteList/SearchForm/SearchForm';
import { connect } from 'react-redux';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { prepareBudgetListSelector } from './budgetListSelector';
import { getBudgetList } from './budgetList.slice';
import { useCallback } from 'react';
import { AuthService } from '../Auth/AuthService';
import { PermissionEnum } from '../../shared/enums';

interface Props {
  gridData: any;
  currentPage: number;
  totalNumber: number;
  isLoading: boolean;
  getBudgetList: (newPage: number, init?: boolean) => void;
}
const BudgetList: FC<Props> = ({ gridData, isLoading, currentPage, totalNumber, getBudgetList }) => {
  const [hasAdminPermission, setHasAdminPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  useEffect(() => {
    getBudgetList(1, true);
  }, []);

  return (
    <Layout>
      <Content>
        <PageHeader className='QBRHeader' title={'Budget Module'} extra={[]} />
        <Card style={{ marginLeft: '0' }}>
          <Table
            style={{ minHeight: '320px', height: '100%' }}
            columns={gridData.columns}
            dataSource={gridData.dataSource}
            loading={isLoading}
            scroll={{ y: 520, x: gridData.columns.length * 220 }}
            pagination={{
              current: currentPage,
              total: totalNumber,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              pageSize: TAKE,
              onChange: (page: number) => {
                getBudgetList(page);
              }
            }}
          />
        </Card>
      </Content>
    </Layout>
  );
};

const MapStateToProps = (state: RootState) => ({
  gridData: prepareBudgetListSelector(state),
  currentPage: state.budgetList.currentPage,
  totalNumber: state.budgetList.totalNumberOfBudgetItems,
  isLoading: state.budgetList.isLoading
});

const MapDispatchToProps = (dispatch: AppDispatch) => ({
  getBudgetList: (newPage: number, init: boolean = false) => dispatch(getBudgetList(init, newPage))
});

export default connect(MapStateToProps, MapDispatchToProps)(BudgetList);
