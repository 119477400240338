import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import axios from '../../axios';
import { BidderStatusEnum, CurrencyEnum } from '../../shared/enums';
import { ToastAnt } from '../Toast/ToastAnt';
import { getQuoteBidders } from '../QuoteList/quoteList.slice';
import { QuoteDataFinding } from '../QuoteData/quoteData.slice';

export const TAKE = 10;

export type Bid = {
  id: string;
  quoteId: string;
  quoteIntId: string;
  bidderId: string;
  companyName: string;
  bidderStatus: BidderStatusEnum;
  bidderInvitedDate: Date | '';
  bidPlacedDate: Date | '';
  rewardedDate: Date | '';
  tenderCloseDate: Date | '';
  tenderExtension: number | null;
  totalManPower: number;
  estimatedHours: number;
  vesselType: string;
  currency: CurrencyEnum;
  expectedProjectDuration: number;
  mobDemobCost: number;
  numberOfTeamAssigned: number;
  teamSize: number;
  weatherRiskCost: number;
  trainingCost: number;
  vesselCost: number;
  additionalCost: number;
  totalCases: number;
  totalNumberOfWTG: number;
  totalNumberOfBlades: number;
  canEdit: boolean;
  bidData: BidData[];
};

export type BidData = {
  id: string;
  quoteDataId: string;
  bidId: string;
  tooling: number;
  additionalCosts: number;
  totalManPower: number;
  estimatedHours: number;
  comment: number;
  accessType: string;
  cases: number;
  findingType: string;
  numberOfBlades: number;
  numberOfWTG: number;
  scopeOfWorkReference: string;
  findings: Array<QuoteDataFinding>;
};

export type ImageData = {
  id: string;
  height: number;
  width: number;
  pixelsPerMM: number;
  isRef: boolean;
  imageURL: string;
  dateCreated: string;
};

export type FindingAnnotations = {
  images: ImageData[];
  annotationData: Annotation;
};

export type Point = {
  x: number;
  y: number;
};

export type Annotation = {
  id: string;
  serialNumber: string;
  name: string;
  color: string;
  xCenter: number;
  yCenter: number;
  width: number;
  height: number;
  shape: Point[];
  pixelsPerMM: number;
  url: string;
};

type State = {
  bid: Bid;
  isLoading: boolean;
  recalculateTotalRepairCost: boolean;
  bidData: BidData[];
  exportToExcelInProgress: boolean;
  finding: FindingAnnotations | null;
  hasAdminPermission: boolean;
  hasBidderPermission: boolean;
};

const initialBid: Bid = {
  id: '',
  quoteId: '',
  quoteIntId: '',
  bidderId: '',
  companyName: '',
  bidderStatus: BidderStatusEnum.InviteNotSent,
  bidderInvitedDate: '',
  bidPlacedDate: '',
  rewardedDate: '',
  tenderCloseDate: '',
  tenderExtension: null,
  additionalCost: 0,
  totalManPower: 0,
  estimatedHours: 0,
  vesselType: '',
  currency: CurrencyEnum.Euro,
  expectedProjectDuration: 0,
  mobDemobCost: 0,
  numberOfTeamAssigned: 0,
  teamSize: 0,
  weatherRiskCost: 0,
  trainingCost: 0,
  vesselCost: 0,
  totalCases: 0,
  totalNumberOfWTG: 0,
  totalNumberOfBlades: 0,
  canEdit: true,
  bidData: []
};

const initialState: State = {
  bid: initialBid,
  isLoading: true,
  recalculateTotalRepairCost: false,
  bidData: [],
  exportToExcelInProgress: false,
  finding: null,
  hasAdminPermission: false,
  hasBidderPermission: false
};

const bidSlice = createSlice({
  name: 'bid',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setExportToExcelInProgress(state, action: PayloadAction<boolean>) {
      state.exportToExcelInProgress = action.payload;
    },
    setBid(state, action: PayloadAction<Bid>) {
      state.bid = action.payload;
      state.bidData = action.payload.bidData;
    },
    setFinding(state, action: PayloadAction<FindingAnnotations>) {
      state.finding = action.payload;
    },
    bidDataChanged(state, action: PayloadAction<BidData>) {
      const bidData = action.payload;
      state.bidData = state.bidData.map(x => {
        if (x.id === bidData.id) {
          return {
            ...bidData,
            key: bidData.quoteDataId
          };
        }
        return x;
      });
    },
    setBidDataFindings(state, action: PayloadAction<{ quoteDataId: string; data: Array<QuoteDataFinding> }>) {
      const index = state.bidData.findIndex(x => x.quoteDataId === action.payload.quoteDataId);
      if (index >= 0) {
        state.bidData[index].findings = action.payload.data;
      }
    },
    setHasAdminPermission(state, action: PayloadAction<boolean>) {
      state.hasAdminPermission = action.payload;
    },
    setHasBidderPermission(state, action: PayloadAction<boolean>) {
      state.hasBidderPermission = action.payload;
    }
  }
});

export const {
  setLoading,
  setBid,
  bidDataChanged,
  setBidDataFindings,
  setExportToExcelInProgress,
  setFinding,
  setHasAdminPermission,
  setHasBidderPermission
} = bidSlice.actions;

export default bidSlice.reducer;

export const bidReward =
  (bid: Bid): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));

    try {
      if (bid) {
        await axios.put(`bid/rewardBid/`, {
          bidderId: bid.bidderId,
          quoteId: bid.quoteId
        });
        //dispatch(getQuoteList(true, 1)); //TODO: refresh current page
        dispatch(getQuoteBidders(bid.quoteId));
        ToastAnt('Bid rewarded');
      }
    } catch (error) {
      ToastAnt('An error occurred!');
    }
  };

export const getBid =
  (bidderId: string): AppThunk =>
  async dispatch => {
    dispatch(setLoading(true));
    const response = await axios.get(`bid/${bidderId}`);
    dispatch(setBid(response.data));
    dispatch(setLoading(false));
  };

export const getFinding =
  (findingId: string): AppThunk =>
  async dispatch => {
    const response = await axios.get(`quoteData/finding/${findingId}`);
    dispatch(setFinding(response.data));
  };

export const saveBid =
  (bid: Bid, submit: boolean): AppThunk =>
  async dispatch => {
    if (bid.id) {
      await axios.put(`bid`, {
        id: bid.id,
        submit: submit,
        vesselType: bid.vesselType,
        additionalCost: bid.additionalCost,
        currency: bid.currency,
        expectedProjectDuration: bid.expectedProjectDuration,
        mobDemobCost: bid.mobDemobCost,
        numberOfTeamAssigned: bid.numberOfTeamAssigned,
        teamSize: bid.teamSize,
        weatherRiskCost: bid.weatherRiskCost,
        trainingCost: bid.trainingCost,
        vesselCost: bid.vesselCost,
        bidData: bid.bidData
      });
    } else {
      await axios
        .post(`bid`, {
          id: bid.id,
          submit: submit,
          bidderId: bid.bidderId,
          vesselType: bid.vesselType,
          additionalCost: bid.additionalCost,
          currency: bid.currency,
          expectedProjectDuration: bid.expectedProjectDuration,
          mobDemobCost: bid.mobDemobCost,
          numberOfTeamAssigned: bid.numberOfTeamAssigned,
          teamSize: bid.teamSize,
          weatherRiskCost: bid.weatherRiskCost,
          trainingCost: bid.trainingCost,
          vesselCost: bid.vesselCost,
          bidData: bid.bidData
        })
        .catch(err => {
          ToastAnt(err.response);
        });
    }
    //dispatch(getQuoteList(true, 1)); //TODO: refresh current page
    dispatch(getQuoteBidders(bid.quoteId));
    dispatch(getBid(bid.bidderId));

    if (submit) {
      ToastAnt('Bid is submitted');
    } else {
      ToastAnt('Bid is updated');
    }
  };

export const getQuoteDataFindings =
  (quoteId: string, quoteDataId: string): AppThunk =>
  async dispatch => {
    const response = await axios.get(`quote/${quoteId}/data/${quoteDataId}`);
    dispatch(setBidDataFindings({ quoteDataId, data: response.data }));
  };

export const exportBid =
  (bid: Bid): AppThunk =>
  async dispatch => {
    dispatch(setExportToExcelInProgress(true));
    await axios
      .get(`bid/exportBid/quote/${bid.quoteId}/bidder/${bid.bidderId}`, {
        responseType: 'blob'
      })
      .then(response => {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.split('filename=');
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1].split(';')[0].replace(/^"|"$/g, '');
          }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        dispatch(setExportToExcelInProgress(false));
      });
  };
