import React, { FC, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { setQuoteIdForDelete, setShowDeleteQuoteDialog, deleteQuote } from '../quoteList.slice';
import { AppDispatch } from '../../../store';
import { RootState } from '../../../rootReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Divider, Modal } from 'antd';
import { Quote } from '../../Quote/quote.slice';
import { AuthService } from '../../Auth/AuthService';
import { PermissionEnum, QuoteStatusEnum } from '../../../shared/enums';
import ConfirmDialog from '../../Shared/ConfirmDialog/ConfirmDialog';

type QuoteTableActionProps = {
  record: Quote;
};

type Props = {
  deleteQuote: (quoteId: string) => void;
};

const QuoteTableAction: FC<QuoteTableActionProps & RouteComponentProps & Props> = ({ record, deleteQuote, history }) => {
  const [hasAdminPermission, setHasAdminPermission] = useState(false);
  const [hasBidderPermission, setHasBidderPermission] = useState(false);

  const checkPermissions = useCallback(async () => {
    var adminPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Admin.toString()]);
    setHasAdminPermission(adminPermission);
    var bidderPermission = await AuthService.hasPermission([PermissionEnum.QuoteManager_Bidder.toString()]);
    setHasBidderPermission(bidderPermission);
  }, []);

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions]);

  const quoteId = `${record.id}`;

  const onClickDeleteQuote = () => {
    deleteQuote(quoteId);
  };

  const onEditQuote = () => {
    history.push(`/quotes/${quoteId}`);
  };

  const deleteQuoteModal = () => {
    ConfirmDialog(onClickDeleteQuote, 'Are you sure you want to delete quote?');
  };

  return (
    <>
      {hasAdminPermission && (
        <div>
          <span>
            <a
              onClick={e => {
                e.stopPropagation();
                onEditQuote();
              }}
            >
              Open
            </a>
          </span>
          <Divider type='vertical' />
          {record.statusEnum === QuoteStatusEnum.InProgress ? (
            <span>
              <a
                onClick={e => {
                  e.stopPropagation();
                  deleteQuoteModal();
                }}
              >
                Delete
              </a>
            </span>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: QuoteTableActionProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteQuote: (quoteId: string) => dispatch(deleteQuote(quoteId)),
  onSetQuoteIdForDelete: (id: string) => dispatch(setQuoteIdForDelete(id)),
  onShowDeleteDialog: () => dispatch(setShowDeleteQuoteDialog(true)),
  onClickDeleteQuote: (id: string) => dispatch(deleteQuote(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteTableAction));
