import React from "react";
import styles from "./Footer.module.scss"

const Footer: React.SFC = () => {
  const year = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <div className="row">
        <div className="col-6">          
        </div>
        <div className="col-6">
          <p className={styles.copyrightLabel}>
            Powered by Siemens Gamesa Renewable Energy A/S &copy; Copyright{" "}
            {year}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
